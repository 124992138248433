//
//// Generate Duration && Delay
//[data-aos] {
//    //@for $i from 1 through 60 {
//    //    body[data-aos-duration='#{$i * 50}'] &,
//    //    &[data-aos][data-aos-duration='#{$i * 50}'] {
//    //        transition-duration: #{$i * 50}ms;
//    //    }
//    //
//    //    body[data-aos-delay='#{$i * 50}'] &,
//    //    &[data-aos][data-aos-delay='#{$i * 50}'] {
//    //        transition-delay: 0s;
//    //
//    //        &.aos-animate {
//    //            transition-delay: #{$i * 50}ms;
//    //        }
//    //    }
//    //}
//}
//
[data-aos] {
    pointer-events: none;
    &.aos-animate {
        pointer-events: auto;
    }
}


$aos-easing: (
        linear: cubic-bezier(.250, .250, .750, .750),

        ease: cubic-bezier(.250, .100, .250, 1),
        ease-in: cubic-bezier(.420, 0, 1, 1),
        ease-out: cubic-bezier(.000, 0, .580, 1),
        ease-in-out: cubic-bezier(.420, 0, .580, 1),

        ease-in-back: cubic-bezier(.6, -.28, .735, .045),
        ease-out-back: cubic-bezier(.175, .885, .32, 1.275),
        ease-in-out-back: cubic-bezier(.68, -.55, .265, 1.55),

        ease-in-sine: cubic-bezier(.47, 0, .745, .715),
        ease-out-sine: cubic-bezier(.39, .575, .565, 1),
        ease-in-out-sine: cubic-bezier(.445, .05, .55, .95),

        ease-in-quad: cubic-bezier(.55, .085, .68, .53),
        ease-out-quad: cubic-bezier(.25, .46, .45, .94),
        ease-in-out-quad: cubic-bezier(.455, .03, .515, .955),

        ease-in-cubic: cubic-bezier(.55, .085, .68, .53),
        ease-out-cubic: cubic-bezier(.25, .46, .45, .94),
        ease-in-out-cubic: cubic-bezier(.455, .03, .515, .955),

        ease-in-quart: cubic-bezier(.55, .085, .68, .53),
        ease-out-quart: cubic-bezier(.25, .46, .45, .94),
        ease-in-out-quart: cubic-bezier(.455, .03, .515, .955)
);

// Easings implementations
// Default timing function: 'ease'

[data-aos] {
    @each $key, $val in $aos-easing {
        body[data-aos-easing="#{$key}"] &,
        &[data-aos][data-aos-easing="#{$key}"] {
            transition-timing-function: $val;
        }
    }
}





[data-aos='ki-fade-up']{
    opacity: 0;
    transition-property: opacity, transform;
    transform: translate3d(0, 30px, 0);
    transition-duration: 2s;
    display: block;

    &.aos-animate {
        opacity: 1;
        transform: none;
    }
}

[data-aos='ki-fade-up-small']{
    opacity: 0;
    transition-property: opacity, transform;
    transform: translate3d(0, 20px, 0);
    transition-duration: 2s;

    &.aos-animate {
        opacity: 1;
        transform: none;
    }
}



[data-aos='ki-fade']{
    opacity: 0;
    transition-property: opacity;
    transition-duration: 4s;

    &.aos-animate {
        opacity: 1;
    }
}

@keyframes navigation-cards {
    0% {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}


//Used in homepage sliders

@keyframes slideInImageLeft {
    0% {
        opacity:0;
        transform: translateX(20px);
    }
    100% {
        opacity:1;
        transform: none;
    }
}

@keyframes slideOutImageLeft {
    0% {
        opacity:1;
        transform: none;
    }
    100% {
        opacity:1;
        transform: translateX(20px);
    }
}

@keyframes slideInImageRight {
    0% {
        opacity:0;
        transform: translateX(-20px);
    }
    100% {
        opacity:1;
        transform: none;
    }
}

@keyframes slideOutImageRight {
    0% {
        opacity:1;
        transform: none;
    }
    100% {
        opacity:1;
        transform: translateX(-20px);
    }
}

@keyframes slideInImageTop {
    0% {
        opacity:0;
        transform: translateY(20px);
    }
    100% {
        opacity:1;
        transform: none;
    }
}

@keyframes slideOutImageTop {
    0% {
        opacity:1;
        transform: none;
    }
    100% {
        opacity:1;
        transform: translateY(20px);
    }
}

@keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:1;
    }
}
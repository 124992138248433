@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}



/* @include when-inside('.example'){ */
@mixin when-inside($selector) {
  #{$selector} & {
    @content;
  }
}


/*  @include family-of(3){  */
@mixin family-of($n) {
  &:first-child:nth-last-child(#{$n}) ~ &,
  &:first-child:nth-last-child(#{$n}) {
    @content;
  }
}


/* @include nth-of-family(3, 7) { */
@mixin nth-of-family($n, $m) {
  $m: $m + 1 - $n;
  &:nth-child(#{$n}):nth-last-child(#{$m}) {
    @content;
  }
}


/*
// Column-width block mixin
// This is used to apply styles to elements, if they're contained within a column of the appropriate size.
Available sizes:
> full
> three-quarters
> two-thirds
> half
> third
> quarter
*/
@mixin el-size($sizes...){
  $selector : '';
  @for $i from 0 to length($sizes) {
    $selector : $selector + '.col--' + '#{nth($sizes, $i + 1)}' + ' & , ';
  }
  #{$selector} {
    @content;
  }
}




// Phone
@mixin phone-small-below {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}
@mixin phone-small-above {
  @media (min-width: #{$phone-width}) {
    @content;
  }
}

// Phone Large
@mixin phone-large-above {
  @media (min-width: #{$phone-large-width}) {
    @content;
  }
}
@mixin phone-large {
  @media (min-width: #{$phone-width}) and (max-width: #{$phone-large-width - 1px}) {
    @content;
  }
}
@mixin phone-large-below {
  @media (max-width: #{$phone-large-width - 1px}) {
    @content;
  }
}

// Tablet Small
@mixin tablet-small-below {
  @media (max-width: #{$tablet-small-width}) {
    @content;
  }
}
@mixin tablet-small{
  @media (min-width: #{$tablet-small-width})  and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}
@mixin tablet-small-above {
  @media (min-width: #{$tablet-small-width}) {
    @content;
  }
}


// Tablet
@mixin tablet-below {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}
@mixin tablet{
  @media (min-width: #{$phone-large-width})  and (max-width: #{$tablet-large-width - 1px}) {
    @content;
  }
}
@mixin tablet-above{
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

// Tablet Large
@mixin tablet-large-below {
  @media (max-width: #{$tablet-large-width}) {
    @content;
  }
}
@mixin tablet-large{
  @media (min-width: #{$tablet-width})  and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin tablet-large-above {
  @media (min-width: #{$tablet-large-width}) {
    @content;
  }
}

// Desktop small
@mixin desktop-small-above {
  @media (min-width: #{$desktop-width}){
    @content;
  }
}
@mixin desktop-small {
  @media (min-width: #{$desktop-width})  and (max-width: #{$desktop-medium-width - 1px}) {
    @content;
  }
}
@mixin desktop-small-below {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

// Desktop Medium
@mixin desktop-medium-below {
  @media (max-width: #{$desktop-medium-width - 1px}) {
    @content;
  }
}
@mixin desktop-medium {
  @media (min-width: #{$desktop-medium-width})  and (max-width: #{$desktop-large-width - 1px}) {
    @content;
  }
}
@mixin desktop-medium-above {
  @media (min-width: #{$desktop-medium-width}) {
    @content;
  }
}

// Desktop Large
@mixin desktop-large-below {
  @media (max-width: #{$desktop-large-width - 1px}) {
    @content;
  }
}
@mixin desktop-large {
  @media (min-width: #{$desktop-large-width})  and (max-width: #{$desktop-xlarge-width - 1px}) {
    @content;
  }
}
@mixin desktop-large-above  {
  @media (min-width: #{$desktop-large-width - 1px}) {
    @content;
  }
}

// Desktop XLarge
@mixin desktop-xlarge-above {
  @media (min-width: #{$desktop-xlarge-width - 1px}) {
    @content;
  }
}
@mixin desktop-xlarge-below {
  @media (max-width: #{$desktop-xlarge-width - 1px}) {
    @content;
  }
}



@mixin height-large {
  @media (max-height: 1000px) {
    @content;
  }
}

@mixin height-medium {
  @media (max-height: 800px) {
    @content;
  }
}


@mixin height-small {
  @media (max-height: 650px) {
    @content;
  }
}

@mixin height-xsmall {
  @media (max-height: 450px) {
    @content;
  }
}
.contact-locations {
    display:flex;
    flex-direction:column;
    align-items: center;
    width:100%;
    padding: 9.6rem 0;

    h2 {
        margin-bottom:2rem;
    }

    h3 {
        margin-top: 4rem;
        margin-bottom:5rem;
        @include phone-large-below {
            margin-top:4rem;
            margin-bottom:2rem;
        }
    }

    h4 {
        margin-bottom:1.5rem;
    }

    &__locations {
        width:100%;
        display:flex;
        flex-wrap:wrap;
        align-items: stretch;
        justify-content: center;
        margin: 0rem -1.6rem;
        @include tablet-small-below {
            margin: 0;
        }
    }

    &__location {
        width:calc(25% - 3.2rem);
        margin: 1.6rem 1.6rem;
        padding: 2.5rem 3.5rem;
        background-color: #fff;
        box-shadow:$shadow-100;
        border-radius:.5rem;
        &:last-child {
            margin-right:0;
        }
        a {
            text-decoration: none;   
        }
        .rich-text > * {
            color:$navy-500;
            font-weight:400;
            font-size: 2rem;
            line-height:1.6;
            margin-bottom:0;
        }
        &--promoted {
            background-color:$navy;
            color:#fff;
            h4 {
                color:#fff;
            }
            .rich-text > * {
                color:#fff;
            }
        }
        @include desktop-small-below {
            width:calc(50% - 3.2rem);
        }
        @include tablet-small-below {
            width:100%;
            margin: 1.6rem 0;
        }
    }
}
.tabbed-content{
  margin-top: 4rem;
  &__tabs{
    border: 0;
    display: flex;
    justify-content: center;
    //align-items: flex-end;
    position: relative;
    &::after{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      content: '';
      display: block;
      transition: all .25s ease-in-out;
      background-color: $navy-400;
    }
  }
  &__tab{
    max-width: 33%;
    display: flex;
    button{
      background: transparent;
      border: 0;
      margin-bottom: 0;
      position: relative;
      padding: 0 5rem 1rem 5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      //font-family: $font-heading;
      @extend %heading-four;
      color: $navy-500;
      @include tablet-below{
        font-size: 2rem;
        padding: 0 2rem 1rem 2rem;
      }
      @include phone-large-below{
        font-size: 1.6rem;
      }
      &::after{
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 2px;
        content: '';
        display: block;
        opacity: 0;
        transition: all .25s ease-in-out;
        background-color: $navy-500;
        z-index: 2;
      }
      &.active{
        font-weight: 700;
        color: $navy;
        &::after{
          background-color: $orange;
          opacity: 1;
        }
      }
      &:hover, &:focus{
        color: $navy;
        &::after{
          opacity: 1;
        }
      }
    }
  }
  &__pane{

  }
}


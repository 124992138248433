.image-grid-row{
  display: flex;
  flex-wrap:wrap;
}

.image-grid-row-item{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  @include column(2);
  @include desktop-small-below{
    @include column(3);
  }
  @include tablet-small-below{
    @include column(4);
  }

  @include phone-large-below{
    @include column(6);
  }

  picture{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img{
    max-width: 70%;
    max-height: 150px;
  }
}
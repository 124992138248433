//-- Fonts
$font-heading: 'GTAmerica Extended', sans-serif;
$font-body: 'GTAmerica Standard', sans-serif;
$font-decorative: 'Laforge Stencil', sans-serif;

//-- Colors

// Primary
$navy: #0C3E8D;
$orange: #F79A37;

// Shades
$navy-100: #FAFBFC;
$navy-200: #F0F3F8;
$navy-300: #E7ECF4;
$navy-400: #CAD2DD;
$navy-500: #5C7189;
$navy-600: #3D65A4;
$navy-900: #0B2957;
$orange-100: #FFFAF4;

// Base
$white: #fff;
$black: #000;

//-- Shadows
$shadow-100: 0px 100px 80px rgba(31, 63, 177, 0.02), 0px 41.7776px 33.4221px rgba(31, 63, 177, 0.0143771), 0px 22.3363px 17.869px rgba(31, 63, 177, 0.0119221), 0px 12.5216px 10.0172px rgba(31, 63, 177, 0.01), 0px 6.6501px 5.32008px rgba(31, 63, 177, 0.00807786), 0px 2.76726px 2.21381px rgba(31, 63, 177, 0.00562291);
$shadow-300: 0px 17px 80px rgba(94, 121, 154, 0.07), 0px 7.10219px 33.4221px rgba(94, 121, 154, 0.0503198), 0px 3.79717px 17.869px rgba(94, 121, 154, 0.0417275), 0px 2.12866px 10.0172px rgba(94, 121, 154, 0.035), 0px 1.13052px 5.32008px rgba(94, 121, 154, 0.0282725), 0px 0.470434px 2.21381px rgba(94, 121, 154, 0.0196802);
$shadow-500: 0px 21px 80px rgba(0, 0, 0, 0.06), 0px 8.7733px 33.4221px rgba(0, 0, 0, 0.0431313), 0px 4.69062px 17.869px rgba(0, 0, 0, 0.0357664), 0px 2.62953px 10.0172px rgba(0, 0, 0, 0.03), 0px 1.39652px 5.32008px rgba(0, 0, 0, 0.0242336), 0px 0.581124px 2.21381px rgba(0, 0, 0, 0.0168687);
$shadow-500-blue: 0px 100px 80px rgba(31, 63, 177, 0.02), 0px 41.7776px 33.4221px rgba(31, 63, 177, 0.0143771), 0px 22.3363px 17.869px rgba(31, 63, 177, 0.0119221), 0px 12.5216px 10.0172px rgba(31, 63, 177, 0.01), 0px 6.6501px 5.32008px rgba(31, 63, 177, 0.00807786), 0px 2.76726px 2.21381px rgba(31, 63, 177, 0.00562291);

//-- Grid & Wrapper
$columns: 12;
$gutterWidth: 2rem;
$wrapper-width: 1660;
// TODO: Figure out why 17px offset is required to prevent overflow
$wrapper-offset: calc(((100% - 1660px) / 2) + 17px);

//-- Breakpoints
$phone-width: 320px;
$phone-large-width: 550px;
$tablet-small-width: 660px;
$tablet-width: 768px;
$tablet-large-width: 825px;
$desktop-width: 1000px;
$desktop-medium-width: 1200px;
$desktop-large-width: 1660px;
$desktop-xlarge-width: 1800px;

$wrapper-width: 1800px;

// Animation beziers
$bezier-smooth: cubic-bezier(.23, .65, .63, .99);
$bezier-fast: cubic-bezier(0.64, 0.39, 0.03, 1.00);
$bezier-fast--bounce: cubic-bezier(0.64, 0.39, 0.03, 1.00);
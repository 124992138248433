
.cap-solution-cards{
  display: flex;
  justify-content: space-between;
  margin: 5rem 0 -5rem 0;
  width: 100%;
  transition: all .2s ease-in-out;

  @include desktop-small-below{
    flex-direction: column;
  }

  @include tablet-above{

    &:hover{
      //  width: calc(100% + 4rem);
      //   margin-left: -2rem;
      .cap-solution-card{
        //opacity: .6;
        &__icon {
          i{
            color: #fff;
          }
          svg.cap-solution-card__icon-outer{
            stroke: #fff;
          }
        }
      }
    }

  }



}

.cap-solution-cards .cap-solution-card{
  border-radius: 5px;
  background-color: $navy-900;
  @include column(4);
  text-align: center;
  padding: 17rem 5rem;
  transition: all .2s ease-in-out;
  position: relative;
  @include desktop-small-above{
    margin: 0 1rem;
  }


  @include desktop-large-below{
    padding: 12rem 3rem;
  }
  @include desktop-small-below{
    @include column(12);
    text-align: left;
    margin-bottom: 2rem;
    padding: 8rem 4rem;
  }

  &__bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .3;
    z-index: 0;
  }




  > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
    position: relative;
    z-index: 2;
    @include desktop-small-below{
     // align-items: flex-start;
      //flex-direction: row;
    }
  }

  > div > div{
    @include desktop-small-below{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  p{
    color: #fff;
  }
  > div{

  }
  &__icon{
    width: 13rem;
    height: 13rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    i{
      font-size: 4rem;
      color: #fff;
      position: relative;
      z-index: 2;
      color: $orange;
      transition: all .2s ease-in-out;
    }

    svg{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      transition: all .2s ease-in-out;
      // Inner
      &.cap-solution-card__icon-inner{
        width: 70%;
        height: 70%;
        opacity: 0;
      }
      // Outer
      &.cap-solution-card__icon-outer{
        width: 80%;
        height: 80%;
        stroke: $orange;
      }
    }
  }

  &__title{
    @extend %heading-two;
    color: #fff;
    @include desktop-large-below{
      font-size: 3.5rem;
    }
  }
  p{
    @include desktop-large-below{
      font-size: 1.8rem;
    }
  }

  &:hover{
    text-decoration: none;
  }

  .button{
    margin-right: 0;
  }
  @include tablet-above{
    &:hover, &:focus{
      transform: scale(1.02);
      opacity: 1;
      background-color: #121314;
      z-index: 3;
      box-shadow: $shadow-300;
      //margin: 0 4rem;
      > div{
        //transform: scale(.9);
      }
      // @include column(4.4);
      .cap-solution-card__icon {
        i{
          color: #fff;
        }
        svg.cap-solution-card__icon-inner{
          opacity: 1;
        }
        svg.cap-solution-card__icon-outer{
          width: 100%;
          height: 100%;
          stroke: #fff;
        }
      }
    }
  }

}

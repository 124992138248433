.committee-block{
    @extend %base-block;
    padding: 2rem 0;
    &__primary{
        @extend %base-block__primary;
        padding-top: 8rem;
        padding-right:5rem;
        padding-left: 5rem;
        @include el-size(three-quarters, two-thirds){
            @include column(6);
            @include phone-large-below{
                @include column(12);
            }
        }
        &::after{
            content:'';
            position: absolute;
            height: 100%;
            width: 2px;
            background-color: $navy-200;
            top:0;
            right:0;
            @include el-size(third, quarter){
                height: 2px;
                width: 95%;
                top: 100%;
                right: 1.5rem;
            }
            @include phone-large-below{
                height: 2px;
                width: 95%;
                top: 100%;
                right: 1.5rem;
            }
        }
        p {
            @extend %body-copy;
            color: $navy-500;
        }
        a {
            margin-bottom: 2rem
        }
    }
    &__tag{
        @extend %base-tag;
        position: absolute;
        top: 0;
        left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    &__img{
        margin-bottom: 1.9rem;
        width: 100%;
        @include phone-large-below{
            max-width: 200px;
        }
    }
    &__secondary{
        @extend %base-block__secondary;
        justify-content: flex-start;
        padding-top: 5rem;
        padding-left: 4rem;
        padding-right: 4rem;
        &-title{
            @extend %body-copy-large;
            margin-bottom: 1.7rem;
        }
        p{
            line-height: 1.6;
            margin-bottom: 1.7rem;
        }
    }
}
/* POPOVER */
body .popover{
	background-color: $navy;
	border-radius:.5rem;
	max-width:550px;
	box-shadow:$shadow-300; 
}
.committee-block__popover{
    padding: 3rem;
    &-title{
        @extend %heading-five;
        color: #fff;
    }
    &-summary{
        @extend %body-copy-small;
        color: #fff;
    }
    &-members ul{
        display: flex;
        flex-wrap: wrap;
        list-style: inherit;
        padding: 0 3rem;
        li{
            @extend %body-copy-small;
            color: #fff;
            padding-right: 5rem;
            font-weight: 700;
            p{
                @extend %body-copy-small;
            }
            &::marker{
                color: $orange;
            }
        }
    }
}
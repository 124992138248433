.visually-hidden, .visuallyhidden, .slick-sr-only{
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
}

.skip-to-content {
    left: 10%;
    z-index: -999;
    position: absolute;
    overflow: hidden;
    opacity:0;

    background:#fff;
    padding:10px;
    font-size:2rem;

    text-align: center;
    text-decoration: none;
    line-height: 150%;
    border-radius: .5rem;

    transform: translateY(-150%);
    transition: transform 0.3s;

    color: $navy-900;

    &:focus {
        color:#111;
    }

    span {
        color: $navy;
        font-weight:700;
        background: #fff;
    }
}
.skip-to-content:focus{
    transform: translateY(10%);
    z-index: 9999;
    opacity:1;
}

.contact-cards{

  .slick-list{
    box-shadow: 0px 17px 80px rgb(94 121 154 / 7%), 0px 7.10219px 33.4221px rgb(94 121 154 / 5%), 0px 3.79717px 17.869px rgb(94 121 154 / 4%), 0px 2.12866px 10.0172px rgb(94 121 154 / 4%), 0px 1.13052px 5.32008px rgb(94 121 154 / 3%), 0px 0.470434px 2.21381px rgb(94 121 154 / 2%);
  }


  .slick-arrow{
    position: absolute;
    top: 50%;
    z-index: 2;
    width: 5rem;
    height: 5rem;
    box-shadow: $shadow-300;
    background-color: $navy-600;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    &::after{
      font-family: 'icoMoon';
      font-size: 2.8rem;
      color: #fff;
    }
    &:hover, &:focus{
      background-color: darken($navy-500, 10%);
    }
    &:focus {
      outline: 2px solid $orange;
    }
    &.slick-prev{
      left: 0;
      transform: translateY(-50%) translateX(-50%);
      &::after{
        content: '\e912';
      }
    }
    &.slick-next{
      right: 0;
      transform: translateY(-50%) translateX(50%);
      &::after{
        content: '\e913';
      }
    }
  }
}

.contact-card{
  padding: 2.5rem 3rem 3rem 3rem;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &--light{
   // box-shadow: $shadow-300;
  }

  &--dark{
    background-color: $navy;
  }

  &--dark &{
    &__title, &__name{
      color: #fff;
    }
    &__location, &__region{
      color: $navy-400;
    }
  }

  &__title{
    @extend %heading-five;
    color: $navy;
    font-weight: 700;
    margin-bottom: 0;
  }
  &__region{
    @extend %body-copy-small;
    color: $navy-500;
  }
  &__image{
    border-radius: 100%;
    margin: 1rem 0;
    @include desktop-medium-below{
      max-width: 10rem;
    }
  }
  &__name{
    @extend %body-copy;
    color: $navy;
    font-weight: 700;
    margin-bottom: 0;
  }
  &__location{
    color: $navy-500;
    @extend %body-copy-small;
  }


  &--alternate{

  }
  &--alternate &{

  }

  &__button{
    margin-top: 1rem;
    margin-right: 0;
    margin-bottom: 0;
  }


}




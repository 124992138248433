.hidden-modal{
  display: none;
}

.modal{

  .modal-content{
    @extend %base-block;
    padding: 4rem;
    width: 100%;
    position: relative;
    @include phone-large-below {
      padding: 3rem 2rem;
    }
  }

  button.close{
    height: 3rem;
    cursor:pointer;
    background:transparent;
    border: none;
    position: absolute;
    right: 1.5rem;
    top: 2rem;
    i{
      color: $navy-500;
      font-size: 3rem;
    }
    @include tablet-below {
      //right: 0rem;
      //top: -27px;
      //top: .5rem;
      //right: .5rem;
    }
  }
  .modal-dialog{
    max-width: 1570px;
    padding: 4rem;
    &--thin{
      max-width: 1200px;
    }
    @include phone-large-below {
      padding: 2rem;
    }
  }
}
.home-diversity {

    position:relative;
    min-height:70vh;
    display: flex;
    align-items: center;

    @include desktop-small-below {
        min-height:65vh;
    }

    .wrapper {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height:100%;
        width:100%;
        padding-top:14rem;
        padding-bottom:14rem;
        @include desktop-small-below {
            // align-items: center;
            > div {
                width: fit-content;
            }
        }
    }

    .simpleParallax {
        height:100%;
    }

    &__bg {
        overflow:hidden;
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index: -1;
        picture {
            display:block;
            height:100%;
            width:100%;
        }
        &-wrapper-image {
            height: 100%;
            img {
                width:100%;
                height:100%;
                object-fit: cover;
                transform-origin: center center;
            }
            // transform: scale(1.5);
            // position:relative;
            // top: var(--parallaxY);
            @include desktop-small-below {
                display:none;
            }
        }
        &-wrapper-image-mobile {
            height: 100%;
            display:none;
            img {
                transform-origin: center center;
                width:100%;
                height:100%;
                object-fit: cover;
            }
   
            // transform: scale(1.5);
            // position:relative;
            // top: var(--parallaxY);
            @include desktop-small-below {
                display:block;
            }
        }
    }

    &__dark-overlay {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background: linear-gradient(-90deg, rgba(11, 41, 87, 0) 2.64%, #15243C 100%);
        opacity: 0.45;
        @include desktop-small-below {
            background: rgba(11, 41, 87, 0.6);
        }
    }

    &__blue-overlay {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background: linear-gradient(-90deg, rgba(11, 41, 87, 0) 2.64%, #15243C 100%);
        @include desktop-small-below {
            background: rgba(11, 41, 87, 0.6);
        }
    }

    &__title {
        @extend %heading-one;
        color:#fff;
        max-width:67rem;
        margin-bottom:4rem;
        @include desktop-small-below {
            // text-align: center;
        }
    }

    &__copy {
        color:#fff;
        p {
            color:#fff;
        }
        max-width:67rem;
        margin-bottom:4rem;
        @include desktop-small-below {
            // text-align: center;
        }
        @include phone-large-below {
            p {
                font-size:2.3rem;
            }
        }
    }

    &__button {
        margin-right:0;
        &.button--secondary-alt {
            border-color: $orange;
            i::before{
              color: $orange;
            }
            &:hover{
              border-color: #fff;
              i::before{
                color: $orange;
              }
            }
        }
    }

}
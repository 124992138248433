.news-block{
	@extend %base-block;
	min-height: calc(100% - 2rem);

	&__primary{
		@extend %base-block__primary;
		padding-top:4rem;
		@include el-size(half, two-thirds, three-quarters, full){
            padding-left: 4rem;
			@include phone-large-below{
				padding-left:2rem;
			}
        }
		// @include phone-large-below{
		// 	padding-top:2rem;
		// }
		&--release{
			@include column(12);
			padding-top:2rem;
			@include el-size(half, two-thirds, three-quarters, full) {
				@include column(12);
				padding-left: 2rem;
			}
			@include desktop-large-below {
				@include column(12);
			}
			@include phone-large-below{
				@include column(12);
			}
		}
	}
	&__primary--release{
		.news-block__info{
			margin-bottom: 0;
		}
	}
	&__secondary{
		@extend %base-block__secondary;
		padding: 0;
		@include el-size(half, two-thirds, three-quarters, full){
			padding-bottom: 0;
		}
	}
	&__img{
		@extend %base-block__img;
		width: 100%;
		height: 100%;
		object-fit: cover;
		@include el-size(half, two-thirds, three-quarters, full){
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			@include phone-large-below{
				border-top-right-radius: 5px;
			}
		}
	}
	&__info--news{
		@include el-size(half, two-thirds, three-quarters, full){
			@include phone-large-below{
				position:absolute;
				top:-3rem;
				left:0rem;
				display:flex;
				border-top-right-radius:5px;
				background-color:#fff;
				flex-direction:row;
				span{
					display:inline-block;
					padding:1rem 1.8rem;
					font-weight: 200;
					@include el-size(sixth){
						font-size:1.2rem;
					}
					font-size:2rem;
				}
			}
		}
		@include el-size(third, quarter, sixth){
      		position:absolute;
			top:-3rem;
			left:0rem;
			display:flex;
			border-top-right-radius:5px;
			background-color:#fff;
			flex-direction:row;
			span{
				display:inline-block;
				padding:1rem 1.8rem;
				font-size:1.5rem;
				font-weight: 200;
				@include el-size(sixth){
					font-size:1.2rem;
				}
				@include phone-large-below {
					font-size:2rem;
				}
			}
    	}
	}
	&__info--news &{
		@include el-size(half, two-thirds, three-quarters, full){
            @include phone-large-below{
                &__tag{
                    border-top-left-radius:0rem;
                    border-bottom-left-radius:0rem;
                }
                &__date{
                    border-top-right-radius:5px;
                }
            }
        }
		@include el-size(third, quarter, sixth) {
			&__tag{
				border-top-left-radius:0rem;
				border-bottom-left-radius:0rem;
			}
			&__date{
				border-top-right-radius:5px;
			}
		}
	}

	&__tag{
		@extend %base-tag;
	}
	&__date{
		@extend %base-date;
		padding-bottom: .75rem;
	}
	&__title{
		@include el-size(two-thirds, three-quarters, full){
            @extend %heading-three;
			@include phone-large-below{
                font-size: 2.9rem;
            }
        }
        @include el-size(half, third, quarter, sixth){
            @extend %heading-five;
			@include phone-large-below{
                font-size: 2.9rem;
            }
        }
	}
	&__summary{
		@include el-size(sixth){
			display: none;
		}
	}
	&__info{
		display:flex;
		flex-direction:column;
		flex-wrap:wrap;
		margin-bottom:2rem;
	}
	&__release-info &{
		&__tag{
			align-self: flex-start;
			display: block;
		}
		&__date{
			display: block;
			padding:2rem 0 0;
		}
	}
	.button{
		margin-bottom: 0;
	}

}
.highlights-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.highlights{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}

.highlight{
  @include column(6);
  @extend %list-item-orange-bullet;
  @include desktop-small-below{
    @include column(12);
    padding-right: 0;
  }
  &__title{
    @extend %body-copy;
    font-weight: 700;
    color: $navy;
    margin-bottom: 0;
  }
  p{

  }
}

.differentiators{
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.differentiator{
  @extend %list-item-orange-bullet;
  @include column(4);
  @include desktop-small-below{
    @include column(12);
    padding-right: 0;
  }
}


.standards{
  display: flex;
  flex-wrap: wrap;
 // margin-top: 3rem;
 // margin-bottom: 5rem;
}

.standard{
  @include column(3);
  font-size:2rem;
  margin-bottom: 4rem;
  padding-right: 3rem;
  padding-left: 3.8rem;
  position: relative;
  dd{
    @extend %body-copy-small;
    line-height: 1.5;

  }
  &::before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0.4rem;
    font-family: 'icoMoon';
    content: '\e925';
    color: $orange;
    font-size: 2.7rem;
  }
  @include desktop-small-below{
    @include column(12);
    padding-right: 0;
  }
  &__title{
    @extend %body-copy;
    font-weight: 700;
    color: $navy-900;
    margin-bottom: 0;
  }
}

.capability-experience{
  margin-top: 8rem;
  &__title{

  }
  &__inner {
    .site-row{
      &:first-child {
        padding-top: 3rem;
      }
      &:nth-child(even){
        background-color: $navy-100;
      }
    }
  }
}
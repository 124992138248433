.sidebar-related-downloads{
    @extend %base-block;
    .download-block{
        box-shadow: none;
    }
    .slick-arrow{
		position: absolute;
		bottom: -3.7rem;
		z-index: 2;
		width: 2.2rem;
		height: 2.2rem;
		background-color: transparent;
		border: 0;
		border-radius: 5px;
        padding: 0;
		cursor: pointer;
		&::after{
			font-family: 'icoMoon';
			font-size: 2.2rem;
			color: $navy;
		}
		&:focus {
			outline: 2px solid $orange;
		}
		// &:hover{
		// 	background-color: darken($navy-500, 10%);
		// }
		&.slick-prev{
			left: 17%;
			transform: translateY(-50%) translateX(-50%);
			&::after{
				content: '\e912';
			}
		}
		&.slick-next{
			right: 17%;
			transform: translateY(-50%) translateX(50%);
			&::after{
				content: '\e913';
			}
		}
	}


	.slick-dots{
		display: flex;
		justify-content: center;
		position: absolute;
		left: 50%;
		bottom: -3rem;
		width: 80%;
        height: 3rem;
    	align-items: center;
        border-radius: 0 0 5px 5px;
		text-align: center;
		transform: translateX(-50%);
        background-color: $navy-200;
        z-index: -1;
		> li{
			display: inline-flex;
			flex-grow: 0;
			flex-shrink: 0;
			margin: 0 .5rem;
			&.slick-active > button{
				background-color: $navy-600;
			}
		}
		> li > button{
			//width: 10px;
			//height: 11px;
			background-color: $navy-400;
			flex-grow: 0;
			flex-shrink: 0;
			border-radius: 100%;
			border: 0;
			padding: 5px 5px;
			cursor: pointer;
			&:focus{
				outline: 2px solid $orange;
			}
		}
	}

	img{
		max-width: 100%;
	}

}
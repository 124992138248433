.solutions-feed {
    @extend %base-block;
    padding: 3rem 4rem;
    flex-wrap: wrap;
    &__heading {
        @extend %heading-three;
        width: 100%;
        color: $navy-900;
    }
    &__solution-parent{
        display: flex;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5rem;
            height: 6rem;
            clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
            background-color: $orange-100;
            margin-bottom: 1rem;
            margin-right: 1rem;
            i {
                font-size: 2.6rem;
                color: $orange;
            }
        }
        a {
            @extend %heading-five;
            margin-bottom: 2rem;
            text-decoration: none;
        }
    }
    &__solution-child {
        display: flex;
        flex-direction: column;
        a{
            @extend %body-copy-small;
            line-height: 1.4;
            color: $navy-500;
            margin-bottom: 1.5rem;
            text-decoration: underline;
        }
    }
    //container display overrides by column size
    &__container{
        @include el-size(full, half, two-thirds, three-quarters){
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
            padding-right: 2rem;
            .solutions-feed__solution-parent {
                flex-direction: column;
            }
            @include tablet-below {
                flex-direction: column;
            }
        }
        @include el-size(third, quarter){
            display: flex;
            flex-direction: column;
            .solutions-feed__solution-parent {
                align-items: center;
                a{
                    margin-bottom: 1.5rem;
                }
            }
            .solutions-feed__solution-child{
                padding-left: 6rem;
            }
        }
    }

    &__solution {
        width:100%;
        @include el-size(full, half, two-thirds, three-quarters){
            padding-right:3rem;
            @include tablet-below {
                padding-right:0;
            }
        }
    }
}
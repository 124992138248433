

.home-facilities-slider {

    position:relative;
    background-color: $navy-200;
    padding: 0;

    @include phone-large-below {
        display: flex;
        flex-direction: column;
    }

    &__slider-wrapper {
        position:relative;
        width:100%;
        max-width:1800px;
        margin:0 auto;
    }

    &__slider {
        display:flex;
        .slick-list {
            padding: 0 0 11rem 0;
            @include phone-large-below {
                padding-top:3rem;
            }
        }
        .slick-track {
            display:flex !important;
            align-items: stretch;
        }
        .slick-slide {
            height:initial;
            > div {
                height: 100%;
            }
        }
        .facilities-slide {
            height:100%;
        }
        .facilities-slide__content {
            height:100%;
        }
    }

    &__controls-wrapper {
        position:absolute;
        bottom:16rem;
        width:100%;
        z-index: 3;
        left: 0;
        right: 0;
        @include phone-large-below {
            bottom:18.5rem;
        }

    }

    &__slider-controls {
        position:relative;
        bottom:0;
        right: 8rem;
        margin-left: auto;
        display:flex;
        @include phone-large-below {
            background-color:$navy-100;
        }
        &-left.button,
        &-right.button {
            background:#FFF;
            height:4.4rem;
            width:4.4rem;
            display:flex;
            justify-content: center;
            align-items: center;
            border:2px solid $navy;
            transition: all .2s ease-out;
            border-radius: .4rem;
            margin: 0;
            margin-left: 1.2rem;
            padding: 0;
            i {
                font-size:2.4rem;
            }
            &.slick-disabled {
                opacity:0.35;
                i::after {
                    display:none;
                }
                &:hover {
                    background:white;
                    color:$navy;
                    i::before {
                        transform:none;
                    }
                }
            }
            @include phone-large-below {
                background:none;
                border:0;
                padding: 0;
                margin:0;
                i::after {
                    display:none;
                }
                .using-mouse &:focus,
                .using-mouse &:hover {
                    background:none;
                    color:$navy;
                    i::before {
                        transform:none;
                    }
                }
            }
        }
        @include phone-large-below {
            // &-right.button {
            //     margin-left: -10px;
            // }
            // &-left.button {
            //     margin-right: -10px;
            // }
        }
    
    }

    &__slider-dots-wrapper {
        position: absolute;
        top: 4rem;
        width: fit-content;
        left: calc(50vw - 15rem);
        margin: 0;
        padding: 0;
        background: #fff;
        border-radius: 5px;
        box-shadow: $shadow-100;
        padding:2rem;

        @include desktop-large-below {
            left:auto;
            right: 5rem;
        }

        @include tablet-large-below {
            left:3rem;
            right:3rem;
            margin:0 auto;
            top: -6rem;
        }

        @include phone-large-below {
            background:none;
            box-shadow:none;
            padding:0;
            position:relative;
            top:0;
            left:0;
            padding: 0 3rem;
            order:-1;
            margin-bottom: -46px;

            > .home-facilities-slider__slider-dots {
                display:none;
            }


        }
    }

    &__slider-dots {
        position:relative;
        .slick-dots {
            display:flex;
            @include phone-large-below {
                flex-wrap: wrap;
                justify-content: center;
            }
            li {
                display: flex;
                justify-content: center;
                align-items: center;
                @include phone-large-below {
                    align-items: stretch;
                    width: calc(50% - 8px);
                    margin: 4px;
                }
                // @for $x from 1 through 5 {     
                //     &:nth-child(#{$x - 1}) button {
                //         transition-delay: 80ms * ($x - 1);
                //     }
                // }
            }
           
        }
    }

    &__dots-title {
        @extend %heading-five;
        font-size:1.6rem;
        font-weight:700;
        margin-bottom:1.7rem;
        color:$navy;
        @include tablet-large-below {
            text-align:center;
        }
        @include phone-large-below {
            font-size:2.1rem;
        }
    }

    &__slider-dot {
        position:relative;
        display:flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 5px;
        border:0;
        font-family: 'GT America', sans-serif;
        font-weight: 500;
        font-size: 1.7rem;
        line-height: 120%;
        color:$navy-500;
        background-color:#fff;
        border:0;
        padding: 1.3rem 2.5rem;
        
        // transition: background-color .1s ease-out;
        // transform:translateY(0);
        // opacity:1;

        svg {
            opacity:0;
            width:2rem;
            margin-right:1rem;
            position: relative;
            transition: opacity .2s ease-out;
        }
        &:hover {
            cursor:pointer;
            background-color: $navy-100; 
        }
        .slick-active & {
            background-color: $navy-200; 
            color:$navy;
            font-weight:700;
            svg {
                opacity:1;
            }
        }
        // body:not(.using-mouse) .slick-active & {
        //     &:focus {
        //         outline: 2px solid $orange;
        //     }
        // }
        @include phone-large-below {
            font-size:2rem;
            border: 2px solid $navy-400;
            background:none;
            padding: 7px 2px;
            width: 100%;
            .slick-active & {
                border-color: $navy;
            }
        }
    }

    &__slider-dot--bold {
        // opacity:0;
        // font-weight:700;
    }

    &__dropdown-list {
        .home-facilities-slider__slider-dots .slick-dots li{
            width:100%;
        }
        .home-facilities-slider__slider-dot {
            border: 0;
            border-radius: 0;
        }
        .facilities-tab__wrapper {
            text-align: left;
            padding: 1.5rem 3.5rem;
            border-bottom: 1px solid $navy-200;
        }
        .facilities-tab__text {
            color:$navy-900;
            font-weight: 400;
            font-family: GTAmerica Standard,sans-serif;
            position: relative;
            text-decoration: underline;
        }
        .facilities-tab__text-bold {
            display:none;
        }
        .slick-dots li {
            margin:0;
        }
        .slick-active {
            .home-facilities-slider__slider-dot {
                background-color: $navy-600;
                .facilities-tab__text {
                    color: #fff;
                    opacity:1;
                }
            }
        }
        li:last-of-type .facilities-tab__wrapper{
            border-bottom: none;
        }
    }

    &__dropdown {
        display:none;
        @include phone-large-below {
            display:block;
        }

        &-dots-source {
            display:none;
        }

        .home-facilities-slider__slider-dot {
            padding:0;
        }

        .dropdown-toggle__selection--facilities {
            font-weight: 700;
        }

        // Dropdown styles 
        // @include column(3);
        // margin-right: 2rem;
        // @include desktop-small-below {
        //     @include column(5.8);
        //     margin-bottom: 2rem;
        //     &:nth-of-type(even) {
        //         margin-right: 0;
        //     }
        // }
        // @include phone-large-below {
        //     @include column(12);
        //     margin-right: 0;
        // }
    
        // &-label {
        //     font-size: 2rem;
        //     display: block;
        //     margin-bottom: 2rem;
        // }
    
        .dropdown-toggle {
            cursor: pointer;
            font-size: 2.4rem;
            color: $navy-500;
            background: #fff;
            border: none;
            box-shadow: $shadow-300;
            border-radius: 5px;
            @include column(12);
            text-align: left;
            padding: 2rem 2rem 2rem 3.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            span:first-child {
                overflow:hidden;
                text-overflow: ellipsis;
                margin-right: 1rem;
            }
    
            &__icon {
                display: flex;
                transform: rotate(0);
                transition: 0.4s ease;
                i {
                    color: $navy;
                }
            }
    
            &::after {
                display:none;
            }
        }
        
        .dropdown-toggle.show {
            .dropdown-toggle__icon {
                transform: rotate(180deg);
            }
        }
        
        .dropdown-menu {
            z-index: 5;
            overflow-x:hidden;
            @include column(12);
            border: none;
            border-top: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            margin-top: -5px !important;
            box-shadow: $shadow-100;
            padding-top:0;
            ul {
                transition: 0.5s ease;
        
                li span {
                    font-size: 2.1rem;
                    line-height: 1;
                    white-space: normal;
                }
            }
        }
        
        .dropdown-item {
            cursor: pointer;
            padding: 1.5rem 3.5rem;
            border-bottom: 1px solid $navy-200;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:first-of-type {
                margin-top: 1rem;
            }
            &:last-of-type {
                border-bottom: none;
                margin-bottom: 2rem;
            }
            span {
                text-decoration: underline;
                color: $navy-900;
            }
            &:hover, &:focus {
                background: $navy-200;
            }
            &:active {
                background: none;
            }
        
        
            // &--active {
            //     background-color: $navy-600 !important;
            //     & > span {
            //         color: $white !important;
            //     }
            //     li {
            //         color: #212529;
            //     }
            // }
        }
          // End Dropdown styles 
        
    }
}

//Going to great lengths to avoid layout shift by normal to bold text 
//Have a hidden bold version to create max width
//Overlapping CSS with slider-dots
.facilities-tab {
    &__wrapper {
        position:relative;
        @include phone-large-below {
            text-align: center;
            width: 100%;
        }
    }
    &__text {
        font-family: "GTAmerica Extended", sans-serif;
        position: absolute;
        left: 0;
        top: 0;
        right:0;
        transition: opacity .1s ease-out;
        font-weight:400;
        color:$navy-600;
        @include phone-large-below {
            font-size:14px;
        }
        @media (max-width: 380px){
            font-size:13px;
        }
    }
    &__text-bold {
        font-family: "GTAmerica Extended", sans-serif;
        font-weight:700;
        opacity:0;
        transition: opacity .1s ease-out;
        @include phone-large-below {
            font-size:14px;
        }
        @media (max-width: 380px){
            font-size:13px;
        }
    }
}

.facilities-slide {

    display:flex !important;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        margin-top: 10rem;
        padding: 6rem 8rem 7rem 15rem;
        min-height: 65rem;
        margin-left: calc(50% - 8rem);
        width: calc(50% + 8rem);

        background-color: #fff;
        box-shadow: $shadow-100;
        border-radius: 5px;

        @include desktop-xlarge-above {
            padding: 6rem 8rem 7rem 18rem;
        }

        @include tablet-large-below {
            z-index:2;
            width:100%;
            padding: 4rem;
            min-height: auto;
            margin-top:38rem;
            margin-left: 0;
            justify-content: flex-start;
        }

        @include phone-large-below {
            padding: 3rem;
            margin-top:250px;
        }
    }

    &__title {
        @extend %heading-two;
        transition: all .2s cubic-bezier(.23, .65, .63, .99) .1s;
        transform:translateY(2rem);
        opacity:0;
        @include phone-large-below {
            font-size: 2.7rem;
            line-height: 1.4;
            margin-bottom:1.5rem;
        }
    }

    &__tab {
        //used by slick JS
        display:none;
    }

    &__desc {
        @extend %body-copy;
        color:$navy;
        transition: all .2s cubic-bezier(.23, .65, .63, .99) .2s;
        transform:translateY(2rem);
        opacity:0;
        @include phone-large-below {
            margin-bottom:1rem;
        }
    }

    &__button {
        transition: all .2s cubic-bezier(.23, .65, .63, .99) .3s;
        transform:translateY(2rem);
        opacity:0;
        @include phone-large-below {
            margin-bottom:0;
        }
    }

    &__img {
        position:absolute;
        right: calc(50% - 5px);
        box-shadow: $shadow-100;
        border-radius: 0 5px 5px 0;
        overflow:hidden;
        margin-bottom: 4rem;
        height:calc(100% - 5rem);
        width: calc(50% + 5px);
        z-index:1;

        @media(min-width:1800px){
            border-radius: 5px;
            right:initial;
            left:3rem;
        }

        @include tablet-large-below {
            width: calc(100% - 20px);
            left: 10px;
            right: 10px;
            height:45rem;
        }

        @include phone-large-below {
            height:300px;
        }

        > div {
            width:100%;
            height:100%;
        }

        picture {
            display:block;
            width:100%;
            height:100%;
        }

        img {
            object-fit: cover; 
            width: 100%;
            height: 100%;
        }
    }
}


.home-facilities-slider__slider-controls {


        //-----------------------------------
        // Mobile Only dots
        .home-facilities-slider__slider-dots {
            display:none;
        }
        @include phone-large-below {
            right: 0;
            left: 0;
            margin: 0 auto;
            padding:0.6rem 1.6rem;
            border-radius: 5px;
            width: fit-content;
            background-color: #F4F5FA;

            .home-facilities-slider__slider-dots {
                display:flex;
                justify-content: center;
                width:100%;
            }

            .slick-dots {
                display:flex;
                align-items: center;
                margin: 0 auto;
                padding:0;
                flex-wrap:nowrap;
        
                li {
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    margin:0;
                }
            }

            .facilities-tab__wrapper {
                display:none;
            }

            .home-facilities-slider__slider-dot {
                width:10px;
                height:10px;
                border-radius: 100%;
                padding:0;
                margin: 0 1rem;
                border:0;
                transition: all .2s ease-out;
                background-color:#B5BDCD;
            }

            .slick-active .home-facilities-slider__slider-dot {
                background-color:#3D65A4; 
            }
        }
        // End mobile only dots
        //-----------------------------------
}


.home-facilities__mobile-button {
    display:none;
    margin-bottom: 5rem;
    width: 100%;
    justify-content: center;

    .button--secondary-alt {

        .link-text {
            color: $navy;
        }

        border-color: $orange;
        i::before{
          color: $orange;
        }
        &:hover{
          border-color: #fff;
          i::before{
            color: $orange;
          }
        }
    }

    .button--secondary {
        border-color: $orange;
        i::before{
          color: $orange;
        }
        &:hover{
          border-color: #fff;
          i::before{
            color: $orange;
          }
        }
        margin:0;
    }
    @include phone-large-below {
        display:flex;
        margin-right: 0;
        .button {
            margin-right: 0;
        }
    }
}

.facilities-show-content {
    .facilities-slide__tag,
    .facilities-slide__title,
    .facilities-slide__desc,
    .facilities-slide__button {
        transform:translateY(0);
        opacity:1;
    }
}


.slide-in .facilities-slide__img {
    animation: .6s forwards slideInImageRight;
    @include tablet-large-below {
        animation: .6s forwards slideInImageTop;
    }
}

.slide-out .facilities-slide__img {
    animation: .6s forwards slideOutImageRight;
    @include tablet-large-below {
        animation: .6s forwards slideOutImageTop;
    }
}

.slick-active {
    .facilities-tab__text {
        opacity:0;
    }
    .facilities-tab__text-bold {
        opacity:1;
    }
}

.slick-active {
    // .facilities-tab__text {
    //     font-weight:700;
    // }
}

.home-facilities-slider--animate-tab {
    // transform:translateY(2rem);
    // opacity:0;
    // .slick-active {
    //     .facilities-tab__wrapper {
    //         opacity:0;
    //     }
    // }
}
%block-base{
  // Minimum width of 2 columns @ full width
  //min-width: 285px;
}


.inline-block--settings{
  clear:both;

  &-align{
    &-left{
      float:left;
      margin-right: 2rem;
      margin-bottom: 0rem;
    }
    &-right{
      float:right;
      margin-left: 2rem;
      margin-bottom: 0rem;
    }
  }
  &-size{
    &-small{
      padding:3.5rem;
      max-width:400px;
      @include tablet-small-below{
        float:none;
        max-width:100%;
      }
    }
    &-medium{
      padding:3.8rem;
      padding-top: 0;
      max-width:600px;
      @include tablet-large-below{
        float:none;
        max-width:100%;
      }

    }
    &-large{
      padding:4rem;
      padding-top: 0;
      max-width:800px;
      @include desktop-small-below{
        float:none;
        max-width:100%;
      }
    }
  }
}



// Component Tester styles
.component-tester-title{
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 5;
  margin-top: 10rem;
  margin-bottom: 2rem;
  span{
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.4rem;
    display: block;
    color: #648AAB;
    background-color: #EBF2FF;
    display: inline-block;
    padding: 1rem 2rem;
    border-radius: 2rem;
    z-index: 2;
    position: relative;
  }
  &::before{
    content:'';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #dae2ef;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
}


.component-tester-gap{
  height: 300px;
  display: block;
  width: 100%;

}


.row-container{
  &--bg-white{
    background-color: #fff;
  }
  &--bg-ultralight-blue{
    background-color: $navy-100;
  }
  &--bg-light-blue{
    background-color: $navy-200;
  }
  &--bg-muted-blue{
    background-color: $navy-300;
  }
  &--bg-regular-blue{
    background-color: $navy;
    .col-bg:not(.col-bg--white):not(.col-bg--color-ultralight-blue):not(.col-bg--color-light-blue):not(.col-bg--color-muted-blue) {
        *:not([class*=rich-text--color-]):not(.link-text),
        span.rich-text--color-navy-dark,
        span.rich-text--color-navy {
          color: #fff;
        }
    }
  }
  &--bg-dark-blue{
    background-color: $navy-900;
    .col-bg:not(.col-bg--white):not(.col-bg--color-ultralight-blue):not(.col-bg--color-light-blue):not(.col-bg--color-muted-blue) {
        *:not([class*=rich-text--color-]):not(.link-text),
        span.rich-text--color-navy-dark,
        span.rich-text--color-navy {
          color: #fff;
        }
    }
  }
}
form {
    // background-color:#fff;
    // border-radius:5px;
    // box-shadow:$shadow-300;
	max-width:100%;
	margin-bottom:2rem;
	display: flex;
    flex-direction: column; 

    .freeform-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        flex-wrap: wrap;
        @include phone-large-below {
            flex-direction:column;
        }
    }
    .freeform-column{
        //width: 100%;
        flex: 1 0;
        padding: 10px 0;
        margin: 0 10px;
        box-sizing: border-box;
    }

    input, select, textarea {
        font-size: 2.2rem;
        font-family: $font-body;
        padding: 1.5rem 2.1rem;
        color: $navy;
        border: none;
        border-radius: 5px;
        background-color: #F7F9FB;
        border-radius: 6px;
        width: 100%;
        &:focus {
            outline: 2px solid $orange;
        }
    }

    textarea {
        resize: vertical;
    }

    .freeform-row .freeform-column .freeform-label.freeform-required:after {
        content: '*';
        color: $orange;
        margin-left: 1rem;
    }

    label:not(.checker), .checkboxes legend, .radioBoxes legend {
        font-size: 2rem;
        display: block;
        margin-bottom: 1rem;
        color: $navy;
        font-weight: 400;
        .freeform-required & {
            &::after{
                content: '*';
                color: $orange;
                margin-left: 1rem;
            }
        }
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $navy-400;
        opacity: 1; /* Firefox */
        font-size: 2.2rem;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $navy-400;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $navy-400;
    }

    select {
        margin: 0;      
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;

        background-image:
            linear-gradient(45deg, transparent 50%, $navy-400 50%),
            linear-gradient(135deg, $navy-400 50%, transparent 50%);
        background-position:
            calc(100% - 25px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
        background-size:
            10px 10px,
            10px 10px,
            2px 3em;
        background-repeat: no-repeat;
    }

    select:focus {
        background-image:
        linear-gradient(45deg, $navy-400 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, $navy-400 50%);
        background-position:
            calc(100% - 15px) 1em,
            calc(100% - 25px) 1em,
            calc(100% - 2.5em) 0.5em;
        background-size:
            10px 10px,
            10px 10px,
            2px 3em;
        background-repeat: no-repeat;
        border: 2px solid $orange;
        outline: 0;
    }

    select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }

    .freeform-form-has-errors {
        color:#ff4052;
        font-size: 2.2rem;
        margin: 1.3rem 1rem;
    }

    .freeform-row .freeform-column .freeform-errors > li, 
    .freeform-row .freeform-column .ff-errors > li {
        font-size: 1.6rem;
        color:#ff4052;
    }

    .freeform-alert {
        font-size:2rem;
        font-weight:bold;
        &.freeform-alert-success {
            color:$orange;
        }
    }

    &.projectInterest {
        .freeform-alert.freeform-alert-success {
          //This website does NOT use AJAX, so success messages are not needed
            //They are also causing an unusual bug where they incorrectly appear
            //So.. just hide them.
            display:none;
        }
    }
}

//*** Custom Checkbox

.checkbox {

        height: 100%;
        display: flex;
        align-items: center;

        label {
            font-size: 1.7rem;
            font-weight: 400;
            display: flex;
            align-items: flex-start;
            margin-right: 3rem;
            padding: 1rem 0;
            cursor: pointer;
            color: $navy;
            &:hover{
                .checkmark-title{
                    //opacity: 1;
                    //font-weight: 600;
                }
                .checkmark{
                    border: 2px solid $navy;
                    &:after {
                        color: $navy;
                        opacity: 1;
                    }
                }
                input[type=checkbox]:checked + .checkmark{
                    &::after{
                        color: $orange;
                    }
                }
            }

        }
        input[type=checkbox] {
            position: absolute;
            opacity: 0;
            width:0;
        }
        input:focus + span.checkmark {
            outline: 2px solid $orange; //check with Chris
        }
        .checkmark {
            width: 22px;
            height: 22px;
            display: block;
            border: 2px solid rgba( $navy, .3);
            background-color: #fff;
            border-radius: 6px;
            position: relative;
            margin-right: 0.7rem;
            margin-top: 0.2rem;
            transform: translateY(-.2rem);
            flex-shrink: 0;
            transition: border .15s ease-in-out;
            &::after{
                font-family: 'icomoon';
                content: '\e925';
                font-weight: 900;
                color: $orange;
                font-size: 1.4rem;
                position: absolute;
                left: 50%;
                top:  50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: all .15s ease-in-out;
            }
        }
        input[type=checkbox]:checked + .checkmark{
            border-color: $orange;
        }

        input[type=checkbox]:checked + .checkmark::after{
            opacity: 1;
        }
        input[type=checkbox]:checked + .checkmark + .checkmark-title{
            opacity: 1;
        }
        .checkmark-title{
            line-height: 1.2;
            opacity: .9;
            transition: all .15s ease-in-out;
            user-select: none; /* supported by Chrome and Opera */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
        }

}


//*** Custom Radio 

.radioButton {
    .freeform-row & {
        display:block;
        label {
            font-size: 1.7rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            margin-right: 3rem;
            padding: 1rem 0;
            cursor: pointer;
            color: $navy;
            &:hover{
                .radio-title{
                    //opacity: 1;
                    font-weight: 600;
                }
                .radio{
                    border: 2px solid $navy;
                    &:after {
                        background-color: $navy;
                        opacity: 1;
                    }
                }
            }
        }
        input[type=radio]{
            position: absolute;
            opacity: 0;
            width:0;
        } 
        input:focus + span.radio {
            outline: 2px solid $orange; 
        }
        .radio { 
            width: 22px;
            height: 22px;
            display: block;
            border: 2px solid #E5E5E5;
            background-color: #fff;
            border-radius: 50%;
            position: relative;
            margin-right: .7rem;
            &::after{
                content: '';
                width:12px;
                height:12px;
                border-radius: 50%;
                background-color: $orange;
                position: absolute;
                left: 50%;
                top:  50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: all .15s ease-in-out;
            }
        }
        input[type=radio]:checked + .radio {
            border: 2px solid $orange;
        }
        input[type=radio]:checked + .radio::after{
            opacity: 1;
        }
        input[type=radio]:checked + .radio + .radio-title{
            opacity: 1;
        }
        .radio-title{
            //opacity: .6;
            transition: all .15s ease-in-out;
            user-select: none; /* supported by Chrome and Opera */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
        }
    }
}
.teaser-grid{
display: flex;
  flex-wrap: wrap;
  width: 100%;
  &:after {
    content: '';
    display: block;
    clear: both;
  }

}

/* clearfix */
.teaser-grid-item{
 // width: calc(25% - 2rem);
  //padding-bottom: 2rem;
  padding-right: 2rem;
  padding-left: 0;
  display: flex;
  @include desktop-large-below{
    width: 33%
  }
  @include tablet-large-below{
    width: 50%;
  }
  @include tablet-small-below{
    width: 100%;
    padding-right: 0;
  }

  &--double{
   // width: calc(50% - 2rem);
    @include desktop-large-below{
      width: 66%;
    }
    @include tablet-large-below{
      width: 100%;
      padding-right: 0;
    }

  }
}

.gutter-sizer{
  width: 2rem;
}


body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-font-kerning: normal;
    font-kerning: normal;
}

%text-base{
    &.thin{
        font-weight: 300;
    }
}


%heading-base{
    font-family: $font-heading;
    color: $navy;
    font-weight: 500;
}

h1, h2, h3, h4, h5, p, li, span{
    font-variant-ligatures: none;
}

h1, h2, h3, h4, h5 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding: 0;
}

%heading-one{
    @extend %text-base;
    @extend %heading-base;
    font-size: 6.2rem;
    line-height: 1.2;
    margin-bottom: 3rem;

    @include desktop-large-below{
        font-size: 5.2rem;
    }
    @include desktop-medium-below{

    }
    @include desktop-small-below{

    }
    @include tablet-below{
        font-size: 4.5rem;
    }
    @include phone-large-below {
        font-size: 4.7rem;
    }
}

%heading-two{
    @extend %text-base;
    @extend %heading-base;
    font-size: 4.5rem;
    line-height: 1.3;
    margin-bottom: 3rem;

    @include desktop-large-below{
        font-size: 4rem;
    }
    @include desktop-medium-below{

    }
    @include desktop-small-below{

    }
    @include tablet-below{
        font-size: 3.5rem;
    }
    @include phone-large-below{

    }

    &.heading-alt{
        @extend %heading-two-alt;
    }
}

%heading-two-alt{
    @extend %text-base;
    @extend %heading-base;
    font-size: 4rem;
    line-height: 1.1;
}


%heading-three{
    @extend %text-base;
    @extend %heading-base;
    font-size: 3.2rem;
    line-height: 1.2;
    margin-bottom: 2rem;

    @include desktop-large-below{
        font-size: 2.8rem;
    }
    @include desktop-medium-below{

    }
    @include desktop-small-below{

    }
    @include tablet-below{
        font-size: 3rem;
    }
    @include phone-large-below{
        font-size: 3.3rem;
    }
}

%heading-four{
    @extend %text-base;
    @extend %heading-base;
    font-size: 2.6rem;
    margin-bottom: 1rem;
    line-height: 1.25;

    @include desktop-large-below{
        font-size: 2.4rem;
    }
    @include desktop-medium-below{

    }
    @include desktop-small-below{

    }
    @include tablet-below{
        font-size: 2.5rem;
    }
    @include phone-large-below{
        font-size: 2.9rem;
    }
}

%heading-five{
    @extend %text-base;
    @extend %heading-base;
    font-size: 2.4rem;
    margin-bottom: 1rem;
    line-height: 1.25;

    @include desktop-large-below{
        font-size: 2.1rem;
    }
    @include desktop-medium-below{

    }
    @include desktop-small-below{

    }
    @include tablet-below{
        font-size: 2.2rem;
    }
    @include phone-large-below{
        font-size: 2.7rem;
    }
}


%heading-six{
    @extend %text-base;
    @extend %heading-base;
    font-size: 1.9rem;
    margin-bottom: 1rem;
    line-height: 1.25;

    @include desktop-large-below{
        font-size: 1.9rem;
    }
    @include desktop-medium-below{

    }
    @include desktop-small-below{

    }
    @include tablet-below{
        font-size: 2.2rem;
    }
    @include phone-large-below{
        //  font-size: 3rem;
    }
}




h1 {
    @extend %heading-one;
}

h2 {
    @extend %heading-two;

}

h3 {
    @extend %heading-three;
}

h4 {
    @extend %heading-four;
}

h5 {
    @extend %heading-five;
}

h1, h2, h3, h4, h5{

}


%body-copy{
    font-size: 2rem;
    line-height: 1.6;
    font-weight: 400;
    font-family: $font-body;


    @include desktop-large-below{
        font-size: 1.8rem;
    }



    @include tablet-below {
        font-size:2.1rem;
    }

    @include phone-large-below {
        font-size:2.3rem;
    }
}

%body-copy-small{
    font-size: 1.8rem;
    line-height: 1.6;
    font-family: $font-body;
    @include desktop-large-below{
        font-size: 1.7rem;
    }
    @include tablet-below{
        font-size: 2rem;
    }
    @include phone-large-below {
        font-size: 2.2rem;
      }
}


%body-copy-xsmall{
    font-size: 1.6rem;
    line-height: 1.6;
    font-family: $font-body;
    @include phone-large-below {
        font-size:2rem;
    }
}


%body-copy-large{
    font-size: 2.4rem;
    line-height: 1.5;
    font-weight: 500;
    font-family: $font-body;
    color: $navy;
    @include desktop-large-below{
        font-size: 2.2rem;
    }
    @include tablet-below{
        font-size: 2.4rem;
    }
    @include phone-large-below {
        font-size: 2.7rem;
    }
}

%block-title{
    font-size: 2.4rem;
    line-height: 1.5;
    font-weight: 500;
    font-family: $font-heading;
    color: $navy;
}


%heading-subline{
    @extend %body-copy-large;
    font-weight: 700;
}

%font-regular{
    font-family: $font-body;
}

%font-header{
    font-family: $font-heading;
}

span{
    // @extend %body-copy;
}


p, li{
    @extend %body-copy;
    //color: #4F4F4F;

    strong{
        // color: #262626;
    }
    em{
        font-style: italic;
    }
    a{
        font-weight: 700;
        .main-content &{
            text-decoration: underline;
        }
    }
    &.body-large{
        @extend %body-copy-large;
    }

    &.body-small{
        @extend %body-copy-small;
    }
}

span{
    &.pretitle{
        font-size: 2rem;
        font-family: $font-heading;
        margin-bottom: 1rem;
        display: block;
    }
}

p{
    margin-bottom: 3rem;
    max-width: 1200px;
    //Commented out as font color is being done in the body (main.scss)
    //color: $navy-500; //Default body color changed from 900 to 500 in the last round of designs
    &.highlight-text{
        max-width: 1200px;
    }
    &.large-text{
        font-size:2.4rem;
        max-width:100%;
    }
}

span{
    &.rich-text--color{
        &-navy {
            color:$navy;
        }
        &-orange {
            color:$orange;
        }
        &-default{
            color:$navy-500;
         }
        &-navy-dark{
           color:$navy-900;
        }
    }
}

.rich-text {
    ul {
        @extend %list-simple;
        margin-bottom:3rem;
        li > ul {
            margin-top:1.5rem;
        }
    }
    ol {
        @extend %list-base;
        margin-bottom: 2rem;
        padding-left: 2.3rem;
        li::marker {
            display: block;
            color: $orange;
        }
        li > ol {
            margin-top:.5rem;
            li::marker {
                display: block;
                color: $navy;
            }
        }
    }
    color: $navy-500;
}


%sub-heading{
    @extend %text-base;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: .3rem;
    font-size: 2rem;
    @include phone-large-below{

    }
}


.sub-heading{
    @extend %sub-heading;
}





sup {
    font-size: 0.5em;
    vertical-align: super;
    line-height: 0;
}

strong{
    font-weight: 700;
}

a{
    text-decoration: none;
    color: $navy;
    &:hover{
        text-decoration: underline;
    }
    //font-size: 1.9rem;
    //color: $primary-blue;
    //text-decoration: underline;
}

p.highlight-text{
    @extend %body-copy-large;
    color: var(--color-highlight);
}


%list-base{
    &.two-column{
        display: flex;
        flex-wrap: wrap;
        li{
            @include column(6);
            padding-right: 3rem;
            @include phone-large-below{
                @include column(12);
                padding-right: 0;
            }
        }
    }
}

%list-simple{
    @extend %list-base;
    // Add margin to level one items
    > li{
        margin-bottom: 1.5rem;
    }
    li{
        position: relative;
        padding-left: 1.5rem;
        //margin-left: 2rem;
        &::before{
            content: '';
            display: block;
            width: .5rem;
            height: .5rem;
            position: absolute;
            left: 0;
            top: 1.3rem;
            border-radius: 100%;
            background-color: $orange;
        }
        li {
            &::before {
                background-color: $navy-500;
            }
        }
    }
}

%list-simple-small{
    @extend %list-base;
    // Add margin to level one items
    > li{
        margin-bottom: 1.8rem;
        > ul > li{
            margin-top: .5rem;
            margin-bottom: .5rem;
        }
    }
    li{
        position: relative;
        padding-left: 1.3rem;
        @extend %body-copy-small;
        line-height: 1.2;
        &::before{
            content: '';
            display: block;
            width: .5rem;
            height: .5rem;
            position: absolute;
            left: 0;
            top: .9rem;
            border-radius: 100%;
            background-color: $orange;
        }
        li {
            &::before {
                background-color: $navy-500;
            }
        }
    }
}






.text{
    &--color-orange{
        color: $orange;
    }

    &--color-navy{
        color: $navy;
    }
}


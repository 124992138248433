.page-header__social-share {
    position: absolute;
    top: 15%;
    right: 0;
    display: flex;
    align-items: center;
    padding-right: 3rem;

    .view-page-header-full & {
        @include desktop-small-below {
            top: 0;
            margin-top: 12rem;
        }
    }

    .page-header--news-release &{
        top: 0;
        @include desktop-small-below {
            top: 3.5rem;
        }
    }

}


.social-share-widget {

    display: flex;
    align-items: center;

    .video-view-page & {
        margin-top: 4rem;
    }
    
    span, a {
        color: $white;
        opacity: 0.7;
    }
    span {
        font-size: 1.8rem;
        font-weight: 700;
        margin-right: 2rem;
    }
    &-link {
        margin-right: 1rem;
        font-size: 2.8rem;
        transition: 0.2s ease;
        &:last-of-type {
            margin-right: 0;
        }
        &:hover, &:focus {
            text-decoration: none;
            opacity: 1;
        }
    } 

    &--dark {
        span, a {
            color: $navy-500;
        }
    }
}
.project-block{
	@extend %base-block;
	position: relative;
	min-height: calc(100% - 2rem);
  	@include el-size(half, third, quarter, sixth) {
  		flex-direction:row;
  		@include desktop-medium-below{
  			flex-direction:column;
  		}
  	}
	@include el-size(full, two-thirds, three-quarters) {
		@include tablet-small-below {
			flex-direction:column;
		}
	}



	&.no-image {
		.project-block__primary {
			@include column(12);
		}
		.project-block__tag{
			@include el-size (full, two-thirds, three-quarters, half, third, quarter, sixth){
				@include desktop-medium-below{
					margin-top: 2rem;
					margin-bottom: 2rem;
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
					transform: none;
				}
			}
		}

    }
	&__primary{
		@extend %base-block__primary;
		padding-bottom: 1rem;
		justify-content: flex-start;
		.project-block__title {
			// @extend %heading-five;
			// font-size: 2rem;
			@include el-size(two-thirds, three-quarters, full){
				@extend %heading-three;
			}
			@include el-size(half){
				@extend %heading-five;
				@include phone-large-below{
					font-size: 2.9rem;
				}
			}
			@include el-size(third, quarter, sixth){
				@extend %heading-six;
				@include phone-large-below{
					font-size: 2.9rem;
				}
			}
		}
  		@include el-size (half, third, quarter, sixth){
			@include desktop-medium-below{
				@include column(12);
				padding-top: 0;
			}	
  		}
		@include el-size(full, two-thirds, three-quarters) {
			@include tablet-small-below {
				@include column(12);
				padding-top: 0;
			}
		}
	}
	picture {
		@include column(5);
		@include el-size (half, third, quarter, sixth){
			@include desktop-medium-below {
				@include column(12);
		  	}
		}
		@include el-size(full, two-thirds, three-quarters) {
			@include tablet-small-below {
				@include column(12);
			}
		}
	}

	&__secondary{
		width: 100%;
		height: 100%;
		//min-height:200px;
		//border-radius: 5px;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		object-fit: cover;
		object-position: center;
		// @include column(6);
		// background-size: cover;
		// background-position:center;
		// background-repeat: no-repeat;
  		@include desktop-medium-below{
  			@include column(12);
  			// padding:2rem;
  			// margin-top:2rem;
  			background-origin: content-box;
			@include el-size (half, third, quarter, sixth){
				// padding:2rem 2rem 0;
				width:100%;
				position:relative;
				flex-shrink: 0;
				border-bottom-right-radius: 0;
			}
  		}
		@include el-size (full, two-thirds, three-quarters) {
			@include tablet-small-below {
				// padding:2rem 2rem 0;
				width:100%;
				position:relative;
				flex-shrink: 0;
				border-bottom-right-radius: 0;
			}
		}
	}

	&__tag{
		@extend %base-tag;
		display:inline-block;
		margin-bottom: 1rem;
		@include el-size (half, third, quarter, sixth){
			@include desktop-medium-below{
				margin-bottom: 0;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				transform: translate(0, -50%);
    			margin-left: -2rem;
			}
		}
		@include el-size (full, two-thirds, three-quarters){
			@include tablet-small-below{
				margin-bottom: 0;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				transform: translate(0, -50%);
    			margin-left: -2rem;
			}
		}

	}
	&__open-popover{
		position: absolute;
		right:1rem;
		color:$navy;
		cursor:pointer;
		text-decoration:none;
		padding:1.5rem;
		background:#fff;
		border-radius:.5rem;
		transition:all 0.3s ease-in-out;
		&:hover{
			background:$navy-200;
			text-decoration: none;
			transition:all 0.3s ease-in-out;
		}
		i{
			text-decoration:none;
			font-size: 2.5rem;
		}
		@include el-size (half, third, quarter, sixth){
			@include desktop-medium-below{
				// padding-bottom: 0.2rem;
    			// padding-left: 1rem;
				right: 0;
				border-top-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		@include el-size (full, two-thirds, three-quarters){
			@include tablet-small-below{
				// padding-bottom: 0.2rem;
    			// padding-left: 1rem;
				right: 0;
				border-top-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
	&__popover{
		background-color:$navy;
		color:#fff;
		padding:2rem;
		.project-block__title{
			color:#fff;
			width:100%;
			padding-bottom:2rem;
			border-bottom: 1px solid #CAD2DD;
			font-size:2.5rem;
			font-family:$font-heading;
		}
		.project-block__summary{
			font-size:1.8rem;
			font-weight:200;
			margin-bottom: 2rem;
			display: block;
			width:100%;
		}
		.project-block__details{
			display:flex;
			flex-direction:row;
			flex-wrap:wrap;
			div{
				@include column(6);
				span{
					font-size:1.5rem;
					font-weight:200;
				}
			}
		}
	}
}
body .popover{
	background-color: $navy;
	border-radius:.5rem;
	max-width:432px;
	box-shadow:$shadow-300; 
}

/*** Project search results ****/
li.col--third .project-block__tag {
    // transform: translate(0, -65%);
}
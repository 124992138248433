
.home-innovation {

    &__wrapper {
        width:100%;
        // max-width:2000px;
        margin:0 auto;
        position: relative;
        @include phone-large-below  {
            padding: 0 3rem;
        }
    }

    &__slider {
        position:relative;
        padding: 7.5rem 0;
        @include phone-large-below  {
            padding: 10rem 0 4rem 0;
        }
        @include phone-large-below {
            padding: 10rem 0 0 0;
        }
    }

    &__slide {
        display:flex !important;
        flex-direction: row;
        > * {
            width:50%;
        }
        @include phone-large-below  {
            flex-direction: column-reverse;
        }
    }

    &__slide-title {
        display:none;
    }

    &__content {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        padding-right: clamp(6rem, 7vw, 13rem);
        padding-left: 3rem;
        padding-top:14rem;
        padding-bottom:8rem;

        &-inner {
            max-width:54rem;
        }

        h1, h2, h3 {
            line-height:130%;
        }

        @include phone-large-below  {
            width:100%;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 6rem 0 4rem 0;
        }

        @include phone-large-below {
            padding: 6rem 0 2rem 0;
        }
    }

    &__title {
        max-width:57rem;
        text-align: left;
        transform:translateY(2rem);
        opacity:0;
        transition: all .2s cubic-bezier(.23, .65, .63, .99) .1s;
    }

    &__copy {
        text-align: left;
        color:$navy;
        transform:translateY(2rem);
        opacity:0;
        transition: all .2s cubic-bezier(.23, .65, .63, .99) .2s;
    }

    &__button {
        transform:translateY(2rem);
        opacity:0;
        transition: all .2s cubic-bezier(.23, .65, .63, .99) .3s;
        a {
            margin-right: 0;
        }

        .button--secondary{
            border-color: $orange;
            i::before{
              color: $orange;
            }
            &:hover{
              border-color: #fff;
              i::before{
                color: $orange;
              }
            }
        }
    }

    &__img {
        box-shadow: $shadow-100;
        border-radius: 5px 0 0 5px;
        overflow:hidden;
        height:85vh;
        max-height: 80rem;
        min-height: 55rem;

        transition: all .2s cubic-bezier(.23, .65, .63, .99);

        @include phone-large-below  {
            width:100%;
            min-height: auto;
            height:40rem;
            box-shadow: none;
            border-radius: 5px;
        }

        > div {
            width:100%;
            height:100%;
        }

        picture {
            display:block;
            width:100%;
            height:100%;
        }

        img {
            object-fit: cover; 
            width: 100%;
            height: 100%;
        }
    }

    &__icon-block {
        position:absolute;
        left:0;
        right:0;
        top:23rem;
        margin:0 auto;
        z-index: 2;
        width: fit-content;
        transform:scale(.9);
        transition: all .2s cubic-bezier(.23, .65, .63, .99);
        @include phone-large-below {
            transform:scale(0.8);
        }
        svg {
            // filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
            filter: drop-shadow(0px 100px 80px rgba(31, 63, 177, 0.3));
            will-change: filter; //fixs safari bug
            width:10rem;
        }
        @include phone-large-below  {
            top:31.5rem;
            right:initial;
            left: 2rem;
            svg {
                filter: drop-shadow(0px 5px 5px rgba(31, 63, 177, 0.05));
            }
            > * {
                transform: scale(0.8);
            }
        }
    }

    &__icon {
        position:absolute;
        width:100%;
        height:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        top: 0;
        opacity:0;
        transform:scale(.96);
        transition: all .2s cubic-bezier(.23, .65, .63, .99);
        @include phone-large-below {
            transform:scale(0.86);
        }
        i {
            font-size: 7.4rem;
            color:$orange;
        }
        display:none;
    }




    &__slider .slick-slide:nth-child(odd) &__icon--innovation {
        display:flex;
    }

    //EVEN - sustainability

    &__slider .slick-slide:nth-child(even) &__img {
        order:-1;
        border-radius: 0 5px 5px 0;
        @include phone-large-below  {
            order:0;
        }
    }

    &__slider .slick-slide:nth-child(even) &__content {
        align-items: flex-start;
        padding-left: clamp(6rem, 7vw, 13rem);
        padding-right: 2rem;
        @include phone-large-below  {
            padding: 8rem 0;
            align-items: left;
        }
    }

    &__slider .slick-slide:nth-child(even) &__title {
        text-align: left;
        @include phone-large-below  {
            text-align: left;
        }
    }

    &__slider .slick-slide:nth-child(even) &__copy {
        text-align: left;
        @include phone-large-below  {
            text-align: left;
        }
    }

    &__slider .slick-slide:nth-child(even) &__icon--sustainability {
        display:flex;
    }

    

    &__tabs {
        position:absolute;
        z-index: 2;
        top:15rem;
        left:0;
        display: flex;
        width: 100%;
        justify-content: center;

        @include desktop-small-below {
            top:10rem;
        }

        @include phone-large-below  {
            top:4.5rem;
        }

        .slick-dots {
            display: flex;
            padding:2rem;
            background-color:#fff;
            box-shadow: 0px -100px 80px rgba(31, 63, 177, 0.02), 0px -41.78px 33.4221px rgba(31, 63, 177, 0.0143771), 0px -22.34px 17.869px rgba(31, 63, 177, 0.0119221), 0px -12.52px 10.0172px rgba(31, 63, 177, 0.01), 0px -6.65px 5.32008px rgba(31, 63, 177, 0.00807786), 0px -2.77px 2.21381px rgba(31, 63, 177, 0.00562291), 0px 100px 80px rgba(31, 63, 177, 0.02), 0px 41.7776px 33.4221px rgba(31, 63, 177, 0.0143771), 0px 22.3363px 17.869px rgba(31, 63, 177, 0.0119221), 0px 12.5216px 10.0172px rgba(31, 63, 177, 0.01), 0px 6.6501px 5.32008px rgba(31, 63, 177, 0.00807786), 0px 2.76726px 2.21381px rgba(31, 63, 177, 0.00562291);
            border-radius: 5px;
            @include phone-large-below {
                padding: 10px;
            }
        }
    }

    &__tab {
        background-color:#fff;
        font-family: 'GTAmerica Extended', sans-serif;
        color:$navy-900;
        font-size:1.7rem;
        padding: 1.3rem 4rem;
        border:0;
        border-radius: 5px;
        &:hover {
            cursor:pointer;
        }
        .slick-active & {
            background-color:$navy-200;
            color:$navy;
            font-weight: 700;
        }

        @include phone-large-below  {
            font-size:2rem;
        }

        @include phone-large-below {
            font-size:15px;
            padding: 10px 16px;
        }


      
    }

    

}

.home-innovation-show-content {
    .home-innovation__title,
    .home-innovation__copy,
    .home-innovation__button {
        transform:translateY(0);
        opacity:1;
    }
    .home-innovation__icon-block {
        transform:scale(1);
        @include phone-large-below {
            transform:scale(0.9);
        }
    }
    .home-innovation__icon  {
        opacity:1;
        transform:scale(1);
        @include phone-large-below {
            transform:scale(0.9);
        }
    }
    .home-innovation__image {
        transition-delay:300;
        opacity:1;
    }
}

.slide-in .home-innovation__img {
    animation: .5s forwards slideInImageLeft;
    @include tablet-large-below {
        animation: .5s forwards slideInImageTop;
    }
}

.slide-out .home-innovation__img {
    animation: .5s forwards slideOutImageLeft;
    @include tablet-large-below {
        animation: .5s forwards fadeOut;
    }
}

.slick-slide:nth-child(even) .slide-in .home-innovation__img {
    animation: .5s forwards slideInImageRight;
    @include tablet-large-below {
        animation: .5s forwards slideInImageTop;
    }
}

.slick-slide:nth-child(even) .slide-out .home-innovation__img {
    animation: .5s forwards slideOutImageRight;
    @include tablet-large-below {
        animation: .5s forwards fadeOut;
    }
}
.image-single-block{
	display: inline-flex;
	flex-direction: column;
	img{
		max-width: 100%;
		margin-bottom: 1rem;
		border-radius: 5px;
	}
	p{
		padding-right: 2rem;
		@extend %body-copy-small;
		@include el-size(quarter, third){
			@extend %body-copy-xsmall;
		}
	}
	.image-single-block__caption{

	}

}
.partner-block{
    @extend %base-block;
    padding: 4rem 4.5rem 4rem 3.5rem;
    @include el-size(full, three-quarters, two-thirds, half, third, quarter){
        flex-direction: column;
    }
    &__tag{
        @extend %base-tag;
        width: fit-content;
    }
    &__primary{
        margin: 2rem 0;
        padding-right: 4rem;
        display: flex;
        @include el-size(third, quarter){
            flex-direction: column-reverse;
        }
        @include tablet-small-below{
            flex-direction: column-reverse;
        }
        div{
            padding-right: 2rem;
            width: 100%;
        }
        picture {
            width: 33%;
            img {
                max-width: 100%;
            }
        }
    }
    &__img{
        max-height: 7.2rem;
        float: right;
        @include el-size(third, quarter){
            max-width: 21.5rem;
            max-height: 4.5rem;
            margin-bottom: 2rem;
            float: left;
        }
        @include tablet-small-below{
            float: none;
            margin-bottom: 2rem;
        }
    }
    &__title{
        margin-bottom: 1rem;
    }
    &__role{
        @extend %heading-five;
        color: $navy-900;
    }
    &__secondary{
        display: flex;
        flex-wrap: wrap;
    }
    &__summary{
        width: 100%;
        color: $navy-500;
    }
    &__contribution{
        @extend %body-copy-small;
        color: $navy-500;
        display: flex;
        flex-wrap: wrap;
        align-items:flex-end; //text baseline alignment
        width: 100%;
        padding-right: 5rem;
        margin-bottom: 3rem;
        span{
            font-weight: 700;
            margin-right: 1rem;
        }
        & &-item {
            padding-left: 0;
            margin-left: 0;
            a {
                display: inline;
                @extend %body-copy-small;
                color: $navy-500;
                font-weight: 500;
                text-decoration: underline;
                margin-left: 0.5rem;
            }
            &:before {
                display:none;
            }
            &:after {
                content: ',  ';
            }
            &:last-child:after{
                content:''
            }
        }
    }
}
.teaser-block{
  @extend %base-block;
  @include tablet-small-below{
    flex-direction: column;
  }
  &__title{
    @include el-size(two-thirds, three-quarters, full){
      @extend %heading-three;
      @include phone-large-below{
        font-size: 2.9rem;
      }
    }
    @include el-size(half){
      @extend %heading-three;
      @include phone-large-below{
        font-size: 2.9rem;
      }
    }
    @include el-size(third, quarter, sixth){
      @extend %heading-five;
      @include phone-large-below{
        font-size: 2.9rem;
      }
    }
  }
  p{
    margin-bottom: 0;
    @include el-size(third, quarter) {
      @extend %body-copy-small;
    }
  }
  &--promoted{

  }

  &__primary{
    @extend %base-block__primary;
    padding: 0;
    picture {
      width: 100%;
    }
    img{
      @extend %base-block__img;
      object-fit: cover;
      height: 100%;
      @include el-size(third, quarter) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      @include el-size(two-thirds, three-quarters, full, half) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @include phone-large-below{
          border-top-right-radius: 5px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
  &__img{
    &.teaser-block__image--mobile-only{
      display: none;
      @include phone-large-below{
        display: block;
      }
    }
    &.teaser-block__image--desktop-only{
      @include phone-large-below{
        display: none;
      }
    }
  }
  &__secondary{
    @extend %base-block__secondary;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 4rem 3rem 2rem 2rem;
    @include el-size(third, quarter) {
      padding: 2rem;
    }
    @include el-size(two-thirds, three-quarters, full, half) {
      @include phone-large-below{
        padding: 2rem;
      }
    }
  }
  @include el-size(third, quarter){
    flex-direction: column;

  }
  .button{
    margin-bottom: 0;
  }

  // Large Version
  &--large{
    background-color: $navy;
    p{
      color: #fff;
    }

    .button{
      color: #fff;
      &:hover, &:focus{
       .link-text{
         color: #fff;
         &::after{
           border-color: #fff;
         }
       }
      }
    }
  }
  &--large &{
    &__title{
      color: #fff;
    }

  }
}

.stats-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: $navy-900;
  margin: 5rem 0;
  border-radius: 5px;
}

.stats-row-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 // padding: 0 2rem;

  &--text{
    padding: 6rem 2rem;
  }

  &__title{
    font-weight: 700;
    color: #fff;
    font-size: 4.5rem;
    margin-bottom: 1rem;
  }
  &__subtitle{
    color: $orange;
    font-family: $font-heading;
    font-size: 2rem;
  }
}

.home-quote {
    background-color: $navy-900;

    &__wrapper {
        display:flex;
        flex-direction: row;
        align-items: center;
        @include tablet-below {
            flex-direction: column-reverse;
        }
    }

    &__image {
        flex:1;
        flex-shrink:0;
        margin-top: 10rem;
        align-self: flex-end;
        picture {
            display:block;
            width:100%;
            height:100%;
        }
        img {
            width:100%;
            height: 100%;
            object-fit: contain;
            display:block;
        }
        @include desktop-small-below {
            flex: 2;
        }
        @include tablet-below {
            margin-top: 2rem;
            width:100%;
        }
    }

    &__content {
        flex:2;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 6rem;
        @include tablet-below {
            align-items: flex-start;
            padding: 0;
            padding-top:5rem;
        }
    }

    &__quote-icon {
        margin-bottom: 4.5rem;
        @include phone-large-below {
            width:40px;
        }
    }
   
    &__quote p {
        @extend %body-copy-large;
        color:#fff;
        @include phone-large-below {
            font-size:2.3rem;
            padding: 0 6px;
        }
    }

    &__quote {
        max-width: 96rem;
        margin-bottom:1.6rem;
        // @include tablet-below {
        //     text-align: left;
        // }
    }

    &__author {
        position:relative;
        margin-bottom:1rem;

        &::before {
            content:"";
            position:absolute;
            background-color: $orange;
            width: 6.5rem;
            height: 3px;
            top: 11px;
            left: -9rem;
            @include tablet-below {
                display:none;
            }
        }
    }

    &__name {
        font-size: 2.4rem;
        font-weight: 700;
        color: #fff;
        margin-bottom: 1rem;
        // @include tablet-below {
        //     text-align: center;
        // }
    }

    &__role {
        font-size: 2.4rem;
        color: #fff;
        // @include tablet-below {
        //     text-align: center;
        // }
    }

}


.wrapper{
  max-width: $wrapper-width;
  width: 100%;
  margin: 0 auto;
  padding: 0 3rem;
  position: relative;
  display: flex;
  flex-direction: column;

  &.flex-wrap{
    display: flex;
    flex-wrap: wrap;
  }

  .inner > &{
    flex-direction: row;
    flex-wrap: wrap;
  }
  @include desktop-xlarge-below{
    padding: 0 10rem;
  }
  @include desktop-small-below {
    padding: 0 3rem;
  }

}

.inner > .wrapper:first-child{
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}






// Column styles

$column-padding-list:(
  (small, 2rem, 0rem),
  (medium, 5rem, 3rem),
  (large, 7rem, 5rem)
);

@mixin columnPaddingSizes($direction, $sizes){
  @each $size in $sizes {
    &-#{nth($size, 1)}{
      .col-bg{
        padding-#{$direction}:#{nth($size, 2)};
        @include tablet-large-below {
          padding-#{$direction}:#{nth($size, 3)};
          &.col-bg--color-transparent {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }
    }
  }
}





.col{
  position:relative;

  // Minimum width of 2 columns @ full width
  //min-width: calc(265px - 2rem);

  @include phone-large-below{
    width:100%;
  }
  &--pad-top{
    @include columnPaddingSizes("top",$column-padding-list);
  }
  &--pad-right{
    @include columnPaddingSizes("right",$column-padding-list);
  }
  &--pad-bottom{
    @include columnPaddingSizes("bottom",$column-padding-list);
  }
  &--pad-left{
    @include columnPaddingSizes("left",$column-padding-list);
  }
  //
  //
  //@for $i from 1 through 60 {
  //    &:nth-child(#{$i}){
  //      transition-delay: #{$i * 140}ms;
  //      &.aos-animate{
  //
  //      }
  //    }
  //}
  //
  //opacity: 0;
  //transition-property: opacity, transform;
  //transform: translate3d(0, 50px, 0);
  //transition-duration: .5s;
  //
  //.aos-animate & {
  //  opacity: 1;
  //  transform: none;
  //}
  //



}

.col-bg{
  position:relative;
  width:100%;
  border-radius: 5px;
  z-index: 0;
  height: 100%;
  > div{
    position: relative;
    z-index: 1;
  }
  &.col-bg--color-white{
    background: #fff;
  }
  &.col-bg--color-ultralight-blue{
    background: $navy-100;
  }
  &.col-bg--color-light-blue{
    background: $navy-200;
  }
  &.col-bg--color-muted-blue{
    background: $navy-200;
  }
  &.col-bg--color-regular-blue{
    background: $navy;
    *:not([class*=rich-text--color-]):not(.link-text),
    span.rich-text--color-navy-dark,
    span.rich-text--color-navy {
      color: #fff;
    }
  }
  &.col-bg--color-dark-blue{
    background: $navy-900;
    *:not([class*=rich-text--color-]):not(.link-text),
    span.rich-text--color-navy-dark,
    span.rich-text--color-navy {
      color: #fff;
    }
  }
}

.col--large-padding {
  padding: 1.5rem 3rem 1.5rem 0;
  @include phone-large-below {
    padding: 1rem 1rem 1rem 0;
  }
}

.col-inner{
  
  &--align-right{
    align-items: flex-end;
    display: flex;
    flex-direction: column;

  }
  &--align-center{
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

// Row Styles


$row-padding-list:(
  (none, 0rem, 0rem),
  //(default,4rem),
  (small, 2rem, 1rem),
  (medium, 7rem, 3.5rem),
  (large, 10rem, 5rem)
);
@mixin rowPaddingSizes($direction, $sizes){
  @each $size in $sizes {
    &-#{nth($size, 1)}{
      padding-#{$direction}:#{nth($size, 2)};
      @include phone-large-below {
        padding-#{$direction}:#{nth($size, 3)};
      }
    }
  }
}

.row {
  //margin: 3rem 0;
  &.row--valign-top{
    align-items: flex-start;
  }
  &.row--valign-middle{
    align-items: center;
  }
  &.row--valign-bottom{
    align-items: bottom;
  }
  &--pad-top{
    @include rowPaddingSizes("top",$row-padding-list);
  }
  &--pad-bottom{
    @include rowPaddingSizes("bottom",$row-padding-list);
  }

  // align-items : stretch use instead of height : 100%, ONLY affects children
  &.row--valign-stretch.children-per-col--one {
    align-items:stretch;
    .col-bg {
        height:100%;
    }
    .col-inner {
        @include phone-large-above {
          height:100%;
          display:flex;
          flex-wrap:wrap;
          > * {
            margin-bottom:2rem;
          }
        }
    }
  }

  &.row--halign-left {
    justify-content: flex-start;
  }
  &.row--halign-center {
    justify-content: center;
  }
  &.row--halign-right {
    justify-content: flex-end;
  }
  
  &.row--mobile-reverse-order {
    &-lg {
      @include tablet-large-below {
        flex-direction: column-reverse;
      }
    }
    &-sm {
      @include phone-large-below {
        flex-direction: column-reverse;
      }
    }

  }

}




.main-content{
  @include column(9);
  padding-right: 5rem;
  @include desktop-medium-below{
    @include column(9);
  }
  @include desktop-small-below{
    @include column(12);
    padding-right: 0;
  }
  padding-top: 6rem;
  margin-bottom: 5rem;
  .wrapper {
    padding: 0;
  }

  // Remove extra space above the first pagebuilder row
  & > .row-container:first-of-type {
    .row.page-content {
      padding-top: 0;
    }
  }
}


.sidebar{
  @include column(3);
  @include desktop-medium-below{
    @include column(3);
  }
  @include desktop-small-below{
    @include column(12);
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .inner &{
    margin-top: -5rem;
    justify-content: flex-start;
    margin-bottom: 5rem;
  }
  .button{
    margin-right: 0;
  }
  .page-header--has-sidebar &{
    padding-bottom: 5rem;
    @include desktop-small-below {
      padding-bottom: 2rem;
    }
    @include tablet-below {
      padding-bottom: 0;
    }
  }
}

.overflow{
  width: 100%;
}

.site-row{
  padding: 6rem 0;
 // margin: 5rem 0;
//  margin: 5rem 0;
  &--bg-navy-200{
    background-color: $navy-200;
  }
  &--bg-navy-100{
    background-color: $navy-100;
  }
  &--services{
    margin-bottom: 0;
  }
  &--benefits{
    margin-top: 0;
  }
  &__title{
    margin-bottom: 4rem;
  }
}






/* PLACEHOLDER/DEMO START */


.body-scroll-buttons{
  margin: 2rem 0 4rem 0;
  background-color: #fff;
  border: 2px solid $navy-200;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__title{
    font-weight: 700;
    background-color: $navy-200;
    color: $navy-900;
    padding: 1rem 2rem;
    border-bottom-right-radius: 5px;
    float: left;
    @extend %body-copy-small;
    @include phone-large-below{
      width: 100%;
    }
  }
  ul{
    margin: 3rem;
    display: flex;
    flex-wrap: wrap;
    @include tablet-below{
      flex-direction: column;
    }
  }
}

.body-scroll-button{
  color: $navy-900;
  @extend %body-copy-small;
  font-weight: 700;
  border: 0;
  background: transparent;
  cursor: pointer;
  margin-right: 3rem;
  margin-bottom: 1.5rem;
  text-align: left;
  &:hover, &:focus{
    span:last-child{
      text-decoration: none;
    }
  }
  span:first-child{
    font-family: $font-decorative;
    color: $orange;
    display: inline-block;
    margin-right: .2rem;
    font-weight: 300;
  }
  span:last-child{
    text-decoration: underline;
  }
}

.body-row{
  padding: 10rem 0;
  &:first-child{
    padding-top: 5rem;
  }
  .wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    @include desktop-small-below{
      flex-wrap: wrap;
    }
  }

  &.body-row--accordion{
    .wrapper{
      align-items: flex-start;
      flex-direction: column;
    }
    .accordion{
      width: 100%;

    }
    .accordion-row__title{
      font-size: 2.5rem;
    }
    .accordion-row__body > div{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include  tablet-below{
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
      }
    }
    .accordion-row__media{
      max-width: 38rem;
      flex-shrink: 0;
      @include  tablet-above{
        margin-left: 5rem;
      }
    }
  }
  &:nth-child(even){
    background-color: $navy-200;
    p{
      color: $navy-900;
    }

  }

  &:not(.body-row--accordion):nth-child(even){
    .wrapper{
      flex-direction: row-reverse;
      @include desktop-small-below{
        flex-direction: row;
      }
    }
  }

  &:nth-child(even) &{
    &__secondary{
      padding-left: 0;
      padding-right: 8rem;
      @include desktop-small-below{
        padding-right: 0;
      }
    }
  }

  @include desktop-small-below{
    padding: 5rem 0;
  }


  &__primary{
   // @include column(7);
    flex-grow: 1;
    padding-right: 5rem;
    @include desktop-small-below{
      padding-right: 0;
      //@include column(12);
    }

    > ul {
      @extend %list-simple;
    }

  }

  &__secondary
  {
    @include column(4.5);
   // padding-right: 5rem;
    padding-left: 8rem;
    flex-shrink: 0;

    .image-carousel-block{
      margin: 0;
    }
    @include desktop-small-below{
      padding-left: 0;
      @include column(8);
     // padding: 0;
    }

    @include tablet-small-below{
      @include column(12);
    }




  }




}

.introduction{
  margin-bottom: 5rem;
  ul{
    @extend %list-simple;
  }
  &__media{
    //@include column(5);
    float: right;
    margin-left: 2rem;
    margin-bottom: 3rem;
    @include phone-large-below{
      margin-left: 0;
      float: none;
    }
  }
}

.divider{
  display: block;
  background-color: $navy-400;
  height: 2px;
  margin: 3rem 0 8rem 0;
}


/* PLACEHOLDER/DEMO END */



// Set default page height for short pages (like about page)
.container{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#main-content{
  flex-grow: 1;
}


.page-inner{
  margin-top: 20rem;
}







.filter-search {
    @include column(12);
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3rem;
}

.reset-all-filters {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button.reset-all-filters__button {
        @extend %body-copy;
        color: $navy-500;
        padding-right: 2rem;
        cursor: pointer;
        background: none;
        border: none;
        @include desktop-small-below {
            padding-right: 0;
        }
        
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
}

.filter-search__dropdowns {
    display: flex;
    justify-content: space-between;
    @include column(12);

    @include desktop-small-below {
        flex-wrap: wrap;
    }
}

.filter-search__dropdown {
    @include column(3);
    margin-right: 2rem;
    @include desktop-small-below {
        @include column(5.8);
        margin-bottom: 2rem;
        &:nth-of-type(even) {
            margin-right: 0;
        }
    }
    @include phone-large-below {
        @include column(12);
        margin-right: 0;
    }

    &-label {
        font-size: 2rem;
        display: block;
        margin-bottom: 2rem;
    }

    .dropdown-toggle {
        cursor: pointer;
        font-size: 2.4rem;
        color: $navy-500;
        background: none;
        border: none;
        box-shadow: $shadow-300;
        border-radius: 5px;
        @include column(12);
        text-align: left;
        padding: 2rem 2rem 2rem 3.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span:first-child {
            overflow:hidden;
            text-overflow: ellipsis;
            margin-right: 1rem;
        }

        &__icon {
            display: flex;
            transform: rotate(0);
            transition: 0.4s ease;
            i {
                color: $navy;
            }
        }

        &::after {
            display:none;
        }
    }
}

.dropdown-toggle.show {
    .dropdown-toggle__icon {
        transform: rotate(180deg);
    }
}

.dropdown-menu {
    z-index: 5;
    overflow-x:hidden;
    @include column(12);
    border: none;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -5px !important;
    box-shadow: $shadow-100;
    ul {
        transition: 0.5s ease;

        li span {
            font-size: 1.8rem;
            line-height: 1;
            white-space: normal;
        }
    }
}

.dropdown-item, .dropdown-item__accordion li {
    cursor: pointer;
    padding: 1.5rem 3.5rem;
    border-bottom: 1px solid $navy-200;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:first-of-type {
        margin-top: 1rem;
    }
    &:last-of-type {
        border-bottom: none;
        margin-bottom: 2rem;
    }
    span {
        text-decoration: underline;
        color: $navy-900;
    }
    &:hover, &:focus {
        background: $navy-200;
    }
    &:active {
        background: none;
    }


    &--active {
        background-color: $navy-600 !important;
        & > span {
            color: $white !important;
        }
        li {
            color: #212529;
        }
    }

    &__toggle-accordion, &__toggle-accordion--nested {
        cursor: pointer;
        display: flex;
        align-items: center;
        background: $white;
        border: 1px solid $navy-400;
        border-radius: 5px;
        padding: .3rem .7rem;
        margin-left: 1rem;
        font-size: 1.8rem;
        font-weight: 700;
        color: $navy-500;
        transition: all 0.2s ease;
        i {
            margin-left: .5rem;
        }
        &:hover, &:focus {
            background: $navy;
            border-color: $navy;
            color: $white;
        }
    }
}

.dropdown-item__accordion {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    @include column(12);

    li:hover, li:focus {
        color: #16181b;
        background-color: $navy-200;
    }

    .is-open & {
        display: inherit;
        flex-direction: column;
    }

    &--nested {
        display:none;
        position: absolute;
        top: 0;
        left: 100%;
        @include column(12);

        .is-open--nested & {
            display: inherit;
            flex-direction: column;
        }
    }

    .back-button, .back-button--nested {
        cursor: pointer;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        color: $navy;
        padding: 1rem 3.5rem;
        white-space: normal;
        text-align: left;
        i {
            padding-right: 1rem;
            margin-left: -.5rem;
        }
    }
}

.filter-search-bar {
    margin-bottom: 5rem;
    margin-top: -8rem;
    @include column(12);
    max-width: 75rem;
    display: flex;
    flex-direction: column;
    box-shadow: $shadow-300;
    label {
        font-size: 2rem;
        display: block;
        margin-bottom: 1rem;
        color: $white;
    }
    input {
        height: 10rem;
        @include column(12);
        font-size: 2.6rem;
        padding: 0 3.5rem;
        color: $navy-500;
        border: none;
        // box-shadow: $shadow-300;
        border-radius: 5px;
        background-color: #fff;
        &::-webkit-search-cancel-button {
            display:none;
        }
        @include phone-large-below {
            font-size: 2.2rem;
        }
        @include phone-small-below {
            font-size: 1.7rem;
        }
    }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        display: none;
    }

    &__container {
        display:flex;
        align-items: center;
        position: relative;
        @include column(12);
    }
    &__icon {
        display: inline-block;
        position: absolute;
        right: 0;
        margin-right: 2rem;
        i {
            font-size: 4rem;
        }
        @media(max-width: 375px) {
            display: none;
        }
    }
}

.filter-search-bar--media, 
.filter-search-bar--learning {
    max-width: 95rem;

    input {
        border-radius: 5px 0 0 5px;
    }

    .filter-search__search-bar-input {
        display: flex;
        align-items: center;
    }

    .filter-search__dropdown--region {
        height: 10rem;
        width: auto;
        margin-right: 0;
        @include desktop-small-below {
            margin-bottom: 0;
        }
    }
    .dropdown-toggle {
        height: 100%;
        background: $navy;
        color: $white;
        padding: 2rem;
        border-radius: 0 5px 5px 0;
        &.show {
            .dropdown-toggle__icon--earth {
                transform: rotate(0);
            }
        }
    }
    .dropdown-toggle__icon {
        i {
            color: $white;
        }
        &--earth {
            margin-right: 1rem;
            i {
                font-size: 4rem;
                @include phone-large-below {
                    font-size: 3rem;
                }
            }
            @media(max-width: 375px) {
                display: none;
            }
        }
    }
    .dropdown-toggle__selection--region {
        margin-right: 1.5rem;
        max-width: 15rem;
        overflow: hidden;
        text-overflow: ellipsis;
        @include phone-large-below {
            max-width: 8rem;
            font-size: 2rem;
        }
    }
    .dropdown-menu {
        margin-top: -1px !important;
    }
}


.all-projects-container {
    margin-top: 5rem;
    margin-bottom: 15rem;
}

.search-panel__loading-projects {
    position: relative;
}


.project-search__results, 
.media-search__results,
.learning-search__results {
    min-height: 30rem;
    position: relative;
}

.projects-loading, 
.media-loading, 
.learning-loading {
    color: #000;
    font-size: 2rem;
    display: none;
    .loading-animation {
        position: absolute;
        top: 5rem;
        left: 50%;
        transform: translateX(-50%);
    }
}

.learning-loading {
    .loading-animation {
        top: 15rem;
    }
}

.search-results__list--divider {
    position:relative;
    height: 10rem;
    margin-bottom: 2rem;
    &::after {
        content: '';
        @include column(12);
        height: 2px;
        background: $navy-400;
        position: absolute;
        top: 50%;
        left: 0;
    }
}

.learning-center-index-search {
    .wrapper {
        padding-top:0;
        padding-bottom:0;
    }
    .learning-center-search {
        &__outer-wrapper {
            position:relative;
        }
        &__wrapper {
            position:absolute;
            width:100%;
            top:0;
            left:0;
            right:0;
            z-index: 2;
        }
    }
}

.learning-center-search {
    .secondary-filters-container .reset-all-filters {
        flex-shrink:0;
    }
    .secondary-filters-container {
        .download-type-filter {
            width:100%;
            // width:50%;
            @include phone-large-below {
                width:100%;
                margin-bottom:0;
            }
        } 
        .year-timeline {
            width:50%;
            @include phone-large-below {
                width:100%;
            }
        }
    }
}

.filter-search--learning {
    display:flex;
    flex-direction: row;
    align-items: center;
    flex-wrap:nowrap;
    @include desktop-small-below {
        flex-direction: column;
    }

    .filter-search-bar {
        width:100%;
        flex: 1;
        max-width: initial;
        label {
            margin-bottom:2rem;
        }
        @include desktop-small-below {
            
        }
    }

    .filter-search__dropdowns {
        margin-left:3.2rem;
        margin-bottom: 5rem;
        margin-top: -8rem;
        justify-content: flex-start;
        width:50%;
        max-width: 90rem;
        flex-wrap: nowrap;

        .filter-search__dropdown {
            width:50%;
            margin-right:3.2rem;
            &:last-child {
                margin-right:0;
            }
        }

        .dropdown-toggle {
            background:#fff;
            height:10rem;
        }
    
        .filter-search__dropdown-label {
            color:#fff;
        }

        @include desktop-small-below {
            margin: 0;
            margin-top:-2rem;
            flex-direction: row;
            width: 100%;
            max-width:initial;
            .filter-search__dropdown-label {
                color:$navy;
            }
        }

        @include phone-large-below {
            flex-wrap:wrap;
            .filter-search__dropdown {
                width:100%;
                margin-right:0;
            }
        }

    }

    
    & + .reset-all-filters {
        margin-top: -3rem; 
        @include desktop-small-below { 
            margin-top: 0;
        }
    }

   
}
.image-gallery-row{
	padding:4rem 0;
	&__button{
		border:none;
		padding:0;
		position:relative;
		background:none;
		display: flex;
		align-items:center;
		align-content:center;
		justify-content:center;
		cursor:pointer;
		width: 100%;
		&:hover, &:active, &:focus{
			.hex-inner{
				transition:all 0.3s ease-in-out;
				transform:scale(1.8);
			}
			.hex-outer{
				transition:all 0.3s ease-in-out;
				transform:scale(.3);
			}
			i{
				transition:all 0.3s ease-in-out;
				transform:scale(1.7);
			}
		}
	}
	&__caption{
		padding-top:1rem;
		font-size:1.6rem;
		color:$navy-500;
	}
	&__search{
		position:absolute;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		i{
			color:$navy;
		}
		svg,i{
			transition:all 0.3s ease-in-out;
			position:absolute;
		}
	}
}

.gallery-block{
	width:100%;
	margin-bottom:4rem;
	picture, .image-gallery-row__img{
		width:100%;
		border-radius:.5rem;
	}
}








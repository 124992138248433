

.image-multiple-block{
	@include column(11.5);
	//max-width: 90rem;
	position: relative;
	z-index: 2;
	display: flex;
	justify-content: flex-start;
	padding-top: 5rem;
	img{
		max-width: 100%;
		margin-bottom: 2rem;
	}
	@include tablet-large-below {
		height: initial;
	}

	> div{
		position: relative;
		//@include column(4);
		padding-right: 2rem;
		img{
			max-width: 100%;
			position: relative;
			z-index: 3;
			border-radius: 5px;
			@include desktop-large-below{
			//	max-width: 90%;
			}
		}
		&::before{
			z-index: 0;
		}
		&:nth-child(1){
			align-self: flex-start;
			// &::before{
			// 	content: '';
			// 	display: block;
			// 	background-size: cover;
			// 	//background-image: url('/dist/images/page-header_triple-photo_deco_1.svg');
			// 	position: absolute;
			// 	left: 43%;
			// 	top: 59%;
			// 	transform: translate(-50%, -50%);
			// 	width: 135%;
			// 	height: 130%;
			// }
			//@include desktop-large-below{
			//	align-self: center;
			//	transform: translateY(-50%);
			//}
			//@include tablet-below{
			//	transform: translateY(-10%);
			//}
		}
		&:nth-child(2){
			align-self: flex-end;
			margin-top: 14rem;
			@include desktop-small-below{
				margin-top: 8rem;
			}
			&::before{
				content: '';
				display: block;
				background-size: cover;
				//background-image: url('/dist/images/page-header_triple-photo_deco_2.svg');
				position: absolute;
				left: 0;
				top: 0;
				transform: translate(-29%, -29%);
				width: 50%;
				height: 30%;
				@include desktop-small-below{
					display: none;
				}
			}
			//@include desktop-large-below{
			//	align-self: center;
			//	transform: translateY(20%);
			//	z-index: 2;
			//}
			//@include tablet-below{
			//	transform: translateY(10%);
			//
			//}

		}
		&:nth-child(3){
			align-self: center;
			&::before{
				content: '';
				display: block;
				background-size: cover;
				//background-image: url('/dist/images/page-header_triple-photo_deco_3.svg');
				position: absolute;
				left: 0%;
				top: 29%;
				transform: translate(-50%, -50%);
				width: 120%;
				height: 130%;

			}
			//@include desktop-large-below{
			//	align-self: center;
			//}
		}

	}

}


.mobile-nav {
    width:100%;
    padding: 2rem;
    background-color:#fff;
    display:none;
    @include desktop-small-below {
        display:flex;
        flex-direction:row;
        align-items: center;
        justify-content: flex-end;
    }
    &__logo {
        margin-right: auto;
        img {
            width: 24rem;
            max-width: 200px;
            height: auto;
        }
        &-white {
            display:none;
        }
        .header--dark:not(.mobile-menu-is-open):not(.is-compressed) & {
            &-white {
                display:block;
                @include desktop-small-below {
                    display:none;
                }
            }
            &-color {
                display:none;
                @include desktop-small-below {
                    display:block;
                }
            }
        } 
    }
    &__contact {
        height:40px;
        width:40px;
        color:$navy-600;
        background-color: $navy-200;
        border:0;
        padding: 1rem;
        border-radius:5px;
        margin-left:1.5rem;
        display:flex;
        align-items: center;
        justify-content: center;
        i {
            font-size: 22px;
        }
        &:hover {
            cursor: pointer;
        }
        @include phone-small-below {
            display:none;
        }
    }
    &__search {
        height:40px;
        width:40px;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        color:$navy-600;
        background-color: $navy-200;
        margin-left: 0.5rem;
        border:0;
        // transition: all .2s ease-in-out;
        i {
            font-size:22px;
        }
        &:hover {
            cursor: pointer;
            // background-color: rgba(12, 62, 141, 0.25); 
            // border:2px solid $navy;
        }
        @include desktop-small-below {
            .is-searching & {
                background-color:$navy;
                i {
                    display:none;
                }
             
            }
        }
    }
    &__search-close {
        position:relative;
        top:2px;
        display:none;
        @include desktop-small-below {
            .is-searching & {
                display:block;
            }
        }
    }
}

.btn-open-mobile-nav {
    width: 40px;
    height: 40px;
    flex-shrink:0;
    border: 0;
    margin-left:6px;
    border-radius: 4px;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    display: none;
    background-color: $navy;
    transition: all .2s ease-out;
    // .header--dark:not(.is-compressed)  &{
    //     color: #fff;
    // }
    // .is-showing-mobile-nav .header--dark &{
    //     color: $tertiary-darkblue;
    // }
    @include desktop-small-below{
        display: flex;
    }
    .mobile-nav__line {
        height:3px;
        width:22px;
        margin-bottom: 5px;
        border-radius:10px;
        background-color: #fff;
        transition: all .2s ease-out;
        transform-origin: center center;
        position:relative;
        &.mobile-nav__line-3 {
            margin-bottom:0;
        }
    }
    .mobile-menu-is-open & {
        background-color: $navy;
        .mobile-nav__line {
            background-color:#fff;
            margin-bottom:0;
        }
        .mobile-nav__line-1 {
            top:3px;
            transform: rotate(-45deg);
        }
        .mobile-nav__line-2 {
            transform: rotate(45deg);
        }
        .mobile-nav__line-3 {
            opacity:0;
        }
    }
    &:hover {
        cursor: pointer;
        // background-color: #fff;
        // .mobile-nav__line {
        //     background-color: $navy;
        // }
    }
}

.mobile-nav-aboutus--hide {
    @include desktop-small-below {
        .header .primary-nav .primary-nav__main-menu .primary-nav__list-item .primary-nav__menu & {
            opacity: 0;
            height: 0;
            overflow: hidden;
            padding: 0;
            margin: 0;
        } 
    }
}

.mobile-nav-aboutus--open {
    @include desktop-small-below {
        .header .primary-nav .primary-nav__main-menu .primary-nav__list-item .primary-nav__menu & {
            padding-left: 0;
            > div {
                padding-left: 0;
            }
            .primary-nav__index-link {
                display:none;
            }   
        }
       
    }
}

@include desktop-small-below {
    // .header {
    //     > .wrapper {
    //         flex-direction: row;
    //         justify-content: space-between;
    //     }
    // }
    // .primary-nav {
    //     order:initial;
    //     &__main-menu {
    //         height:0;
    //         width:0;
    //         overflow:hidden;
    //         opacity:0;
    //         z-index:-999;
    //         transition: opacity .2s ease-out;
    //     }
    // }
    body.body--fixed:not(.is-searching) {
        overflow:hidden;
        > .container {
            // position: fixed;
            z-index:1050; //beat out bootstraps modal backdrop at 1040
        }
    }
    body.body--fixed.body--interactive > .container {
        width:100vw;
    }

    .mobile-menu-is-open {
        &.header {
            background: $navy-900;
        }
        a {
            // font-size:2.2rem !important;
        }
        .primary-nav__list {
            position:relative;
        }
        .primary-nav__main-menu {
            // transition: opacity .2s ease-out;
            
        
            .primary-nav__link {
                color:#fff;
                padding-left: 0;
                font-size: 2.6rem;
                padding: 1.6rem 0;
                opacity:1;
                &[data-level="1"],
                &[data-level="2"],
                &[data-level="3"] { 
                    font-size: 2rem;
                    display: flex;
                    color:$navy-400;
                    padding: 1rem 0;
                    font-weight:400;
                    background:none;
                }
                &[data-level="1"] {
                    font-weight:bold;
                    margin-bottom:0;
                    .primary-nav__link-icon {
                        // margin-right:0;
                    }
                }
                &[data-level="2"]{
                    background:none;
                }
            }
        }

        .primary-nav__description {
            color:$navy-400;
            padding-left:0;
            p {
                font-size:2.4rem;
            }

        }
        .primary-nav__index-link[data-level="1"] {
            padding-left:0;
            margin-bottom:1rem;
            font-weight:bold;
            font-size:2.4rem;
        }
        .primary-nav__menu {
            width:100%;
            padding:0;
        }
        .primary-nav__menu > div {
            width:100%;
            position: relative;
            padding: 1rem 0;
            background: none;
        }
        .primary-nav__menu[data-level="3"] > div {
            padding:0;
        }
        .primary-nav__menu > div > h2 {
            display:none;
        }
        .primary-nav__list[data-level="3"] {
            column-count: initial;
        }
        .primary-nav__list-item[data-level="0"] {
            margin-left:0;
            border-bottom: 1px solid $navy-500;
        }
        .primary-nav__menu[data-level="1"] {
            top:0;
            position:relative;
        }
        .primary-nav__menu[data-level="2"] {
            position:relative;
            padding-left:2rem;
        }
        .primary-nav__list[data-level="2"] {
            padding-left:2rem;
        }
        .primary-nav__menu[data-level="2"] > div {
            background:none;
            height:initial;
            padding: 0 0 0 1rem;
        }
        .primary-nav__menu[data-level="3"] {
            background:none;
            // padding-left:3rem;
        }
        .primary-nav__list[data-level="3"] {
            padding-left:2rem;
        }
        .primary-nav__menu {
            height: initial !important; 
            position: relative;
        }
        .primary-nav__index-link {
            &[data-level="2"],
            &[data-level="3"]{
                color: $navy-400;
                text-decoration: none;
                font-family: "GTAmerica Standard", sans-serif;
                margin:0;
                font-size:2rem; 
                padding:1rem 0;
                @include desktop-small-below {
                    font-size:2.4rem; 
                }
            }
        }
        .primary-nav__next-level-arrow {
            display:none;
        }
        .active-meganav .primary-nav__link[data-level="0"] {
            opacity:1;
        }
        &.active-meganav .active-megamenu .primary-nav__link[data-level="0"] {
            color:#fff;
        }
        .active-megamenu .primary-nav__link[data-level="0"]::after {
            display:none;
        }
        &.header--dark:not(.active-meganav):not(.is-compressed) .active > .primary-nav__link[data-level="0"]::after {
            color:$orange;
        }
        .active > .primary-nav__link[data-level="0"]::after {
            height: 2rem;
            width: 2px;
            left:-1rem;
            bottom: calc(50% - 1rem);
            background-color:$orange;
        }
        .primary-nav__link-arrow,
        .primary-nav__link-icon {
            display:none;
        }
    }
}



.related-media-news{
	margin:5rem auto;
	display:block;
	.col{
		margin-bottom:2rem;
		display: flex;
		@include desktop-medium-below{
			@include column(6);
		}
		@include phone-large-below{
			@include column(12);
		}
	}
	&__title{
		display:flex;
		align-content:center;
		align-items:center;
		i{
			padding-right:1rem;
			color:$orange;
		}
	}
}

.media-cta-block{
	padding:1rem 3rem;
	&--media-center{
		@include phone-large-below{
			padding-top: 0;
			padding-bottom: 0;
			.media-cta-block__primary{
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
	@include phone-large-below{
		padding-left: .75rem;

		.media-cta-block__primary{
			padding-left: .75rem;

		}
	}

	&__primary{
		@extend %base-block__primary;
		@include phone-large-below{
			//align-items: center;
			//text-align: center;
			//padding-top: 0;
		}
	}
	.button{
		@include phone-large-below{
			margin-right: 0;
		}
	}
	&__type{
		font-size:2rem;
		color:$navy-500;
		margin:1.5rem 0;
	}
	&__title{
		@extend %heading-four;
		font-weight: 700;
	}
	&__summary{
		font-size:1.8rem;
		font-weight: 200;
		color:$navy-500;
		margin-bottom:2rem;
		display: block;
	}
	&__icon{
		font-size:4rem;
		color:$navy;
		margin-bottom:1.5rem;
	}
	&--training{
		border-radius:5px;
		background:$navy-100;
	}

	.button{
		margin-top: 1rem;
	}
	&--training &{
		&__summary{
			color:$navy-900;
		}
	}

}
.challenge-row{
  margin: 0;
  width: 100%;

  &__header{
    width: 100%;
    background: #fff;
    border-radius: 5px;
    padding: 3rem 3em 3rem 3rem;
    border: 0;
    position: relative;
    margin-bottom: 2rem;
    transition: color .2s ease-in-out;
    text-align: left;
    cursor: pointer;
    box-shadow: $shadow-100;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      .challenge-row__title {
        color: $navy-900;
        text-decoration: underline;
      }
    }

    &::after{
      font-family: 'icoMoon';
      content: '\e90c';
      font-size: 3.2rem;;
      color: $orange;
    }
    &[aria-expanded="true"]{
      color: $orange;
      .challenge-row__title {
        color: $navy-900;
        text-decoration: underline;
      }
    }
    &[aria-expanded="true"]::after{
      content: '\e910';
    }

    @include el-size(third){
      @extend %heading-five;
      margin: 0;
    }
    @include el-size(quarter){
      font-size: 2rem;
    }


    &:last-child{
      border-bottom: 2px solid #DFE5F1;
    }
  }

  &__solution-title{
    @extend %body-copy-large;
    display: block;
    margin-bottom: 2rem;
    font-weight: 500;
  }

  &__expertise-title{
    @extend %body-copy;
    font-weight: 700;
    display: inline-block;
  }

  &__solutions{
    @extend %body-copy;
    color: $navy-500;
    ul{
      display: inline-block;
    }
    li{
      display: inline-block;
    }
    a{

    }
  }

  &__title{
    @extend %heading-four;
    margin: 0;
  }


  &__body{
    > div{
      padding: 1rem 2rem 2rem 2rem;
    }
    @include el-size(third, quarter){
      .rich-text p{
        @extend %body-copy-small;
      }
    }
  }
}



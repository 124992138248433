@import "_includes";


html {
  font-size: 62.5%;
  @include desktop-large-below {
    font-size: 55%;
  }
  @include desktop-medium-below {
    font-size: 50%;
  }
  @include desktop-small-below {
    font-size: 48%;
  }
  @include tablet-below {
    font-size: 50%;
  }
  @include phone-large-below {
    font-size: 45%;
  }
}


*{
  box-sizing: border-box;
}

body {
  font-family: $font-body;
  color: $navy-500;
  background-color: #fff;
}

.section-row{
  padding: 6rem 0;
  &__title{
    margin-bottom: 3rem;
  }
  &--bg-navy-100{
    background-color: $navy-100;
  }
}


.dev-bubble{
  background-color: red;
  height: 5rem;
  width: 5rem;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border-radius: 100%;
  color: #fff;
  z-index: 999;
  &--first{
    transform: none;
    left: .5rem;
    top: .5rem;
  }
  &--a{
    background-color: #D884FF;
  }
  &--b{
    background-color: #4FB5FF;
  }
  &--c{
    background-color: #00B227;
  }
  &--d{
    background-color: #EA7E00;
  }
  &--e{
    background-color: #7B4CFF;
  }
}


.dev-container{
  position: relative;
  min-height: 3rem;
  border: 2px dashed rgba(200,200,200,.3);
  z-index: 1;
  display: block;
  margin-top: 2rem;
  width: 100%;
  .inner &{
    border-color: rgba(200,200,200,.8);
  }
}


%list-item-orange-bullet{
  padding-right: 3rem;
  padding-left: 2rem;
  position: relative;
  margin-bottom: 1rem;

  &::before{
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    left: 0;
    top: 1.3rem;
    background-color: $orange;
    border-radius: 100%;
  }
}



.page-teaser-rows{
  margin: 5rem 0;
}


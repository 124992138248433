.breadcrumbs{
  margin-bottom: 1rem;

  &--desktop {
    @include tablet-large-below {
      display:none;
    }
  }
  &--mobile {
    display:none;
    @include tablet-large-below {
      display:inline-block;
    }

  }

}

.breadcrumb-items{
  display: flex;
  flex-wrap:wrap;
}

.breadcrumb-item-icon {
  i {
    font-size:2rem;
    margin-right:.5rem;
    position:relative;
    top:3px;
    // color:#fff;
    color:#5C7194;
    .header--dark + main & {
      color:#fff;
    }
    .breadcrumbs--mobile & {
      top:0;
    }
  }
}

.breadcrumb-item {
  position:relative;
  font-size: 1.8rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  z-index: 2;
  margin-bottom:1rem;
  > a, > span {
    position: relative;
    z-index: 6;
    min-width:fit-content;
    color:#5C7194;
  }
  &::after{
    content: '';
    display: block;
    height: 1px;
    background-color: $navy-500;
    width: 2.5rem;
    margin: 0 1rem;
  }
  &:last-child {
    &::after {
      display:none;
    }
  }
  .breadcrumbs--mobile & {
    font-size:2rem;
  }
  &.active a, &.active span{
    color:$navy;
  }
  &.breadcrumb-item--show {
    color:$navy;
    z-index: 3;
    a, span{
      color:$navy;
    }
    &::before {
      content:"";
      position:absolute;
      top:-10px;
      left:-10px;
      width:calc(100% - 2.5rem); //2.5rem is the dash between crumbs
      height:calc(100% + 10px);
      background-color:#FFF;
      z-index: 6;
      border-radius:5px;
    }
    &.active {
      &::before {
        width:calc(100% + 1rem); //no dash on last crumb
      }
    }
  }
}

.breadcrumb-dropdown{
  position:absolute;
  z-index:1;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  padding: 40px 10px 10px 10px;
  transform:translate(-10px, -5px) !important; //overwrite some inline JS additions
  padding-top:40px;
  width:calc(100% - 2.5rem);
  // min-width:18rem; 
  border-radius:.5rem;
  .breadcrumb-item.active & {
    width: calc(100% + 3rem);
    &::before {
      width:calc(100% + 15px); 
    }  
  }
}

.breadcrumb-dropdown-item{

}

.breadcrumb-item__dropdown-btn {
  background: none;
  box-shadow: none;
  border: 0;
  position: relative;
  z-index: 6;
  &:hover {
    cursor: pointer;
  }
}

.breadcrumb-dropdown {
  
}

.breadcrumb-dropdown-item {
  line-height:1.1;
  margin-bottom: 0.7rem;
  a {
    font-size: 1.6rem;
    font-weight: 400;
  }
}

.breadcrumb__icon {
  opacity:0.7;
  .breadcrumb-item.active & {
    opacity:1;
    .breadcrumb-item__dropdown-btn:hover & {
      opacity:1;
    }
  }
  &-inner {
    path {
      fill: $navy-900;
    }
   .active & {
      path {
        fill: $navy-900;
      }
    } 
    .breadcrumb-item--show & {
      path {
        fill:$navy-900;
      }
      .breadcrumb-item.active &  {
        path {
          fill:$navy-900;
        }
      }
    }
  }
  &-outer {
    stroke: $navy-900;
    .breadcrumb-item.active & {
      stroke: $orange;
    }
    .breadcrumb-item--show & {
      stroke: $navy-900;
    }
  }
  
}

.header--dark + * {

  .breadcrumb-item {
    color: $navy-400;
    a{
      color: $navy-400;
    }

    &.active a, &.active span {
      color:#fff;
    }
    &.breadcrumb-item--show a, &.breadcrumb-item--show span{
      color: $navy-900;
    }
    &::after {
      background-color: #fff;
    }
  }

  //Solutions and Capabilities get a different color / opacity
  .page-header-cap-solution__primary {
    .breadcrumb-item {
      color: rgba(#CAD2DD, .7);
      a, span{
        color: rgba(#CAD2DD, .7);
      }
      &.active a, &.active span {
        color:#fff;
      }
      &.breadcrumb-item--show a, &.breadcrumb-item--show span {
        color: $navy-900;
      }
    }
  }

  .breadcrumb__icon {
    &-inner {
      path {
        fill: #CAD2DD;
      }
     .active & {
        path {
          fill: #fff;
        }
      } 
    }
    &-outer {
      stroke: #CAD2DD;
      .breadcrumb-item.active & {
        stroke: $orange;
      }
    }
  }

  .breadcrumb-item--show {
    .breadcrumb__icon {
      &-inner {
        path {
          fill:$navy;
        }
      }
      .active & {
        path {
          fill:$navy;
        }
      }
      &-outer {
        stroke: $navy;
      }
    } 
  }
}

.image-carousel-block {
	margin-bottom: 4rem;
	@include el-size(half) {
		margin-left: 4rem;
		margin-right: 4rem;
		@include tablet-large-below {
			margin-left: 0;
			margin-right: 0;
		}
	}
	@include el-size(third, quarter) {
		margin-left: 2.5rem;
		margin-right: 2.5rem;
		@include phone-large-below {
			margin-left: 0;
		margin-right: 0;
		}
	}

	.slick-track{
		display: flex;
	}


	.slick-arrow{
		position: absolute;
		top: 50%;
		z-index: 2;
		width: 5rem;
		height: 5rem;
		box-shadow: $shadow-300;
		background-color: $navy-600;
		border: 0;
		border-radius: 5px;
		cursor: pointer;
		&::after{
			font-family: 'icoMoon';
			font-size: 2.8rem;
			color: #fff;
		}
		&:hover{
			background-color: darken($navy-500, 10%);
		}
		&.slick-prev{
			left: 0;
			transform: translateY(-50%) translateX(-50%);
			&::after{
				content: '\e912';
			}
		}
		&.slick-next{
			right: 0;
			transform: translateY(-50%) translateX(50%);
			&::after{
				content: '\e913';
			}
		}
	}


	.slick-dots{
		//display: flex;
		//justify-content: center;
		position: absolute;
		left: 50%;
		bottom: -3rem;
		width: 100%;
		text-align: center;
		transform: translateX(-50%);
		> li{
			display: inline-block;
			width: auto;
			padding: 0 .5rem;
			margin: 0;
			&::before{
				display: none;
			}
			&.slick-active > button{
				background-color: $navy-500;
			}
			@include el-size (third, quarter){
				@include tablet-small-below {
					padding: 0 .2rem;
				}
			}
			@include el-size (third, quarter){
				@include phone-large-below {
					padding: 0 .5rem;
				}
			}
		}
		> li > button{
			width: 1rem;
			height: 1rem;
			padding: 0;
			background-color: $navy-400;
			border-radius: 100%;
			border: 0;
			cursor: pointer;
		}
	}

	img{
		max-width: 100%;
		border-radius: 5px;
		width: 100%;
	}
}
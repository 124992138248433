.page-header-simple {
    position: relative;
    height: 700px;
    overflow: hidden;

    .page-header__background-image {
        position: absolute;
        z-index: -1;
        min-height: 100%;
        min-width: 100%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    &--dark {
        background-color:rgba(0, 0, 0, 0.55);
        
        .page-header__title, .page-header__summary {
            color: $white;
        }
    }
    
    .wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &--media,
    &--learning {
        .page-header__links a {
            margin-bottom: 2rem;
        }
        .page-header__summary {
            max-width: 82rem;
        }
    }

    &--media-type,
    &--learning-type {
        .wrapper {
            align-items: flex-start;
            @include tablet-large-below {
                align-items: center;
            }
        }
        p {
            text-align: left;
            @include column(5);
            @include desktop-medium-below {
                @include column(9);
            }
            @include tablet-large-below {
                @include column(12);
                text-align: center;
            }
        }
    }
}
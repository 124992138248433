.cap-sol-cards-wrapper{
  text-align: center;
  padding: 18rem 0 0 0;
  margin-bottom: 15rem;
  background-color: $navy-200;
  .wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title{
    @extend %heading-one;
  }
  &__title-container{
    p{
      @extend %body-copy-large;
      color: $navy;
    }
  }
}

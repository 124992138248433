.page-header-innovations {

    @include phone-large-above{
        min-height: 70vh;
    }

    &--index {
        @include phone-large-above{
            min-height: 90vh;
        }
    }

    background-color: #000;
    display: flex;
    overflow: hidden;
    position: relative;

    &--focus-page {
        overflow:visible;
        margin-bottom: 8rem;
    }

    .wrapper{
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__background-image{
        position: absolute;
        object-fit: cover;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .3;
    }

    &__primary {
        @include column(9);
        .page-header-innovations--focus-page & {
            @include column(7);
        }
        display: flex;
        flex-direction: column;
        position: relative;

        @include desktop-small-below{
            @include column(12);
            .page-header-innovations--focus-page & {
                @include column(12);
            }
        }
        @include phone-large-below{
            margin-bottom: 10rem;
        }
    }

    &__secondary {
        display: flex;
        flex-direction: column;
        position: relative;
        @include column(3);
        .page-header-innovations--focus-page & {
            justify-content: flex-end;
            @include column(5);
        }
        @include desktop-small-below{
            @include column(12);
            .page-header-innovations--focus-page & {
                @include column(12);
            }
        }
    }

    &__title-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 20rem 0 10rem 0;
        flex-grow: 1;
        @include tablet-large-below {
            margin: 20rem 0 5rem 0;
        }
    }

    color: #fff;
    .body-large {
        color: #fff;
    }

    &__title {
        @extend %heading-one;
        color: #fff;
    }

    &__summary {
    color: #fff;
    }
}

.innovation-jump-links {
    justify-self: flex-end;
    margin-bottom: 3rem;

    &__title {
        font-size:2.1rem;
        font-weight:700;
        color:#fff;
        margin-bottom:2.8rem;
    }

    &__links-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .button {
      margin-right: 2rem;
    }
}

.innovations-header-planbox {
    display:flex;
    flex-direction:column;
    background-color:#fff;
    box-shadow: $shadow-500;
    padding:3.5rem 4rem 1rem 4rem;
    border-radius:5px;
    margin-bottom: -8rem;

    &__title {
        @extend %heading-four;
        margin-bottom:2rem;
    }

    &__description {
        @extend %body-copy;
        color:$navy;
        margin-bottom:2rem;
    }

    &__buttons {
        display:flex;
        align-items:center;
        flex-wrap:wrap;
    }
}
.footer{
  background-color: $navy-900;
  color: #fff;
  padding: 5rem 0;
  .wrapper{
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @include tablet-below{
     // justify-content: center;
    }
  }
  &__logo-container{
    @include column(3.5);
    @include desktop-small-below{
      @include column(12);
      margin-bottom: 3rem;
    }
    @include phone-large-below{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .button{
      margin: 3rem 0;
    }
  }
  &__logo{
    display: flex;
    align-items: flex-start;
    @include phone-large-below{
     justify-content: center;
    }

    img{
      max-width: 30rem;
      @include desktop-small-below{
        max-width: 25rem;
      }
    }
  }


  .footer__lower {
    margin-top: 6rem;
    display:flex;
    justify-content: center;

    @include desktop-large-below{
      margin-top: 3rem;
    }

    @include desktop-small-below {
      padding-top:3rem;
      flex-direction: column;

    }
    @include phone-large-below{
      align-items: center;
      margin-top: 0;
    }
  }

  .footer__socials{
    display: flex;
    align-items: center;
    @include tablet-large-below {
     // margin-bottom: 4rem;
    }
    @include phone-large-below{
     // margin-bottom: 0;
    }
  }

  &__social-icon {
    border:2px solid $orange;
    border-radius:50%;
    display:flex;
    justify-content: center;
    align-items: center;
    height:4.3rem;
    width:4.3rem;
    margin-right: 1rem;
    text-decoration:none;
    transition: opacity .2s ease-out;

    @include tablet-large-below {
      height:5rem;
      width:5rem;
      &:not(:last-child){
        margin-right:2rem;
      }
    }
    &:active{
      text-decoration: none;
    }
    &:hover {
      opacity:.7;
      text-decoration: none;
    }
    &:focus {

    }
    i {
      color:#fff;
      font-size:1.8rem;
      @include tablet-large-below {
        font-size:2.2rem;
      }
    }
  }

  &__copyright {
    //margin-left:auto;
    color:#fff;
    font-size:1.4rem;
    position: relative;
    @include tablet-large-below{
      margin: 3rem 0;
      font-size: 1.9rem;
    }
  }

  &__tertiary{
    display: flex;
    margin-left: auto;
    align-items: center;
    @include desktop-small-below{
      flex-direction: column;
      margin-left: 0;
      align-items: flex-start;
    }
    @include phone-large-below{

      align-items: center;
    }
  }


}


.footer-nav{
  @include column(8.5);

  @include desktop-small-below{
    @include column(12);
  }

  > ul{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include phone-large-below{
      justify-content: center;
    }
  }
  //&__col{
  //  > a{
  //    font-weight: 700;
  //  }
  //}

  &__item > a{
    margin-bottom: 1rem;
    display: block;
    color: #fff;
  }
  &__highlight{
    color: #fff;
  }


  .footer-nav__singles{
    @include phone-large-below{
      width: 100%;
      > ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    > ul > li{
      margin-bottom: 1rem;
      @include phone-large-below{
          padding: 0 1.5rem;
      }
    }

  }

  > ul > li{
    &:not(:last-child){
      padding-right: 3rem;
    }
  //  @include column(3);
    @include tablet-below{
      @include column(6);
      margin-bottom: 3rem;
    }
    @include phone-large-below{
      display: inline-block;
      width: auto;
      margin-bottom: 1rem;
      padding: 0 1.5rem;
    }
  }

  &__item[data-level="1"]{
    > a{
      font-weight: 700;
      font-size: 2rem;
    }
  }


  &__item[data-level="2"]:not(.footer-nav__highlight){
    a{
      font-weight: 300;
      font-size: 1.8rem;
      opacity:.7;
      transition: opacity .2s ease-out;
      &:hover, &:focus {
        opacity:1;
      }
    }
    @include phone-large-below{
      display: none;
    }
  }


}

.footer-tertiary-nav{
  margin-right: 1.7rem;
  @include desktop-small-below{
    margin: 0 0 2rem 0;
  }
  ul{
    display: flex;
    align-items: center;
    @include desktop-small-below{
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    @include phone-large-below{
      justify-content: center;
    }
  }
  ul li{
    display: flex;
    align-items: center;
    @include desktop-small-below{
      align-items: stretch;
    }
    line-height: 1;
    &:first-child a{
      @include tablet{
        padding-left: 0;
      }

    }
    a{
      font-weight: 300;
      font-size: 1.4rem;
      color: rgba(#fff, .7);
      transition: color .2s ease-out;
      position: relative;
      padding: 0 2rem;
      display: flex;
      align-items: center;
      @include desktop-small-below{
        padding: 0 4rem 0 0;
      }
      @include phone-large-below {
        padding: 0 2rem 0 2rem;
      }
      &::after{
        display: block;
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 2px;
        background-color: #fff;
        opacity: .4;
        @include desktop-small-below {
          display: none;
        }
      }
      &:hover, &:focus {
        color: #fff;
      }
    }
    @include desktop-small-below {
      a{
        font-size: 1.7rem;
        margin-bottom: 2rem;

       // text-align: center;
      }
      &:last-child a{
        padding-right: 0;

      }
    }

  }
}

.scroll-to-top-btn {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  color: #fff;
  border-color: $navy-400;
  background: $navy-400;
  box-shadow: $shadow-300;
  z-index: 100;
  opacity: 0;
  transition: all 0.25s ease-in;
  &--active {
    opacity: 0.8;
    display: inline-flex;
  }
  &.button {
    margin-right: 0;
    margin-bottom: 0;
  }
  &.button--empty {
    padding: 1rem;
  }
  &:hover, &:focus {
    color: #fff;
    background-color: $navy-400;
  }
  &:focus {
    outline: 2px solid $orange;
  }
  @include phone-large-below{
    right: 1rem;
    bottom: 1rem;
  }
}

.video-block{
	//margin: 3rem 0;
	margin-bottom: 2rem;
	min-height: calc(100% -  2rem);
	width: 100%;

	&__title{
		// @extend %heading-five;
		// @include el-size(full, three-quarters){
		// 	@extend %heading-three;
		// }
		@include el-size(two-thirds, three-quarters, full){
            @extend %heading-three;
			@include phone-large-below{
                font-size: 2.9rem;
            }
        }
        @include el-size(half, third, quarter, sixth){
            @extend %heading-five;
			@include phone-large-below{
                font-size: 2.9rem;
            }
        }
	}

	&__image {
		position:absolute;
		height:100%;
		width:100%;
		pointer-events:none;
		object-fit: cover;
		border-radius: 5px;
		box-shadow: $shadow-300;
	}

	&__button{
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;
		width: 100%;
		border: 0;
		cursor: pointer;
		display: block;
		background: none;
	}
	&:hover &{
		&__play{
			transform: translate(-50%, -50%) scale(1.1);
		}
	}
	&__play{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		height: 8rem;
		width: 8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: transform .15s ease-in-out;
		&::after{
			display: block;
			font-family: 'icoMoon';
			content: '\e907';
			font-size: 4rem;
			color: #fff;
			margin-top: -.5rem;
		}
		&-hexagon {
			position: absolute;
			z-index: -1;
			width: 12rem;
			height: 12rem;
			svg {
				width: 100%;
				height: 100%;
				filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.15));
			}
		}
	}

	&--expanded{
		padding: 0;
		box-shadow: $shadow-300;
		background-color: #fff;
		border-radius: 5px;
		.video-block__summary {
			@extend %body-copy-small;
			color:$navy-500;
		}
		@include el-size(full, three-quarters, two-thirds){
			display: flex;
			@include tablet-small-below {
				flex-direction: column;
			}
		}
	}
	&--expanded &{
		@include el-size(full, three-quarters, two-thirds){
			&__primary{
				@include column(12);
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&__image{
			box-shadow: none;
			height: 100%;
			// margin-bottom: 2rem;
			@include el-size(full, three-quarters, two-thirds){
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				@include tablet-small-below {
					border-top-right-radius: 5px;
				}
			}
			@include el-size(half, third, quarter){
				border-bottom-right-radius: 0;
			}
		}
	}
	//video url
	a {
		margin-bottom: 0;
	}

	&__primary {
		position: relative;
	}
	
	&__secondary {
		@extend %base-block__primary;
		justify-content: flex-start;
		// padding-top: 0;
		button{
			margin-bottom: 0;
		}
		@include el-size(full, three-quarters, two-thirds, half, third, quarter){
			@include column(12);
		}
		@include el-size(half, third, quarter){
			// padding-left: 0;
			// padding-bottom: 0;
			margin-top: -2rem;
		}
		@include el-size(full, three-quarters, two-thirds){
			justify-content: center;
			padding-left: 3rem;
			@include tablet-small-below {
				// padding-left: 0;
				// padding-bottom: 0;
				margin-top: -2rem;
			}
		}
	}

	&__tag {
		@extend %base-tag;
		margin-bottom: 2rem;
		z-index: 4;
		@include el-size(half, third, quarter){
			margin-bottom: 0;
			border-top-left-radius:0rem;
			border-bottom-left-radius:0rem;
			transform: translate(-27.5%, -50%);
			padding-left: 2rem;
		}
		@include el-size(full, three-quarters, two-thirds){
			@include tablet-small-below {
				margin-bottom: 0;
				border-top-left-radius:0rem;
				border-bottom-left-radius:0rem;
				transform: translate(-41%, -50%);
				padding-left: 2rem;
			}
		}
	}
}
.home-careers {

    .row--pad-bottom-large {
        @include tablet-large-below {
            padding-bottom:5rem;
        }
    }

    &__title {
        @extend %heading-two;
        @include tablet-large-below {
            // text-align: center;
            h2 {
                line-height: 1.3;
            }
        }
    }

    &__copy {
        @include tablet-large-below {
            // text-align: center;
        }
        p {
            @include phone-large-below {
                font-size:2.3rem;
            }
        }
    }


    &__button-row {
        display:flex;
        flex-direction: row;
        align-items: center;

        @include desktop-medium-below {
            flex-direction: column;
            align-items: flex-start;
        }

        @include tablet-large-below {
            // align-items: flex-start;
        }

        .button {
            margin-right: 4rem;
            @include desktop-medium-below {
                margin-right: 0;
            }
        }
        .button--tertiary  {
            @include desktop-medium-below {
                // padding-left:2rem;
            }
        }
    }
}
.contact-row{
  padding: 8rem 0;
  margin: 0;
  overflow-x: hidden;
  .wrapper{
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__title{
    font-weight: 700;
    @extend %heading-two;
  }
  &__subtitle{
    @extend %heading-three;
  }
  &__content, &__secondary{
    p{
      @extend %body-copy-large;
      // margin-bottom: 0;
      @include phone-large-below {
          font-size:2.3rem;
      }
    }
  }

  &__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 5rem;
    @include tablet-below{
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-right: 0;
    }
  }
  &__secondary{
    @include column(5);
    padding: 0 7rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start;
    @include desktop-medium-below{
      display: flex;
      flex-direction: row;
      margin-bottom: 5rem;
      align-items: center;
    }
    @include tablet-below{
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0 6rem;
    }
  }


  &__primary{
    position: relative;
    padding: 9rem 0;
    display: flex;
    @include tablet-below{
      flex-direction: column;
      align-items: center;
      padding: 8rem;
    }
    > div{
      position: relative;
      z-index: 1;
    }
    //box-shadow: $shadow-500;
    &::after{
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 500vh;
      background-color: #fff;
      box-shadow: $shadow-500-blue;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      z-index: 0;
      @include desktop-medium-below{
        width: 100%;
        border-radius: 5px;
      }
    }
  }

  &--default &{
    &__primary{
      @include tablet-below{
        text-align: center;
      }
      @include phone-large-below{
        padding: 3rem;
      }
      // @include phone-small-below{
      //   padding: 2rem;
      // }
    }
    &__deco{
      @include tablet-below{
        &::after{
          display: none;
        }
          margin: 0 0 6rem 0;
      }
    }
  }

  &--expanded &{
    &__primary{
      display: block;
      @include column(7);
      padding: 5rem;
      @include phone-large-below {
        padding:2rem;
      }
    }

    &__secondary{
      @include desktop-medium-below {
        padding: 0 4rem 0 0;
      }
      @include tablet-below{
        padding: 0;
      }

    }

    &__primary, &__secondary{
      @include desktop-medium-below{
        @include column(12);
      }
    }
    &__deco{
      margin: 0 0 2rem 0;
      @include desktop-medium-below{
        margin: 0 5rem 3rem 0;
      }
      @include tablet-below {
        margin: 0 0 3rem 0;
      }
    }
  }

  &__deco{
    position: relative;
    margin: 8rem 16rem;
    @include desktop-large-below{
      margin: 6rem 10rem;
    }
    @include desktop-medium-below{
      margin: 6rem 8rem;
    }
    @include desktop-medium-below{
      margin-right: 5rem;
    }
    @include tablet-below{
      margin: 0 5rem 0 0;
    }
    svg{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 28%;
      height: 28%;
      *{
        fill: $navy;
      }
    }
    &::after{
      content: '';
      // background-image: url('src/images/contact-row_deco_navy.png');
      background-size: cover;
      position: absolute;
      width: 200%;
      height: 197%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: .04;
    }
  }
}


.page-header-inline-media {
    position:relative;
    width:100%;
    padding-top: 14rem;
    display:flex;
    align-items: stretch;
    @include desktop-small-above {
        min-height: 60vh;
    }
    @include desktop-small-below {
        padding-top: 8.5rem;
    }

    background-color:$navy-200;
    &.page-header--dark {
        background-color:$navy;
        h1, div {
            color:#fff;
        }
    }

    .wrapper {
        display:flex;
        flex-direction:row;
        align-items: center;
        flex-wrap:nowrap;
        @include desktop-small-below {
            flex-direction:column;
        }
    }

    &--video {
        @include desktop-small-below {
            .wrapper,
            .page-header-inline-media__secondary {
                align-items:initial;
            }
        }
    }

    &__swoosh {
        position:absolute;
        z-index:0;
        height:calc(100% - 15rem);
        width:auto;
        left:0;
        right:0;
        bottom: 0;
        .page-header--dark & {
            opacity:0.25;
            svg path {
                fill:$navy-900;
            }
        }
        @include phone-large-below {
            display:none;
        }
    }

    &__primary {
        @include column(10);
        display: flex;
        flex-direction: column;
        padding: 15rem 0 15rem 0;
        @include desktop-small-below {
            padding: 10rem 0 5rem 0;
            align-items: center;
            width:100%;
        }
        @include phone-large-below {
            padding: 6rem 0 3rem 0;
        }
        .page-header-inline-media--no-media & {
            width:80%;
            @include desktop-small-below {
                width:100%;
            }
        }
    }

    &__secondary {
        width:50%;
        padding-left:4rem;
        img {
            box-shadow:$shadow-500;
        }
        .page-header-inline-media--image-overflows & {
            align-self:flex-end;
        }
        @include desktop-small-below {
            padding-left:0;
            padding-bottom: 5rem;
            width:100%;
            display:flex;
            flex-direction: column;
            align-items: center;
        }
        .page-header-inline-media--no-media & {
            display:none;
        }
    }

    &__title {
        color:$navy;
        overflow-wrap:break-word;
        max-width: 100%;
        p {
            @extend %heading-one;
            color:$navy;
            margin:0;
        }
        .page-header--dark & {
            color:#fff;
            p {
                color:#fff;;
            }
        }
        @include desktop-small-below {
            text-align:center;
            p {
                text-align:center;
                margin: 0 auto;
            }
        }
    }

    &__summary {
        @extend %body-copy-large;
        color:$navy-600;
        max-width:120rem;
        margin-bottom:4rem;
        .page-header--dark & {
            color:#fff;
        }
        p {
            @extend %body-copy-large;
            color:$navy-600;
            .page-header--dark & {
                color:#fff;
            }
        }  
        p:last-child {
            margin-bottom:0;
        }
        @include desktop-small-below {
            text-align:center;
            p {
                text-align:center;
                margin: 0 auto;
            }
        } 
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        @include desktop-small-below {
            justify-content: center;
        }
        .button {
            margin:0 2rem 2rem 0;
            &:last-child {
                margin-right:0;
            }
        }
    }

    &--image-overflows {
        .image-multiple-block {
            top:4rem;
            @include desktop-small-below {
                top:0;
                padding:0;
            }
            img {
                margin-bottom:0;
            }
        }
    }

    &.page-header--interactive {
        min-height:initial;
        .page-header-inline-media__primary {
            padding: 5rem 0 2rem 0;
            @include desktop-small-below {
                padding: 8rem 0 5rem 0;
            } 
        }
        .page-header-inline-media__swoosh {
            display:none; 
        }
    }


}

.page-header-inline-media--image-overflows {
    .wrapper {
        @include desktop-medium-below {
            flex-direction: column;
        }
    }
    .page-header-inline-media__primary {
        @include column(7);
        @include desktop-medium-below {
            width: 100%;
            padding: 10rem 0 5rem 0;
            align-items: center;
            text-align: center;
        }  
    }
    .page-header-inline-media__secondary {
        @include desktop-medium-below {
            padding-left: 0;
            padding-bottom: 5rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .image-multiple-block {
            @include desktop-medium-below {
                padding-left: 2rem;
                top: 0;
            }
            @include desktop-small-below {
                width: 100%;
            }
        }  
    }
}


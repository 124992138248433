.sidebar-related-items{

}

.sidebar-related-item{

  &:not(:last-child){
    margin-bottom: 2rem;
  }

  a, span{
    font-size: 1.8rem;
    color: $navy;
    @include phone-large-below{
      font-size: 2.3rem;
    }
  }

  > a{
    font-weight: 700;
    display: flex;
    position: relative;
    padding-left: 3rem;
    margin-bottom: 1rem;
    i{
      color: $orange;
      font-size: 2.2rem;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  > ul{
    

  }

  > ul > li, &.sidebar-related-item--simple{
    > a, > span{
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin-bottom: 1rem;
      padding-right: 2.5rem;
    }
    > a{
      &::after{
        font-family: 'icoMoon';
        content: '\e913';
        color: $navy-400;
        position: absolute;
        right: 0;
        transition: color .1s ease-in-out, transform .2s ease-in-out;
      }
      &:hover, &:focus {
        &::after {
          color: $navy;
          transform: translateX(.5rem);
        }
      }
    }
  }

  &.sidebar-related-item--simple {
    margin-bottom: 0;
    > a {
      padding-left: 0;
    }
  }
}

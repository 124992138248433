.icon-block{
  // @extend %block-base;
  margin-bottom: 2rem;
  width: 100%;

  &__heading{
    display: flex;
    margin-bottom: 1rem;
    @include phone-large-below {
      margin-bottom: 2rem;
    }
  }

  &__title{
    margin: 0;
    @include el-size(two-thirds, three-quarters, full){
      @extend %heading-three;
      @include phone-large-below{
        font-size: 2.9rem;
        margin-bottom:0;
      }
    }
    @include el-size(half, third, quarter, sixth){
      @extend %heading-five;
      @include phone-large-below{
        font-size: 2.9rem;
        margin-bottom:0;
      }
    }

  }



  &__icon{
    position: relative;

    svg:last-child{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
    }
    &--color-navy{
      svg:last-child *{
        fill: $navy;
      }
    }
    &--color-orange{
      svg:last-child *{
        fill: $orange;
      }
    }
  }

  a {
    margin-right: 0;
  }

  &__icon-bg{
    width: 100%;
    height: 100%;
    fill: #fff;
  }

  // Alignment
  &--vertical{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &--vertical &{
    &__heading{
      align-items: center;
      flex-direction: column;
      margin-bottom: 2rem;
    }
    &__icon{
      width: 10rem;
      margin-bottom: 2rem;
      @include el-size(quarter){
        width: 9rem;
      }
    }
  }

  &--horizontal &{
    &__heading{
      align-items: center;

    }
    &__icon{
      width: 7rem;
      margin-right: 2rem;
    }
  }


  // Colours
  &--white{
    box-shadow: $shadow-100;
    background-color: #fff;
    padding: 3rem 4rem;
    border-radius: 5px;
  }
  &--white & {
    &__icon-bg--color-navy {
      fill: $navy-100;
    }
    &__icon-bg--color-orange {
      fill: $orange-100;
    }
  }

}

.page-header-media-background {
    position:relative;
    width:100%;
    padding-top: 14rem;
    display:flex;
    align-items: stretch;
    min-height: 70vh;
    @include desktop-small-below {
        padding-top: 8.5rem;
    }
    background-color:$navy-200;

    > .wrapper {
        @include desktop-small-above {
            padding-bottom:2rem; 
        }
    }

    &.page-header--jump-links {
        > .wrapper {
            @include desktop-small-above {
                padding-top:14rem;
                padding-bottom:7rem; 
            }
        }
    }

    &__bg {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index:0;
        overflow: hidden;
    }

    &__bg-overlay {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index:1;
        background-color:rgba(0,0,0,0.6);
    }

    &__img, 
    &__video {
        height:100%;
        width:100%;
        object-fit:cover;
        object-position: center;
        position:relative;
        z-index:0;
    }

    &__video {
        transform:scale(1.8);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height:100%;
        padding: 2rem;
    }

    &__breadcrumb {
        font-size:1.8rem;
        font-weight:700;
        opacity:0.7;
        color:$navy;
        margin-bottom:4rem;
        a {
            color:$navy;
            text-decoration: none;
            &:hover{
                text-decoration: none;  
            }
        }
        .page-header--dark & {
            color:#fff;
            a {
                color:#fff;
            }
        }
    }

    &__title {
        color:$navy;
        text-align:center;
        p {
            @extend %heading-one;
            text-align:center;
            color:$navy;
            margin: 0 auto;
        }
        .page-header--dark & {
            color:#fff;
            p {
                color:#fff;;
            }
        }
    }

    &__summary {
        @extend %body-copy-large;
        color:$navy-600;
        max-width:100rem;
        text-align:center;
        margin: 0 auto;
        margin-bottom:4rem;
        .page-header--dark & {
            color:#fff;
        }
        p {
            @extend %body-copy-large;
            color:$navy-600;
            text-align:center;
            .page-header--dark & {
                color:#fff;
            }
        }  
        p:last-child {
            margin-bottom:0;
        }
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .button {
            margin:0 2rem 2rem 0;
            &:last-child {
                margin-right:0;
            }
        }
    }
}


.jump-links {
    display:flex;
    flex-direction:column;
    align-items: center;
    margin-top: 10rem;

    &__title {
        font-size:2.4rem;
        font-weight:700;
        color:$navy;
        .page-header--dark & {
            color:#fff;
        }
        margin-bottom:2.8rem;
    }

    &__links {
        display:flex;
        justify-content: center;
        align-items: center;
        flex-wrap:wrap;
        @include desktop-small-below {
            flex-direction:column;
        }
    }

    &__link { 
        margin:0 1.2rem 1.2rem 0;
        &:last-child {
            margin-right:0;
        }
    }
}
#planbox-modal {
    width:100%;
    margin: 0 auto;
    left:0;
    right:0;

    .modal-dialog {
       // max-width:100rem;
    }

    h3 {
        margin-bottom:4.5rem;
    }

    .modal-content {
        padding: 1rem 3rem 0 3rem;
    }

    .modal-header {
        justify-content: flex-end;
        border:0;
    }

    .btn-close {
        display:inline-block;
        background: none;
        border: 0;
        padding: 0;
        margin: 0;
        color: #5C7194;
        font-size:2rem;
        &:hover {
            cursor:pointer;
        }
    }
}

.innovations-area {
    &__section {
        margin-top:4rem;
        margin-bottom:4rem;
    }
    &__section-content {
        //margin-bottom:7rem;
        //margin-left: -1rem;
        //margin-right: -1rem;
        margin-top: 5rem;
    }
}

.innovations-area-block {
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    flex-wrap:wrap;
    margin-bottom: 4rem;
    max-width:100%;

    .innovations-area-block__image {
        width:33%;
        margin-right:4rem;
        @include tablet-large-below {
            width: 100%;
            margin: 0 0 2rem 0;
        }
    }

    .innovations-area-block__text-content {
        flex:1;
        div{
            > ul {
                @extend %list-simple;
            }
        }
    }
}



.innovations-more-info {
    &__section {
        background-color:$navy-900;
        width:100%;
        padding: 7.5rem 3rem 5rem;
        @include phone-large-below {
            padding: 7.5rem 0 5rem;
        }
        > .wrapper {
            display:flex;
            flex-direction:column;
            align-items: center;
            justify-content: center;
        }
        .button.button--tertiary .link-text{
            color:#fff;
            &:hover {
                color:$orange;
            }
        }
    }

    &__copy {
        max-width:110rem;
        margin-bottom:1rem;
    }

    &__section .wrapper &__copy * {
        color:#fff;
        text-align:center;
    }
    
    &__links {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        @include phone-large-below {
            flex-direction: column;
        }
    }

}


.innovations-partners {
    &__section {
        background-color:$navy-200;
        width:100%;
        padding: 10rem 0;
        @include phone-large-below {
            padding: 7.5rem 0;
        }
        > .wrapper {
            display:flex;
            flex-direction:column;
        }
        .solutions-feed__solution-parent, 
        .capabilities-feed__capability-parent {
            flex-direction: column;
        }
    }

    &__copy {
        max-width:100rem;
        margin-bottom:6rem;
        @include phone-large-below {
            margin-bottom:1rem;
        }
    }

    &__partners {
        display:flex;
        width:100%;
        flex-direction:row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom:6rem;
        > * {
            width:50%;
            @include tablet-large-below {
                width:100%;
            }
        }
    }
}

.innovations-projects {
    padding: 10rem 0;
    @include phone-large-below {
        padding: 7.5rem 0;
    }
}
.download-block{
	@extend %base-block;
	min-height: calc(100% - 2rem);
	
	&__primary{
		@extend %base-block__primary;
		align-items: flex-start;
		img{
			@extend %base-block__img;
			//height: 100%;
			box-shadow:$shadow-300;
		}
	}
	&__secondary{
		@extend %base-block__secondary;
		display: flex;
		flex-direction: column;
    	justify-content: center;
	}
	&__tag{
		@extend %base-tag;
		display:inline-block;
		margin-bottom:2rem;
		word-wrap: break-word;
    	width: fit-content;
	}
	&__title{
		// @include el-size(third, quarter, sixth){
		// 	@extend %heading-five;
		// }
		@include el-size(two-thirds, three-quarters, full){
            @extend %heading-three;
			@include phone-large-below{
                font-size: 2.9rem;
            }
        }
        @include el-size(half, third, quarter, sixth){
            @extend %heading-five;
			@include phone-large-below{
                font-size: 2.9rem;
            }
        }
	}
}
//block style overrides
.download-block--default{
	@include el-size(half, two-thirds, three-quarters, full){
		flex-direction: row-reverse;
	}
	@include el-size(third, quarter, sixth){
		flex-direction: row-reverse;
	}
	.download-block__primary{
		width: 40%;
		img {
			object-fit:contain;
			height:auto;
		}
	}
	.download-block__secondary{
		width: 60%;
		padding: 2rem 1rem 0 2rem;
	}
}
.download-block--expanded{
	@include el-size(half, two-thirds, three-quarters, full, third, quarter, sixth){
		flex-direction: column;
	}
	.download-block__primary{
		flex-direction: row;
		picture{
			width: 50%;
			@include el-size(third, quarter, sixth){
				width: 40%;
			}
			@include el-size(half, two-thirds, three-quarters, full){
				@include phone-large-below{
					width: 40%;
				}
			}
		}
		img{
			width: 100%;
		}
		@include el-size(full, half) {
			@include desktop-large-below {
			  @include column(12);
			}
		}
	}
	.download-block__details-container{
		padding-left: 2rem;
		width:50%;
		display:block;
		&:last-child{
			margin-bottom:0;
		}
		@include el-size(third, quarter, sixth){
			width: 60%;
		}
		@include el-size(half, two-thirds, three-quarters, full){
			@include phone-large-below{
				width: 60%;
			}
		}

	}
	.download-block__details{
		margin-bottom:1rem;
		padding:0;
		span{
			@extend %body-copy-small;
			color:$navy-500;
			@include el-size(third, quarter, sixth){
				@extend %body-copy-xsmall;
			}
		}
		p{
			@extend %body-copy-small;
			font-weight:700;
			color:$navy;
			margin-bottom:0;
			@include el-size(third, quarter, sixth){
				@extend %body-copy-xsmall;
			}
		}
	}
	.download-block__summary{
		@extend %body-copy-small;
		color:$navy-500;
		@include el-size(third, quarter, sixth){
			@extend %body-copy-xsmall;
		}
	}
}


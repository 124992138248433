.home-slideshow{
  .button {
    &.mobile-only{
      display: none;
      @include phone-large-below{
        display: flex;
        margin: 0;
      }
    }
    &.desktop-only{
      @include phone-large-below{
        display: none;
      }
    }

    &.button--secondary-alt{
      border-color: $orange;
      //margin: 0;
      i::before{
        color: $orange;
      }
      &:hover{
        border-color: #fff;
        i::before{
          color: $orange;
        }
      }
    }

  }


}



.home-slideshow .loading-animation {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: all .2s ease-in-out;
  pointer-events: none;

  .lds-dual-ring {
    width: 50px;
    height: 50px;
  }

  .lds-dual-ring:after {
    width: 40px;
    height: 40px;
    border-width: 4px;
    border-color: $navy transparent $navy transparent
  }

  .is-home-hero-video-loaded & {
    opacity: 0;
  }

}

.progress-bar {
  height: 1rem;
  width: 100%;
  background-color: #ccc;

  > span {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background-color: red;
  }
}

.home-slideshow video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  @include desktop-small-below{
    display: none;
  }
  //opacity: 0;
  //transition: opacity .75s ease-in-out;
}

.home-slideshow__mobile-image{
  display: none;
  &.slide__bg-image{
    opacity: 1;
  }
  @include desktop-small-below{
    display: block;

  }
}



.home-slideshow {
  min-height: 90vh;
  // height: 100rem;
  width: 100%;
  background-color: $navy-900;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  //align-items: center;
  position: relative;


  @include desktop-medium-above {
    //min-height: 700px;
    min-height: 90vh;
  }


  @media screen and (orientation: landscape) {
    @include desktop-small-below{
      padding-top: 1rem;
    }
    @include tablet-large-below {
      //height: 125vh;
      align-items: flex-start;
      //padding-top: 20vh;
    }
    @include tablet-below {
    //  height: 110vh;
    }
  }


  p {
    @extend %body-copy-large;
    color: #fff;
    max-width: 1000px;
    opacity: .8;
    @include desktop-large-below {
      max-width: 800px;
    }
  }
}

.slide__bg-image{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: .2;
}

.home-slideshow__video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.home-slideshow__primary {
  @include column(9);
  position: relative;
  z-index: 3;
  // opacity: 0;
  transition: opacity .75s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &:focus{
    outline: 0;
  }

  .is-home-hero-video-loaded & {
    opacity: 1;
  }

  @include desktop-small-below {
    text-align: center;
    align-items: center;
    padding: 0 2%;
    @include column(12);
    // @include push(1);
  }
  // @include tablet-below{
  //   padding: 20rem 0 10rem 0;
  // }
  @include phone-large-below {
    // padding: 0 2rem;
  }
  @include phone-small-below {
    padding: 0;
    margin-top: -2rem;
  }

  p {
    animation: titleFadeSlide .7s cubic-bezier(.23, .65, .63, .99) both .2s;
    @media(max-width:450px) {
      font-size: 2rem;
    }
    @include phone-small-below {
      margin: 1.5rem;
    }
  }

  & > a:last-of-type {
    margin-right: 0;
  }

  br {
    display: none;
    @include tablet-below {
      display: inherit;
    }
  }
}


.home-slideshow__pretitle{
  font-size: 2rem;
  font-family: $font-heading;
  color: $orange;
  margin-bottom: 1rem;
  margin-left: .2rem;
  animation: titleFadeSlide .7s cubic-bezier(.23, .65, .63, .99) both;
}

.home-carousel__button--primary{
  animation: titleFadeSlide .6s cubic-bezier(.23, .65, .63, .99) both .3s;
}

.home-slideshow__title {
  @extend %heading-one;
  color: #fff;
  max-width: 1400px;
  line-height: 1.1;
  animation: titleFadeSlide .7s cubic-bezier(.23, .65, .63, .99) both .1s;


  @include height-large {
    font-size: 6rem;
  }
  @include height-medium {
    font-size: 6rem;
  }
  @include desktop-xlarge-below {
    font-size: 6rem;
  }

  @include desktop-medium-below {
    font-size: 6rem;
    @media (orientation: landscape) {
      font-size: 6rem;
     // margin-bottom: 0;
    }
  }
  @include desktop-small-below {
    @media (orientation: landscape) {
      font-size: 3rem;
    }
  }
  @include tablet-small-below {
    font-size: 4rem;
  }
  @media(max-width: 450px) {
    font-size: 3.5rem;
    line-height: 1.3;
  }
  @include phone-small-below {
    margin-bottom: 0;

  }





}

@keyframes titleFadeSlide{
  from{
    opacity: 0;
    transform: translateY(2rem);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
}


.home-slideshow__mobile-actions {
  display: none;
  @include tablet-below {
    display: block;
  }
}

.carousel {
  display: flex;
  opacity: 1;
  transition: opacity .5s ease-in-out;

  .is-ready & {
    //opacity: 1;
  }
}

.carousel-inner {
  margin-top: 0;
  display: flex;
  overflow: visible;
  z-index: 1;
  opacity: 0;
  transition: all .2s ease-in-out;
  .is-home-hero-video-loaded & {
    opacity: 1;
  }
  @include tablet-below {
    //  display: none;
  }
}


.home-slideshow__tabs-wrapper {
  position: absolute;
  bottom: 7rem;
  //transform: translateY(-50%);
  //width: 100%;
  z-index: 5;
  // margin-left: -3rem;
  opacity: 0;
  width: 100%;
  //left: calc((100% - 1660px) / 2 + 17px);
  transition: opacity .75s ease-in-out;

  //right: $wrapper-offset;
  @include desktop-large-below{
    //right: 2rem;
  }
  //.wrapper{
  //  display: flex;
  //  justify-content: flex-end;
  //  flex-direction: row;
  //}
  @include desktop-small-below {
    top: auto;
    bottom: 8rem;
    right: auto;
    transform: translateY(0);
    @include column(12);
    @media (orientation: landscape) {
      bottom: 3rem;
    }
  }
  @include tablet-below{
    bottom: 5rem;
  }
  @include phone-small-below {
    bottom: 3.5rem;
  }
  // @include tablet-below {
  //   display: none;
  // }

  .is-home-hero-video-loaded & {
    opacity: 1;
  }
}

.home-slideshow__tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  right: auto;
  @include desktop-small-below {
    flex-direction: row;
    margin-right: 0;
    justify-content: center;
    padding-top: 5rem;
    //&::before {
    //  content: "";
    //  display: block;
    //  @include column(9);
    //  height: 2px;
    //  background-color: $navy-100;
    //  position: absolute;
    //  top: 0;
    //  left: 50%;
    //  transform: translateX(-50%);
    //  opacity: .2;
    //  @media (orientation: landscape) {
    //    opacity: 0;
    //  }
    //}
  }
  @media(max-width:450px) {
    padding-top: 2rem;
  }
  // @include tablet-below {
  //   display: none;
  // }

  li {
    margin-right: 3rem;
    @include tablet-below{
      margin: 0 2rem;
    }
    @include phone-large-below{
      margin: 0 1rem;
    }
    @include phone-small-below {
      margin: 0 .3rem;
    }
  }


}

.home-slideshow__tabs button {
  color: $navy;
  position: relative;
  //padding: 0 3rem;
  background: transparent;
  height: auto;
  width: auto;
  text-indent: 0;
  background: transparent;
  border: 0;
  flex: 0;
  opacity: 1;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  text-align: right;
  //margin-bottom: 2.5rem;
  padding-left: 40px;

  // @include desktop-medium-below {
  //   padding: 0 2rem;
  // }
  @include desktop-small-below {
    //margin-bottom: 0;
   //  @media screen and (orientation: landscape) {
   //    > div {
   //      margin-right: .5rem;
   //    }
   //}



  }

  @include tablet-below{
    padding-left: 0;
    padding-top: 3rem;
    padding-right: 0;
  }

  @include phone-large-below {
    //padding-right: 1rem;
  }



  &:last-child {
    //padding-right: 0;
  }

  > span {
    pointer-events: none;
  }


  span.home-slideshow__tab-text-secondary {
    display: block;
    color: #fff;
    font-size: 1.4rem;
    opacity: .6;
    font-family: $font-heading;
    transition: all .2s ease-in-out;
  }
  span.home-slideshow__tab-text-primary {
    display: block;
    color: #fff;
    font-size: 2.4rem;
    opacity: .6;
    font-family: $font-heading;
    transition: all .2s ease-in-out;
    @include tablet-below {
      font-size: 1.8rem;
    }
    @media (max-width: 450px) {
      font-size: 1.6rem;
    }
  }

  &:hover, &:focus {
    > span:first-child {
      color: #fff;
    }

    .home-slideshow__tab-deco{
      &::after{
        color: $orange;
        opacity: 1;
      }
    }

    span.home-slideshow__tab-text-primary, span.home-slideshow__tab-text-secondary {
      opacity: 1;
    }

    span.home-slideshow__tab-text-primary::after {
      color: #fff;
      width: 100%;
      left: 0;
      //  transition: all 1s cubic-bezier(0.53, -0.01, 0, 1.03);
    }
  }

  &.current {
    &::before {
      border-color: #fff;
    }

    span.home-slideshow__tab-text-secondary {
      color: $orange;
    }

    span.home-slideshow__tab-text-primary, span.home-slideshow__tab-text-secondary {
      opacity: 1;
    }

    span.home-slideshow__tab-text-primary {
      color: #fff;
      &::after {
        width: 100%;
      }
    }
  }

}


.home-carousel__button--primary{
  @include desktop-small-below{
    @media screen and (orientation: landscape) {
      margin-bottom: 0;
    }
  }
}

.home-slideshow__primary .button--secondary-alt{
  @media screen and (orientation: landscape) {
    @include desktop-small-below {
      font-size: 1.6rem;
    }
  }
}


.home-slideshow__tab-deco{
  position: absolute;
  bottom: .2rem;
  left: .2rem;

  @include tablet-below{
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (orientation: landscape) {

  }
  &::after{
    font-family: icoMoon;
    font-size: 2.7rem;
    display: block;
    color: #fff;
    transform: translateX(.2rem);
    opacity: .5;
    .current &{
      display: none;
    }

    li:nth-child(1) &{
      content: '\e927';
    }
    li:nth-child(2) &{
      content: '\e928';
    }
    li:nth-child(3) &{
      content: '\e929';
    }
    li:nth-child(4) &{
      content: '\e92a';
    }
    li:nth-child(5) &{
      content: '\e92b';
    }
  }
  
  @include phone-large-below {
    //bottom: -3.5rem;
    //right: 1rem;
    //transform: none;
    //.current & {
    //  bottom: -4.3rem;
    //}
  }
}

$dot-diameter: 24px;
$circle-border-width: 4px;
$default-color: $orange;
$completion-color: $orange;
.home-slideshow__tabs {
  //width: 22px;
  //height: 22px;
  //margin-right: .5rem;
  // margin-right: 5rem;


  .circle-container {
    width: $dot-diameter;
    height: $dot-diameter;
    transform: rotate(-90deg) translateX(2px);
    opacity: 0;
    transition: opacity .3s ease-in-out;
    margin-left: 4px;
    display: none;
    @include tablet-below{
      width: 18px;
      height: 18px;
      transform: rotate(-90deg) translateX(0);
    }
  }

  button.current .circle-container{
    opacity: 1;
    display: block;
  }

  .circle-container__background {
    fill: none;
    stroke: $default-color;
    stroke-width: $circle-border-width;
    opacity: .3;
  }


  .circle-container__progress {
    fill: none;
    stroke-linecap: round;
    stroke: $completion-color;
    stroke-dasharray: 100 100;
    stroke-dashoffset: 100;
    stroke-linecap: round;
    stroke-width: $circle-border-width;
    // For animations...
     //transition: stroke-dashoffset 1s ease-in-out;
   //  will-change: transform;
  }

}



.active .slide.next {
  // left: 100%;
  opacity: 0;
}

.active .slide.prev {
  // left: -100%;
  opacity: 0;
}

.active .slide.next.in-transition,
.active .slide.prev.in-transition {
  visibility: visible;
}


.slide {
  width: 100%;
  pointer-events: none;
  padding: 0;
  margin: 0;
  height: 100%;
  position: absolute;
  overflow: hidden;
  border: none;
  display: none;
  top: 0;
  left: 0;
  z-index: 200;
  visibility: hidden;

  &[slide-theme=blue] {
    background-color: $orange;
  }

  &[slide-theme=case-study-aha] {
    background-color: #EF3A4D;
  }


  .wrapper {
    flex-direction: row;
    z-index: 2;
    @include desktop-small-below {
      flex-direction: column;
      @media screen and (orientation: landscape) {
        margin-top: 2rem;
      }
    }
  }


  .btn-primary, .btn-secondary {
    opacity: 0;
    transform: translateY(2rem);
    transition: all .7s cubic-bezier(.53, -.01, 0, 1.03);
  }

  &.current {
    pointer-events: auto;
    display: flex;
    z-index: 5;
    visibility: visible;
    opacity: 1;

    video{
      animation: slideFadeIn 1s ease-in-out both;
    }

    .slide__bg-image{
      animation: slideImgFadeIn 1s cubic-bezier(.23, .65, .63, .99) both;
      &.home-slideshow__mobile-image{
        animation: slideImgFadeInLight 1s cubic-bezier(.23, .65, .63, .99) both;
      }
    }





    // transition: opacity 0s ease-out .5s;
    &:focus {
      outline: 0;
    }

    .btn-primary, .btn-secondary {
      opacity: 1;
      transform: translateY(0);
    }

    .btn-primary {
      transition-delay: .5s;
    }

    .btn-secondary {
      transition-delay: .7s;
    }
  }

  &.prev {
    .btn-primary, .btn-secondary {
      opacity: 0;
      transform: translateY(2rem);
    }
  }

  .active  &{

  }


  &[slide-type=video]{

    &::after{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #001C49;
      opacity: .7;
    }

  }
}

@keyframes slideFadeIn{
  from{
    opacity: 0;
  }
  to{
    opacity: .99;
  }
}

@keyframes slideImgFadeIn{
  from{
    opacity: 0;
  }
  to{
    opacity: .2;
  }
}

@keyframes slideImgFadeInLight{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}


.carousel {
  width: 100%;

  @include desktop-small-below {
    .wrapper {
      justify-content: center;
    }
  }
}

.slide{

}

.carousel {
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  //align-items: center;
  position: relative;
  // transition: opacity .5s ease-in-out;
  overflow: hidden;
  min-height: 90vh;
  // width: 100%;
  @media screen and (orientation: landscape) {
    min-height: 100vh;
  }
}

.carousel {
  //overflow-x: hidden;
  position: relative;
}

.carousel ul {
  margin: 0;
  padding: 0;

  li {

  }
}

.home-carousel__actions {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;

  @include phone-large-below{
    display: none;
  }

  a {
    margin-right: 2rem;

    &:nth-child(1){
      animation: titleFadeSlide .6s cubic-bezier(.23, .65, .63, .99) both .35s;
    }
    &:nth-child(2){
      animation: titleFadeSlide .6s cubic-bezier(.23, .65, .63, .99) both .4s;
    }
    &:nth-child(3){
      animation: titleFadeSlide .6s cubic-bezier(.23, .65, .63, .99) both .45s;
    }

  }
  // a, button {
  //   &:last-of-type {
  //     margin-right: 0;
  //   }
  // }

  @include desktop-small-below {
    justify-content: center;
  }
  @include tablet-below {
    //  display: none;
  }
  @include phone-large-below {
    flex-wrap: wrap;
  }
  // @media(max-width: 450px) {
  //   a {
  //     margin-right: 1rem;
  //     padding: 1.2rem 1rem;
  //     font-size: 1.8rem;
  //     i {
  //       display: none;
  //     }
  //   }
  //   .button--size-large {
  //     font-size: 2rem;
  //   }
  // }
  // @include phone-small-below {
  //   margin-top: 0;
  //   a {
  //     padding: 1rem 1.2rem;
  //     margin-bottom: 1.5rem;
  //     font-size: 1.6rem;
  //   }
  // }
}


.carousel.active {
  // height: 275px;
  position: relative;
}



.slide.current {

}


.controls {
  position: absolute;
  bottom: 6rem;
  z-index: 600;
  display: flex;

  right: $wrapper-offset;

  opacity: 0;
  transition: all .2s ease-in-out;
  .is-home-hero-video-loaded & {
    opacity: 1;
  }

  @include desktop-large-below{
    right: 4rem;
  }
  @include desktop-small-below {
      right: 4rem;
      top: 13rem;
      bottom: initial;
  }
  @include phone-large-below {
    right: 2rem;
    top: 12rem;
}

  .btn-prev,
  .btn-next,
  .home-slideshow__btn-start {
    width: 5rem;
    height: 5rem;
    background-color: rgba(255, 255, 255, .1);
    border-radius: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    margin-right: 0rem;
    transition: all .15s ease-in-out;
    border: 0;
    cursor: pointer;

    &::before {
      font-family: 'icoMoon';
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.9rem;
    }

    &:hover, &:focus {
      background-color: rgba(255, 255, 255, .3);
    }
  }

  .home-slideshow__btn-start {
    background-color: rgba(255, 255, 255, .1);

    &:hover, &:focus {
      background-color: rgba(255, 255, 255, .3);
    }

    &[data-action=stop]::before {
      content: '\e926';

    }

    &[data-action=start]::before {
      content: '\e907';
    }
  }

  .btn-next:hover,
  .btn-prev:hover {
    opacity: 1;
    cursor: pointer;
  }

  .btn-next:focus,
  .btn-prev:focus {
    outline: 3px solid #efdd03;
  }

  .btn-prev {
    &::before {
      content: '\e902';
    }
  }


  .btn-next {
    &::before {
      content: '\e900';
    }

  }
}

.carousel.with-slidenav {
  //padding-bottom: 2em;
  // background-color: #fff;
}


#info, #info2 {
  position: fixed;
  top: 0;
  padding: 3rem;
  background-color: #fff;
  font-size: 3rem;
  font-weight: 700;
  z-index: 999;
}

#info {
  left: 0;
}

#info2 {
  right: 0;

}





.row-industry-experts{
  position: relative;

  .row-line-deco::after{
    background-color: $navy-200;
  }
  .wrapper{
    flex-direction: row;
    position: static;
    flex-wrap: wrap;
  }
  &__primary{
    @include column(6);
    @include push(6);
    position: relative;
    z-index: 1;
    padding: 15rem 4rem 15rem 10rem;
    @include desktop-large-below{
      padding: 6em 2rem 6rem 8rem;
    }
    @include tablet-below{
      @include column(12);
      @include push(0);
      padding: 4rem 0;
      text-align: center;
      // &::before{
      //   content: '';
      //   display: block;
      //   width: 0;
      //   height: 0;
      //   border-left: 25px solid transparent;
      //   border-right: 25px solid transparent;
      //   border-bottom: 25px solid #fff;
      //   position: absolute;
      //   left: 50%;
      //   top: 0;
      //   transform: translateX(-50%) translateY(-100%);
      // }
    }
    @include phone-large-below{
      padding: 3rem 0;
    }



  }

  &__image{
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
  }

  a{
    margin-bottom: 0;
  }
  &__secondary{
    @include column(6);
    position: absolute;
    left: 0;
    height: 100%;
    overflow: hidden;
    @include tablet-below{
      width: 100%;
      position: relative;
    }
  }

  &__title{
    @extend %heading-one;
  }
  p{
    @extend %body-copy-large;
    @include phone-large-below {
      font-size:2.3rem;
    }
  }

  .button{
    margin: 0;
  }
}


.row-line-deco{
  height:17rem;
  height: 12rem;
  position: relative;
  display: block;
  &::after{
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 2px;
    height: 0;
    background-color: red;
    transition: height 2s cubic-bezier(0.64, 0.39, 0.03, 1);

  }

  &.mobile-only{
    display: none;
    @include tablet-below{
      display: block;
    }
  }
  &--short{
    height: 3rem;
  }

  &.aos-animate::after{
    height: 100%;
  }





}






.row-services{
  background-color: $navy-900;
  color: #fff;
  overflow: hidden;
  @include desktop-medium-below{
    padding-bottom: 8rem;
  }
  @include phone-large-below {
    padding-bottom:2rem;
  }


  .row-line-deco::after{
    background-color: #294672;
  }

  .row-line-deco {
    @include phone-large-below {
      height:68px;
    }
  }

  &__mobile-image{
    display: none;
    max-width: 80%;
    margin: 6rem auto 0 auto;
    @include desktop-medium-below{
      display: block;
    }
    @include phone-large-below{
      width: 130%;
      max-width: none;
      margin-left: -15%;
    }
  }


  &__title{
    @extend %heading-one;
    color: #fff;
    text-align: right;
    display: inline-block;
    @include phone-large-above {
      padding-left: 5rem;
    }
    @include desktop-medium-below{
      text-align: center;
    }
    @include phone-large-below {
      margin-top:0;
    }
  }
  &__title-num{
    display: inline-block;
    min-width: 3ch; //fix width bug on JS countup
    @include desktop-medium-below{
      display: block;
    }
  }

  p{
    @extend %body-copy-large;
    color: #fff;
    max-width:62rem;
    @include desktop-medium-below{
      margin-left:auto;
      margin-right:auto;
    }
    @include phone-large-below {
      font-size:2.3rem;
    }
  }

  &__primary{
    //display: flex;
    align-items: top;
    display: grid;
    grid-template-columns: 1fr 200px 1fr;
    column-gap: 8rem;
    margin: 5rem 0;

    @include desktop-medium-below{
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 0;

      > *:nth-child(1){
        order: 2;
        margin: 3rem 0;
      }
      > *:nth-child(2){
        order: 1;
      }
      > *:nth-child(3){
        order: 3;
      }
    }

    svg{
      width: 100%;
      max-width: 200px;
      *{
        stroke-width: 1.5px;
      }
      @include desktop-medium-below{
        display: none;
      }
    }

  }

  .button--secondary-alt{
    border-color: $orange;
    margin: 0;
    i::before{
      color: $orange;
    }
    &:hover{
      border-color: #fff;
      i::before{
        color: $orange;
      }
    }
  }

  &__button {
    @include phone-large-below {
      display:none;
    }
  }

}


.row-services-slider{
  background-color: $navy-900;
  color: #fff;
  padding: 0rem 0 6rem 0;
  position: relative;
  padding-top: 14rem;
  overflow: hidden;

  @include desktop-medium-below{
    padding-top: 5rem;
    &::before{
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      width: calc(100% - 6rem);
      height: 2px;
      background-color: #294672;
      transition: width 2s cubic-bezier(0.64, 0.39, 0.03, 1) .5s;
    }
  }

  @include phone-large-below{
    padding-top: 2rem;
    padding-bottom: 3rem;
  }




  &__primary{
    @include column(6);
    position: relative;
    z-index: 1;
    padding: 0 0 12rem 0rem;
    @include desktop-large-below{
      padding: 0 0 6rem 0;
    }
    @include desktop-medium-below{
      @include column(12);
      padding: 4rem 0;
    }
    @include phone-large-below{
      padding: 3rem 0 12rem 0;
    }
  }

  &__secondary{
    @include column(6);
    position: absolute;
    right: -5rem;
    top: 0;
    height: 100%;
    img{
      max-width: 110%;
    }
    @include desktop-medium-below{
      display: none;
    }
    @include tablet-below{
      width: 100%;
      position: relative;
    }
  }


  .row-services__button {
    display:none;
    @include phone-large-below {
      margin: 0 auto;
      display: flex;
      width: fit-content;
      &.button--secondary-alt {
          border-color: $orange;
          i::before{
            color: $orange;
          }
          &:hover{
            border-color: #fff;
            i::before{
              color: $orange;
            }
          }
      }
    }
  }

}


.row-services-slider__row-line-deco{
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  width: 2px;
  &::after{
    content: '';
    transform: translateX(-50%);
    width: 2px;
    height: 0;
    background-color: #294672;
    display: block;
    transition: height 2s cubic-bezier(0.64, 0.39, 0.03, 1) .5s;
    @include desktop-medium-below{
      display: none;
    }
    .aos-animate &{
      height: 100%;
    }
  }
  span{
    height: 14rem;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    left: -0.5px;
    //transform: translateX(-50%);

    &::before, &::After{
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      width: 2px;
      height: 0;
      //transform: translateX(-50%);
    }
    &::before{
      background-color: $orange;
      transition: height 1s cubic-bezier(0.64, 0.39, 0.03, 1) 2s;
      .aos-animate &{
        height: 14rem;
      }
    }
    &::after{
      background-color: #DDE1E8;
      top: 9rem;
      transition: height 1s cubic-bezier(0.64, 0.39, 0.03, 1) 2.5s;
      .aos-animate &{
        height: 5rem;
      }
    }

    @include phone-large-below{
      height: 50px;
      &::before{
        .aos-animate &{
          height: 50px;
        }
      }
      &::after{
        top: 38px;
        .aos-animate &{
          height: 12px;
        }
      }
    }
  }
  &::after{
   // background-color: #DDE1E8;
  }

}

.row-services-slider-nav{
  display: flex;
  justify-content: flex-end;
  position: relative;
  border: 0;

  padding: 0 9rem 0 0rem;
  @include desktop-large-below{
    padding: 0 9rem 0 0rem;
  }
  @include desktop-medium-below{
    justify-content: center;
    padding: 0 4rem 0 4rem;
  }
  @include phone-large-below{
    padding: 0 0rem;
    justify-content: space-between;
  }

  &::after{
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 2px;
    background-color: #294672;
    width: 0;
    transition: width 1s cubic-bezier(0.64, 0.39, 0.03, 1) 1s;
    .aos-animate &{
      width: 4.5rem;
    }
    @include desktop-medium-below{
      display: none;
    }
  }

  li:last-child button{
    @include desktop-medium-above{
      margin-right: 0;
    }
  }
  button{
    font-family: $font-heading;
    font-size: 2rem;
    color: #fff;
    background: transparent;
    font-weight: 500;
    border: 0;
    position: relative;
    cursor: pointer;
    margin-left: 6rem;
    padding: 0 0 1rem 0;
    @include desktop-large-below{
      margin-left: 3rem;
    }
    @include desktop-medium-below{
      margin: 0 3rem 0 3rem;
    }
    @include phone-large-below{
      margin: 0 1rem 2rem 1rem;
    }

    &:hover, &:focus{
    //  opacity: 1;
    }
    opacity:.7;
    &.active{
     opacity: 1;
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: $orange;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.row-services-slider-slide{
  text-align: left;
  margin: 6rem  0 0 0;
  padding: 0 6rem 0 8rem;

  display:flex;
  flex-direction:column;
  align-items:flex-start;

  &__title{
    color: #fff;
    max-width:54rem;
    margin-bottom:3rem;
    @include phone-large-below {
      font-size: 2.9rem;
    }
  }
  @include desktop-medium-below{
    align-items: center;
    text-align: center;
    margin: 5rem 0;
    padding: 0 6rem 0 6rem;
  }

  p {
    // max-width:54rem;
  }


  @include desktop-large-below{
   // padding-right: 4rem;
  }
  @include phone-large-below{
    padding: 2rem 0 1rem 0;
    margin: 0rem 0 0rem 0;
  }

}




.row-facilities{
  background-color: $navy-200;
  //color: #fff;


  .row-line-deco::after{
    background-color: #DDE1E8;
  }

  .row-line-deco {
    @include phone-large-below {
      height:16px;
    }
  }

  .row-line-deco--bottom {
    @include phone-large-below {
      display:none;
    }
  }

  &__title{
    margin-top: -1.5rem;
    @extend %heading-one;
    text-align: right;
    @include desktop-medium-below{
      text-align: center;
    }
    @include phone-large-below{
      padding: 2rem 8rem;
    }
  }

  p{
    @extend %body-copy-large;
    max-width:62rem;
    @include desktop-medium-below{
      margin-left:auto;
      margin-right:auto;
    }
    @include phone-large-below {
      font-size:2.3rem;
    }
  }

  &__primary{
    //display: flex;
    align-items: top;
    display: grid;
    grid-template-columns: 1fr 200px 1fr;
    column-gap: 8rem;
    margin: 5rem 0;

    @include desktop-medium-below{
      display: flex;
      flex-direction: column;
      text-align: center;

      > *:nth-child(1){
        order: 2;
        margin: 3rem 0;
      }
      > *:nth-child(2){
        order: 1;
      }
      > *:nth-child(3){
        order: 3;
      }
    }

    svg{
      width: 100%;
      max-width: 200px;
      *{
        stroke-width: 1px;
      }
      @include desktop-medium-below{
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-17%);
        opacity: 0.2;
      }
      @include phone-large-below{
        transform: translateX(-50%) translateY(-10%);
      }
    }

  }


  .button--secondary-alt{
    border-color: $orange;
    margin: 0;
    .link-text{
      color: $navy;
    }
    i::before{
      color: $orange;
    }
    &:hover{
      border-color: $navy;
      background: $navy;
      span.link-text{
        color: #fff;
      }
      i::before{
        color: $orange;
      }
    }
  }

  &__button {
    @include phone-large-below {
      display:none;
    }
  }


}



[data-aos='ki-wipe-in-left'] {
  clip-path: inset(0 0 0 100%);
  transition-duration: 1s;
  transition-property: clip-path;
  // animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-left both;

  &.aos-animate {
    clip-path: inset(0 0 0 0);
  }
}

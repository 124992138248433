//*************************** */
// Media Center Index Styles
//************************ */
.media-center__featured-media {
    padding: 5rem 0;
    background: $navy-300;
    margin: 5rem 0;

    &--hide {
        display: none;
    }
    .featured-media__row {
        display: flex;
    }
}

.search-results__list--media {
    margin: 5rem 0;
}

.media-section {
    display: flex;
    align-items: center;

    .view-all-media {
        padding-bottom: .5rem;
        margin-left: 3rem;
    }
}


//*************************** */
// Events Styles
//************************ */
.events__upcoming-events {
    padding: 5rem 0;
    background: $navy-300;
    margin: 5rem 0;
    // Make upcoming events background extend past the wrapper
    width: calc(100vw - 2rem);
    margin-left: calc(-50vw + 50% + 1rem);

    &--hide {
        display: none;
    }
}


//*************************** */
// Downloads Styles
//************************ */
.secondary-filters-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include phone-large-below {
        flex-direction: column-reverse;
    }

    .reset-all-filters {
        width: auto;
        @include phone-large-below {
            width: 100%;
        }
    }
}

.media-section--downloads__section-title {
   @extend %heading-three;
}

.download-ready__form {
    margin: 5rem 0 15rem;
}
.download-ready__download-block {
    margin: -5rem 0 30rem;
    @include phone-large-below {
        margin: 5rem 0 25rem;
    }
}


//****************************** */
// View Page Styles
//****************************** */
.page-header--news-release {
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.55);
    }
    .page-header-inline-media__swoosh {
        svg path {
            fill: $white !important;
        }
    }
    .page-header-inline-media__primary {
        padding: 5rem 0 15rem;
        @include desktop-small-below {
            padding: 15rem 0;
        }
    }
}

.page-header__publish-date, .page-header__author {
    font-family: $font-body;
    font-weight: 700;
    color: $white;
}

.page-header__publish-date {
    display: block;
    font-size: 2.2rem;
    margin: 6.4rem 0 3rem;
    @include desktop-small-below {
        margin-top: 3rem;
    }
}

.page-header__author {
    font-size: 1.8rem;
    font-style: italic;
    opacity: 0.7;
}

.sidebar-container {
    margin-top: 7.5rem;
    @include desktop-small-below {
        margin-top: 0;
    }
    &.sidebar-container--event {
        margin-top: 0;
    }
}



//****************************** */
// Video View Page
//****************************** */

.video-view-page {
    padding-top:24rem;
    @include desktop-small-below {
        padding-top:12rem;
    }

    &__main {
        display:flex;
        align-items: flex-start;
        flex-wrap:nowrap;
        margin-bottom:12rem;
        @include desktop-medium-below {
            flex-direction:column;
            margin-bottom:4rem;
        }
    }

    .breadcrumbs {
        margin-bottom:5rem;
    }

    &__video {
        width:66%;
        @include desktop-medium-below {
            width:100%;
            margin-bottom:3rem;
        }
    } 

    &__sidebar {
        width:33%;
        padding-left:5.5rem;
        @include desktop-medium-below {
            width:100%;
            padding-left:0;
        }   
        h1 {
            @extend %heading-two;
        }
    }

    &__tags-title {
        margin-bottom: .5rem;
        font-size:2rem;
    }

    &__tags {
        display:flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__tag {
        margin-right:0.6rem;
        padding-right: 0.6rem;
        position:relative;
        &::after {
            content:",";
            position:absolute;
            right:0;
            top:2px;
        }
        &:last-child {
            &::after {
                display:none;
            }
        }
        a {
            font-size:1.8rem;
            color:$navy-500;
            font-weight:400;
        }
        a[href] {
            text-decoration: underline;
        }
    }

    .video-container {
        overflow: hidden;
        position: relative;
        width:100%;
        border-radius:5px;
    }
    
    .video-container::after {
        padding-top: 56.25%;
        display: block;
        content: '';
    }
    
    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__contine-watching {
        padding-top: 3rem;
        padding-bottom: 9rem;
        h3 {
            margin-bottom: 4rem;
        }
    }
}
.key-project-block{
    @extend %base-block;
    min-height: calc(100% - 2rem);
    overflow: hidden;
    @include el-size (half, two-thirds, three-quarters, full){
        @include tablet-large-below{
            flex-direction:column;
        }
    }
    @include tablet-large-below{
        flex-direction:column;
    }
    &__primary{
        @extend %base-block__primary;
        padding-top:4rem;
        @include el-size (half, two-thirds, three-quarters, full){
            @include tablet-large-below{
                width: 100%;
            }
        }
        @include phone-large-below{
            // padding-top:2rem;
        }
        
    }
    &__tag{
        @extend %base-tag;
        z-index:4;
    }
    &__info{
        display:flex;
        flex-direction:column;
        flex-wrap:wrap;
        margin-bottom:2rem;
    }
    &__info--project{
        @include el-size(half, two-thirds, three-quarters, full){
            @include tablet-large-below {
                position:absolute;
                top:-2.5rem;
                left:0rem;
                display:flex;
                border-top-right-radius:5px;
                background-color:#fff;
                flex-direction:row;
                span{
                    display:inline-block;
                    padding:1rem 1.8rem;
                    font-size:1.5rem;
                    font-weight: 200;
                    @include el-size(sixth){
                        font-size:1.2rem;
                    }
                }
            }
            @include phone-large-below {
                top: -3rem;
                span{
                    font-size:2rem;
                }
            }
        }
        @include el-size(third, quarter, sixth){
            position:absolute;
            top:-2.5rem;
            left:0rem;
            display:flex;
            border-top-right-radius:5px;
            background-color:#fff;
            flex-direction:row;
            @include phone-large-below {
                top:-3rem;
            }
            span{
                display:inline-block;
                padding:1rem 1.8rem;
                font-size:1.5rem;
                font-weight: 200;
                @include el-size(sixth){
                    font-size:1.2rem;
                }
                @include phone-large-below {
					font-size:2rem;
				}
            }
        }
    }
    &__info--project &{
        @include el-size(half, two-thirds, three-quarters, full){
            @include tablet-large-below{
                &__tag{
                    border-top-left-radius:0rem;
                    border-bottom-left-radius:0rem;
                }
            }
            @include phone-large-below{
                &__tag{
                    border-top-left-radius:0rem;
                    border-bottom-left-radius:0rem;
                }
                &__date{
                    border-top-right-radius:5px;
                }
            }
        }
        @include el-size(third, quarter, sixth) {
            &__tag{
                border-top-left-radius:0rem;
                border-bottom-left-radius:0rem;
                // transform: translateY(-20%);
            }
            &__date{
                border-top-right-radius:5px;
            }
        }
    }
    &__title{
        @include el-size(two-thirds, three-quarters, full){
            @extend %heading-three;
            @include phone-large-below{
                font-size: 2.9rem;
            }
        }
        @include el-size(half, third, quarter, sixth){
            @extend %heading-five;
            @include phone-large-below{
                font-size: 2.9rem;
            }
        }
        margin-bottom:1.5rem;
    }
    &__secondary{
        @extend %base-block__secondary;
        padding: 0;
        width: 50%;
        z-index: 2;
        @include el-size (half, two-thirds, three-quarters, full){
            padding-bottom: 0;
            @include tablet-large-below{
                width: 100%;
            }
        }
        @include tablet-large-below{
            width:100%;
        }
        picture {
            width: 100%;
            height: 100%;
            // img{
            //     width: 100%;
            //     height: 100%;
            //     object-fit: cover;
            // }
        }
        @include el-size(third, quarter){
            width:100%; 
        }
    }
    &--horizontal{
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .button--secondary{
            display: inline-flex;
            @include el-size(third, quarter, sixth){
                display: none;
            }
        }
        .button--tertiary{
            display: none;
            @include el-size(third, quarter, sixth){
                display: inline-flex;
                margin-top: 2rem;
                margin-bottom: 0;
            }
        }
        .key-project-block__summary{
            display: block;
        }
        @include phone-large-below{
            .key-project-block__details{
                display: none;
            }
            .button--secondary{
                display: none;
            }
            .button--tertiary{
                display: inline-flex;
                margin-top: 2rem;
                margin-bottom: 0;
            }
            .key-project-block__summary{
                display: none;
            }
        }
    }
    &__img{
        @extend %base-block__img;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include el-size(half, two-thirds, three-quarters, full){
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            @include tablet-large-below {
                border-top-right-radius: 5px;
            }
        }
        @include el-size(third, quarter, sixth){
            border-bottom-right-radius: 0;
        }
    }
    &__summary{
        padding-bottom:1.5rem;
        @include el-size(third, quarter, sixth){
            display: none;
        }
    }
    &__details{
        @include el-size(third, quarter, sixth){
            display:none;
        }
        position: relative;
        background-color: $navy-100;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: fit-content;
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin-bottom: 3.5rem;
        z-index: 1;
        @include tablet-large-below{
            padding-left: 2.5rem;
            transform: translateX(-2rem);
        }
        &::before{
            content:'';
            position: absolute;
            background-color: $navy-100;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform: translateX(-50%);
            z-index: -1;
            @include tablet-large-below{
                display: none;
            }
        }
        div {
            margin-right: 8rem;
            @include el-size(half, two-thirds){
                margin-right: 4rem;
                @include tablet-large-below{
                    margin-right: 8rem;
                }
            }
            span{
                @extend %body-copy-small;
                color: $navy-500;
            }
            p{
                @extend %body-copy;
                color: $navy;
                font-weight: 700; 
            }
        }

    }

    &.hide {
        display: none;
    }
}

/*** Project search results ****/
li.col--third .key-project-block__info--project .key-project-block__tag {
    // transform: translateY(-30%);
}


.secondary-nav {
    position: relative;
    flex-shrink: 0;
    transition: all .1s ease-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .active-meganav & {
        opacity:0;
    }
    .is-compressed.active-meganav & {
        @include desktop-small-above {
            display:none;
        }
    }
    @include desktop-small-below {
        flex-direction: column;
        justify-content: flex-start;
        padding: 2rem;
        .active-meganav & {
            opacity:1;
        }
    }

    &__main-menu {
        display: flex;
        list-style-type: none;
        margin: 0;
        @include desktop-small-below { 
            flex-direction: column;
            align-items: flex-start;
            background-color:#07234E;
            background:none; //change this once we actually show a secondary nav
            width: 100%;
            padding: 0.5rem 2.5rem;
            border-radius:6px;
            margin-bottom:4rem;
        }
    }

    &__list-item {
        position:relative;
        margin-left: 2rem;
        display:flex;
        .is-compressed & {
            margin-left: 0.7rem;
        }
        @include desktop-small-below {
            display:block;
            margin-left:0;
            width:100%;
            &:not(:last-child){
                border-bottom: 1px solid #5C7194;
            }
        }
        &[data--is-open] > .secondary-nav__link {
            @include desktop-small-below {
                .i-plus-thick {
                    opacity:0;
                }
                .i-minus-thick {
                    opacity:1;
                }
            }
        }
    }

    header:not(.mobile-menu-is-open) &__mobile-only-title {
        display:none;
    }

    &__menu {
        display:none;
        position:absolute;
        top:0;
        left:0;
        width:100%;
        z-index: 1;
        header:not(.mobile-menu-is-open) .secondary-nav__list-item:hover &,
        &[data--is-open] {
            display:block;
        }
        @include desktop-small-below {
            position:relative;
            .secondary-nav__list-item[data--is-open] > & {
                display:block;
            }
        }
    }

    &__links {
        position:relative;
        margin-top:4rem;
        background-color:#fff;
        border-radius:10px;
        box-shadow: $shadow-300;
        padding: 1.4rem 1.8rem 1.4rem 2.1rem;
        width: max-content;
        max-width: 25rem;
        left: 50%;
        transform: translateX(-50%);
        &::after {
            content:"";
            position:absolute;
            top:-10px;
            left:calc(50% - 15px);
            width: 0; 
            height: 0; 
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid #fff;
        }
        .secondary-nav__list-item  {
            margin-left:0;
        }
        @include desktop-small-below {
            left:0;
            transform: none;
            margin-top:0;
            max-width: none;
            width:100%;
            box-shadow:none;
            border-radius:0;
            background-color: #07234E;
            padding-top:0;
            &::after {
                display:none;
            }
        }
    }

    &__link {
        position: relative;
        font-family: $font-body;
        color: $navy-500;
        text-decoration: none;
        font-size: 1.6rem;
        font-weight: 700;
        padding: .5rem 0;
        display: flex;
        align-items: center;
        z-index: 2;

        .is-compressed .secondary-nav > ul > li > &:hover {
            &::after {
                content:"";
                width:100%;
                height:2px;
                background:$navy-400;
                position:absolute;
                bottom:0;
                left:0;
            }
        }

        header:not(.mobile-menu-is-open) &:hover .secondary-nav__link-title {
            text-decoration: underline;
        }

        [data-level="1"] > & {
            .header--dark:not(.active-meganav):not(.is-compressed) & {
                color:#fff;
            }  
        }
        [data-level='2'] & {
            font-weight:300;
            color:$navy-900;
            width:100%;
            @include desktop-small-below {
                color:#fff; 
                font-size:2.4rem;
            }
        } 
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg, i {
            margin-right: 0.6rem;
            color: $navy-500;
            path {
                fill: $navy-500;
            }
            .header--dark:not(.active-meganav):not(.is-compressed) [data-level="1"] > & {
                color:#fff;
                @include desktop-small-below {
                    color:$orange;
                }
                path {
                    fill:#fff;
                }
            }  
            header.mobile-menu-is-open [data-level="1"] > & { 
                @include desktop-small-below {
                    color:$orange;
                }
            }
        }
        .is-compressed & {
            i {
                font-size:2.5rem;
            }
        }
        .is-compressed &:hover & {
            &-title {
                // position:absolute;
                // top:36px;
                // left:calc(-70px + 1.3rem);
                // opacity:1;
                // min-width:140px;
                // width: max-content;
                // height:initial;
                // overflow:visible;
                // font-weight:400;
                // font-size:1.6rem;
                // background-color: $navy-300;
                // padding:0.6rem 1.2rem;
                // border-radius:5px;
                // text-align:center;
                // &::after {
                //     content:"";
                //     position:absolute;
                //     top:-10px;
                //     left:calc(50% - 15px);
                //     width: 0; 
                //     height: 0; 
                //     border-left: 15px solid transparent;
                //     border-right: 15px solid transparent;
                //     border-bottom: 15px solid $navy-300;
                // }
            }
        }
        @include desktop-small-below {
            justify-content: flex-start;
            font-size:2.6rem;
            color:#fff;
            padding:2rem 0;
            width:100%;
            svg {
                margin-right:1.2rem;
                path {
                    fill:#fff;
                }
            }
        }
    }

    &__link-title {

        .is-compressed .secondary-nav__list-item[data-level="1"] > a > & {
            opacity:0;
            width:0;
            height:0;
            overflow:hidden;
        }
        //TODO Should the text disappear on the non-scrolling version?
        // @include desktop-medium-below {
        //     opacity:0;
        //     width:0;
        //     height:0;
        //     overflow:hidden;
        // }
    }

    &__link-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        .is-compressed & svg {
            width: 28px;
            height: auto;
        }
    }

    &__link-title{
        line-height: 1.3;
    }

    &__link-arrow {
        font-size:2rem;
        color:$navy;
        margin-left: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @include desktop-small-below {
            display:none;
        }
    }

    &__contact {
        height:40px;
        border: 2px solid $navy;
        color:$navy;
        border-radius:5px;
        margin-left:1.5rem;
        display:flex;
        align-items: center;
        justify-content: flex-start;
        padding: .8rem 1rem;
        transition:.2s all ease-out;
        header:not(.mobile-menu-is-open) &:hover {
            cursor:pointer;
            @include desktop-medium-above {
                background: $navy;
                color:#fff;
            }
            @include desktop-medium-below {
                background-color: rgba(12, 62, 141, 0.25); 
                border:2px solid $navy;
                > div {
                    background:transparent;
                    color: $navy;
                }
            }
        }
        .header--dark:not(.is-compressed):not(.mobile-menu-is-open) & {
            color:#fff;
            border:2px solid #fff;
            &:hover {
                background:#fff;
                color:$navy;
            }
            @include desktop-medium-below {
                background: rgba(255, 255, 255, 0.25);
                border:0;
                &:hover {
                    color:#fff;
                    background: rgba(255, 255, 255, 0.5);
                    border:2px solid #fff;
                    > div {
                        color:#fff;
                    }
                }
            }
        }
        @include desktop-medium-below {
            header:not(.mobile-menu-is-open) & {
                background-color: rgba(12, 62, 141, 0.05);
                width:40px;
                border:0;
                padding: 1rem;
                justify-content: center;
            }
            header.mobile-menu-is-open & {
                color:#fff;
                border:1px solid #fff;
                padding: 1rem 3rem;
                > div {
                    margin-right:0.8rem;
                }
            }
        }
        // @include desktop-small-below {
        //     width:100%;
        //     margin:0;
        // }
    }

    &__contact-text {
        font-size:1.5rem;
        font-weight:700;
        .secondary-nav__contact:hover & {
            text-decoration:none;
        }
        
        @include desktop-medium-below {
            //Cannot just display:none because needs to be accessed by screen reader 
            position: absolute;
            height: 1px;
            width: 1px;
            overflow: hidden;
            clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
            clip: rect(1px, 1px, 1px, 1px);
            white-space: nowrap; /* added line */
        }
        @include desktop-small-below {
            display:inline-block;
            font-size:2.2rem;
            position:relative;
            height:initial;
            width:initial;
            overflow:initial;
            clip:initial;
            white-space: initial;
        }
    }

    &__contact-icon {
        display:none;
        @include desktop-medium-below {
            display:inline-block;
            font-size: 22px;
        }
    }

    &__search {
        height:40px;
        width:40px;
        display:flex;
        align-items: center;
        justify-content: center;
        border:0;
        border-radius: 5px;
        background-color: rgba(12, 62, 141, 0.05);
        color:$navy;
        margin-left: 0.5rem;
        transition:.2s all ease-out;
        margin-left:1rem; 
        &:hover {
            cursor:pointer;
            background-color: rgba(12, 62, 141, 0.25); 
            border:2px solid $navy;
        }
        i {
            font-size:22px;
        }
        .header--dark & {
            background:rgba(255,255,255,0.25);
            color:#fff; 
            &:hover { 
                background:rgba(255,255,255,0.5);
                border:2px solid #fff;
            }
        }
        .is-compressed & {
            color:$navy-600;
            margin-left:1rem;//TEMP while no contact button
        }
        .header--dark.is-compressed & {
            background-color: rgba(12, 62, 141, 0.05);
            color:$navy-600;
            &:hover { 
                background-color: rgba(12, 62, 141, 0.25);
                border:2px solid $navy;
            }
        }
        @include desktop-small-below {
            display:none;
        }
    }

    &__mobile-plus {
        display:none;
        position:relative;
        margin-left: auto;  
        i {
            color:$orange;
            font-size:2.4rem;
        }
        .i-plus-thick {
            position:relative;
        }
        .i-minus-thick {
            position:absolute;
            top:0;
            right:0;
            opacity:0;
        }
        @include desktop-small-below {
            display:flex;
        }
    }

    &__dropdown-chevron {
        border: 0;
        background: transparent;
        margin-left: 0.5rem;
        padding: 0;
        color:$navy;
        .is-compressed & {
            margin-left:-0.5rem;
            margin-right:0.5rem;
        }
        .header--dark:not(.is-compressed) & {
            color:#fff;
        }
        @include desktop-small-below {
            display:none;
        }
    }

    &__mobile-only {
        display:none;
        @include desktop-small-below {
            display:block;
        }
    }
}


.quote-block{
  padding-top:2rem;
  padding-right: 2rem;
  margin-top:5rem;
    p{
      @include el-size(half, two-thirds, three-quarters, full){
        @extend %body-copy-large;
      }
      @include el-size(third, quarter, sixth){
        @extend %body-copy;
      }
      color: $navy;
      margin-bottom: 4rem;
      position: relative;
      @include el-size(quarter){
        @include tablet-large-above{
          font-size: 1.7rem;
        }
      }
      @include el-size(full){
        max-width: 1050px;
      }
    }
    p::before{
      font-family: 'icoMoon';
      content: '\e921';
      color: $orange;
      position: absolute;
      font-size: 4rem;
      top: -3rem;
      left: 0;
      transform: translateY(-50%);
    }
}
  
.quote-block__author{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    position: relative;
    align-content:center;
    align-items:center;
    img{
      max-width:60px;
      width:100%;
      border-radius:50%;
    }
}
.quote-block__author-details{
  margin-left: 2rem;
  &.no-image {
    margin-left: 0;
  }
}
.quote-block__author-name{
    font-weight: 700;
    font-size: 2.2rem;
    margin-bottom: .5rem;
    display: block;
    color: $navy;
}
.quote-block__author-sub{
  @extend %body-copy-small;
  color: $navy-500;
  display: block;
  @include el-size(quarter, third){
    @extend %body-copy-xsmall;
  }
}
  
.quote-block--alternate{
  @extend %base-block;
  align-items: flex-start;
  padding: 3rem;
  margin: 2rem 0;
  @include el-size(half, two-thirds, three-quarters, full, third, quarter, sixth){
    flex-direction: column;
  }
  p{
    @include el-size(half, two-thirds, three-quarters, full){
      @extend %body-copy-large;
    }
    @include el-size(third, quarter, sixth){
      @extend %body-copy;
    }
    margin-top: 4rem;
  }
  .quote-block__author{
    flex-direction:row;
    .quote-block__author-name{
      margin-bottom:1rem;
    }
  }
}
  
.quote-block--settings{
    &-align{
      padding: 6rem 5.5rem;
      &-left{
        float:left;
        margin-right: 3rem;
        margin-bottom: 3rem;
      }
      &-right{
        float:right;
        margin-left: 3rem;
        margin-bottom: 3rem;
      }
    }
    &-size{
      &-small{
        padding:3.5rem;
        max-width:400px;
        @include tablet-small-below{
          float:none;
          max-width:100%;
        }
        p{
          @extend %body-copy;
          margin-bottom:1.8rem;
        }
        span{
          @extend %body-copy-xsmall;
        }
      }
      &-medium{
        padding:3.8rem;
        max-width:600px;
        @include tablet-large-below{
          float:none;
          max-width:100%;
        }
        p{
          font-size:2.2rem;
          margin-bottom:2rem;
        }
        span{
          @extend %body-copy-small;
        }
        
      }
      &-large{
        padding:4rem;
        max-width:800px;
        @include desktop-small-below{
          float:none;
          max-width:100%;
        }
        p{
          @extend %body-copy-large;
          margin-bottom:2.2rem;
        }
        span{
          @extend %body-copy;
        }
      }
    }
}
.icon-list-block{
  @extend %block-base;
  //display: flex;
  //flex-direction: column;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}


.icon-list-block-item{
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
    &__icon{
      margin-right: 1rem;
      position: relative;
      width: 4rem;
      height: 4rem;
      flex-shrink: 0;

      svg{
        width: 100%;
        height: 100%;
        *{
          fill: $orange;
        }
      }
      @include el-size(quarter){

      }
    }
    &__title{
      @extend %body-copy-large;
      line-height: 1.2;
    }
}
.home-explore {

    background-color: $navy-200;
    padding: 7.5rem 0 1rem;
    @include tablet-large-below {
        padding-bottom: 0;
    }
    @include phone-large-below {
        padding: 5rem 0 0 0;
    }

    &__title {
        @extend %heading-one;
        margin-bottom:1rem;
        @include phone-large-below {
            font-size: 3.5rem;
        }
    }

    &__slider-wrapper {
        position:relative;
        width:100%;
        max-width:1800px;
        margin:0 auto;
    }

    &__slider {
        display:flex;
        .slick-list {
            padding: 2rem 0 12rem 0;
            @include phone-large-below {
                padding: 1rem 0 12rem 0;
            }
        }
        .slick-track {
            display:flex !important;
            align-items: stretch;
        }
        .slick-slide {
            height:initial;
            > div {
                height: 100%;
            }
        }
    }

    &__controls-wrapper {
        position:absolute;
        bottom: 18rem;
        width:100%;
        z-index: 3;
        left: 0;
        right: 0;
        @include tablet-large-below {
            position: relative;
            top:-12rem;
            bottom:initial; 
            align-items: center;
            margin-bottom:-4rem;
        }
        @include phone-large-below {
            margin-bottom:-7rem;
        }
    }

    &__controls {
        position:relative;
        width:50%;
        display:flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 8rem 0 3rem;
        padding-left: clamp(2rem, 5vw, 8rem); 
        @include tablet-large-below { 
            padding: 0.6rem 1rem;
            background-color: $navy-200;
            border-radius:5px;
            justify-content: center;
            width: fit-content;
        }
    }


    &__slider-controls-left.button,
    &__slider-controls-right.button {
        background:#FFF;
        height:4.4rem;
        width:4.4rem;
        display:flex;
        justify-content: center;
        align-items: center;
        border:2px solid $navy;
        transition: all .2s ease-out;
        border-radius: .4rem;
        margin: 0;
        margin-left: 1.2rem;
        padding: 0;
        i {
            font-size:2.4rem;
        }
        &.slick-disabled {
            opacity:0.35;
            i::after {
                display:none;
            }
            &:hover {
                background:white;
                color:$navy;
                i::before {
                    transform:none;
                }
            }
        }

        @include tablet-large-below {
            background:none;
            border:0;
            padding: 0;
            margin:0;
            i::after {
                display:none;
            }
            .using-mouse &:focus,
            .using-mouse &:hover {
                background:none;
                color:$navy;
                i::before {
                    transform:none;
                }
            }
        }
    }

    &__slider-dots {
        position:relative;
        margin-right: auto;
        order:-1;

        @include tablet-large-below { 
            margin-right: 1rem;
            margin-left: 1rem;
            order:initial;
        }

        .slick-dots {
            display:flex;
            li {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &__slider-dot {
        border-radius: 100%;
        width:1.5rem;
        height:1.5rem;
        margin-right:1rem;
        margin-left:1rem;
        border:0;
        transition: all .2s ease-out;
        padding:0;
        background-color:#D4D4D4;
        &:hover {
            cursor:pointer;
            transform: scale(1.1);
        }
        .slick-active & {
            background-color:#3D65A4; 
        }
        body:not(.using-mouse) .slick-active & {
            &:focus {
                outline: 2px solid $orange;
            }
        }
        @include phone-large-below {
            width:10px;
            height:10px;
        }
    }
}

.explore-slide {

    display:flex !important;
    height:100%;

    > .wrapper {
        height:100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #fff;
        box-shadow: $shadow-100;
        border-radius: 5px;
        margin-top: 5rem;
        width: calc(50% + 5rem);
        padding: 8rem 10rem 12rem 8rem;
        min-height: 65rem;
        height:100%;

        @include desktop-medium-below {
            padding: 8rem 8rem 12rem 6rem;
        }
        @include tablet-large-below {
            z-index:2;
            width:100%;
            padding: 4rem;
            min-height: auto;
            margin-top:38rem;
            justify-content: flex-start;
        }
        @include phone-large-below {
            padding: 3.6rem 3rem 0 3rem;
            margin-top:23rem;
        }
    }

    &__tag{
		@extend %base-tag;
        margin-bottom:2.5rem;
        transition: all .2s cubic-bezier(.23, .65, .63, .99);
        transform:translateY(2rem);
        opacity:0;
	}

    &__title {
        @extend %heading-two;
        transition: all .2s cubic-bezier(.23, .65, .63, .99) .1s;
        transform:translateY(2rem);
        opacity:0;
    }

    &__desc {
        @extend %body-copy;
        color:$navy;
        transition: all .2s cubic-bezier(.23, .65, .63, .99) .2s;
        transform:translateY(2rem);
        opacity:0;
    }

    &__button {
        transition: all .2s cubic-bezier(.23, .65, .63, .99) .3s;
        transform:translateY(2rem);
        opacity:0;
    }

    &__img {
        position:absolute;
        right: 0;
        box-shadow: $shadow-100;
        border-radius: 5px 0 0 5px;
        overflow:hidden;
        margin-bottom: 4rem;
        height:calc(100% - 5rem);
        width:50%;
        z-index:1;

        @media (min-width: 1800px){
            border-radius: 5px;
            right: 3rem;
        }
    
        @include tablet-large-below {
            width: calc(100% - 20px);
            left: 10px;
            right: 10px;
            height:44rem;
            border-radius: 5px;
        }

        @include phone-large-below {
            height:29rem;
        }

        picture {
            display:block;
            width:100%;
            height:100%;
        }

        img {
            object-fit: cover; 
            width: 100%;
            height: 100%;
        }
    }
}


.explore-show-content {
    .explore-slide__tag,
    .explore-slide__title,
    .explore-slide__desc,
    .explore-slide__button {
        transform:translateY(0);
        opacity:1;
    }
}

.slide-in .explore-slide__img {
    animation: .5s forwards slideInImageLeft;
    @include tablet-large-below  {
        animation: .6s forwards slideInImageTop;
    }
}

.slide-out .explore-slide__img {
    animation: .5s forwards slideOutImageLeft;
    @include tablet-large-below  {
        animation: .6s forwards slideOutImageTop;
    }
}

//*************************** */
// Key Project Page Styles
//************************ */

// ** Page header styles **
.key-project-details {
    display: flex;
    position: absolute;
    bottom: 5rem;
    padding-right: 16rem;
    @include column(9);
    @include desktop-medium-below {
        @include column(8.04);
        flex-wrap: wrap;
    }
    @include desktop-small-below {
        @include column(12);
        position: static;
        margin: 5rem 0;
        padding-right: 0;
    }
    @include phone-large-below {
        position: static;
        margin-top: 10rem;
    }

    .key-project-detail {
        display:flex;
        flex-direction: column;
        padding-right: 5rem;
        @include column(2.4);
        @include desktop-medium-below {
            @include column(4);
            margin-bottom: 3rem;
        }
        @include desktop-small-below {
            @include column(4);
        }
        @include tablet-below {
            @include column(6);
        }
        @include phone-large-below {
            @include column(6);
        }

        &__label {
            font-size: 1.8rem;
            color: $white;
            margin-bottom: 1rem;
        }
        &__info {
            font-size: 2.6rem;
            color: $white;
            font-weight: 700;
        }
    }
}

// ** Sidebar styles **
.sidebar-panel-project-photos {
    @extend %body-copy-small;
    color: $white;
    @include desktop-small-below {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-right: 3rem;
        &.sidebar-panel {
            @include column(6);
        }
    }
    @include tablet-below {
        &.sidebar-panel {
            @include column(7.2);
        }
    }
    @include tablet-small-below {
        &.sidebar-panel {
            @include column(12);
        }
    }

    // .slick-list {
    //     width: 100%;
    // }
    // .slick-track {
    //     display:flex;
    //     justify-content: space-between;
    // }
    &__title {
        font-family: $font-heading;
    }
    &__container {
        margin-top: 1rem;
        display: flex;
        @include tablet-small-below {
            justify-content: flex-start;
        }

        .project-photo {
            @include column(3);
            padding: 0;
            border: none;
            background: none;
            cursor: pointer;
            position: relative;
            min-width: 0;
            margin-right: .5rem;
            @include tablet-small-below {
                margin-right: 1rem;
            }

            &:last-of-type {
                margin-right: 0;
            }

            i {
                width: 75%;
                position:absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                transition: transform 0.2s ease;
                svg {
                    width: 100%;
                }
            }
            &:hover > i {
                transform: translateY(-50%) translateX(-50%) scale(1.1);
            } 

            img {
                border-radius: 5px;
                width: 100%;
            }
        }
    }
}
.sidebar-panel-project-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include desktop-small-below {
        &.sidebar-panel {
            @include column(6);
        }
    }
    @include tablet-below {
        &.sidebar-panel {
            @include column(4.8);
        }
    }
    @include tablet-small-below {
        &.sidebar-panel {
            @include column(12);
        }
    }

    .sidebar-panel__title {
        font-size: 2.2rem;
        display: block;
    }
    .sidebar-panel-project-cta__button {
        width: 100%;
        text-align: center;
        justify-content: center;
        margin-right: 0;
        @include desktop-small-below {
            width: auto;
        }
    }
}

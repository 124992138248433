.download-type-filter {
    margin-bottom: 5rem;
    @include phone-large-below {
        margin-top: 2rem;
    }

    .download-type-filter__title {
        @extend %body-copy;
        color: $navy;
        margin-bottom: 1.8rem;
    }
    
    .button.download-type-button {
        &--active {
            background-color: #0C3E8D;
            color: #fff;
        }
    }
}

.year-timeline {
    margin: 5rem 0 2rem;
    padding-right: 1rem;
    @include desktop-small-below {
        padding-right: 0;
    }

    fieldset {
        display: flex;
        @include desktop-small-below {
            flex-wrap: wrap;
        }
    }

    .year-timeline__years {
        display:flex;
        flex-direction:row;
        align-items:center;
        flex-wrap:wrap;
    }

    .year-timeline__year {
        width: 100%;
        position:relative;
        margin-top: -1px;
        margin-left: -1px;

        input[type='radio'] {
            -webkit-appearance:none;
            margin: 0;
        }
        input:checked {
            background-color: $navy;
            ~ label {
                color: $white;
            }
        }
        label {
            cursor: pointer;
            font-size: 2.4rem;
            font-family: $font-body;
            color: $navy;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            padding-bottom: 5px;
            @include desktop-large-below {
                font-size: 2rem;
            }
            @media(max-width: 450px) {
                padding-bottom: 2px;
            }
        }

        @include desktop-small-below {
            width: 20%;
            label {
                padding-bottom: 0;
            }
        }

        &--disabled {
            display:none; 
        }
    }

    &__filter-label {
        font-size:2rem;
        color:$navy;
        font-weight:400;
        flex-shrink: 0;
        margin-right: 2rem;
    }

    &--technical-downloads {
        margin-top:0;

        legend {
            @extend %body-copy;
            color: $navy;
            margin-bottom: 1.8rem;
        }
        .year-timeline__years {
            width:100%;
        }

        .year-timeline__year {
            @include column(3);
        }
    }
}


.year-timeline__year-btn {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: none;
    border: 1px solid $navy;
    padding: 3rem;
    transition: 0.3s ease;
    border-radius: 0; // to override safari computed styles
    @media(max-width: 450px) {
        padding: 2.8rem 2.5rem;
    }

    &:hover, &:focus {
        background: $navy;
        & ~ label {
            color: $white;
        }
    }

    &:disabled {
        cursor: default;
        background: $navy-100;
        &:hover, &:focus {
            background: $navy-100;
            & ~ label {
                color: $navy;
            }
        }
        ~ label {
            cursor: default;
            opacity: .4;
        }
    }
}
.bio-block {
    @extend %base-block;
    padding: 4rem 2.5rem 2rem 2.5rem;
    align-items: center;
    text-align: center;
    @include el-size(full, three-quarters, two-thirds, half){
        //@include column(4);
        flex-direction: column;
    }
    &__img{
        //-webkit-clip-path: circle(42.9% at 50% 50%);
        //clip-path: circle(42.9% at 50% 50%);
        border-radius: 100%;
        overflow: hidden;
        margin-bottom: 2rem;
        img{
            max-width: 180px;
            max-height:180px;
            width: 100%;
            height: 100%;
        }
    }
    &__title{
        margin-bottom: 1rem;
    }
    &__sub-title{
        margin-bottom: 1rem;
        color: $navy-900;
        @extend %heading-five;
        @include el-size(quarter){
            font-size: 2rem;

        }
        @include phone-large-below{
            font-size: 2.5rem;
        }
    }
    &__pillar{
        margin-bottom: 2rem;
        color: $navy-600;
        @extend %heading-five;
        @include el-size(quarter){
            font-size: 1.7rem;

        }
        @include phone-large-below{
            font-size: 2.1rem;
        }
    }
    .button {
        margin-right: 0;
        margin-top: auto;
    }  
}

.page-header-cap-solution{
  @include phone-large-above{
    min-height: 70vh;
  }
  background-color: #000;
  display: flex;
  overflow: hidden;
  position: relative;

  &--blue-wash{
    background-color: darken($navy, 20%);
  }

  &--blue-wash &{
    &__background-image{
      opacity: .2;
    }
  }


  .wrapper{
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__background-image, &__background-video{
    position: absolute;
    object-fit: cover;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .3;
  }

  &__primary{
    @include column(9);

    display: flex;
    flex-direction: column;
    position: relative;

    @include desktop-small-below{
      @include column(12);
    }
    @include phone-large-below{
      &:not(.page-header-cap-solution__primary--level-one){
        margin-bottom: 10rem;
      }
    }

  }

  &__title-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20rem 0 10rem 0;
    padding-right: 5rem;
    flex-grow: 1;
    @include desktop-small-below{
      margin-bottom: 0rem;
    }
    @include tablet-below{
      padding-right: 0;
    }
  }

  &__secondary{

  }

  &__centered{
    @include column(12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15rem 0;
    @include phone-large-below{
      margin-bottom: 10rem;
    }

    .page-header__summary {
      text-align: center;
    }
  }

  &--dark{
    color: #fff;
  }
  &--dark &{
    &__title{
      color: #fff;
    }
    &__summary{
      color: #fff;
    }
  }

  &__title{
    @extend %heading-one;
  }

  //&--has-sidebar{
  //  .sidebar{
  //    padding-bottom: 5rem;
  //  }
  //}
}

.page-header-cap-solution-scroll-buttons{
  display: flex;
  flex-wrap: wrap;
  justify-self: flex-end;
  margin-bottom: 3rem;
  //position: absolute;
  //bottom: 3rem;
  .button{
    margin-right: 2rem;
  }
}



.page-header-cap-sol-jump-links{
  margin: 2rem 4rem 4rem 0;
  background-color: rgba(240, 243, 248, 0.08);
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  @include column(11.5);
  @include desktop-small-below{
    @include column(12);
  }
  &__title{
    font-weight: 700;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 1.5rem;
    padding: .7rem 1.5rem;
    border-bottom-right-radius: 5px;
    float: left;
    @extend %body-copy-small;
    @include phone-large-below{
      width: 100%;
      font-size: 2rem;
    }
  }


  ul{
    padding: 2rem 3rem 0rem 2.5rem;
    column-count: 3;
    column-gap: 4rem;
    width: 100%;
    @include tablet-below{
      column-count: 2;
    }
    @include phone-large-below{
      column-count: 1;
    }
    @extend %list-simple-small;
  }





  ul li{
    padding-bottom: 2rem;
    margin-bottom: 0;
    &:nth-child(3n) {
      break-after: always;
    }
  }
  button{
    margin: 0 2rem 0rem 0;
    padding: 0;
    color: #fff;
    font-family: $font-body;
    background: transparent;
    border: 0;
    font-weight: 700;
    font-size: 1.6rem;
    cursor: pointer;
    text-align: left;
    &:hover, &:focus{
      text-decoration: underline;
    }
    @include phone-large-below{
      font-size: 2rem;
    }
  }

}

.page-teaser-row{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5rem;
  &__image{
    max-width: 100%;
    border-radius: 5px;
   // order: 1;
  }
  &__primary{
    @include column(8.5);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 5rem 0;
    @include desktop-medium-below{
      @include column(7);
      //order: 3;
    }
    @include desktop-small-below{
      @include column(12);
    }
  }
  &__secondary{
    padding: 0 3rem;
    @include column(3.5);
    @include desktop-medium-below{
      @include column(5);
    //  order: 2;
    }
    @include desktop-small-below{
      @include column(12);
      flex-direction: row;
      align-items: space-between;
      padding: 0 .5rem;
      width: 100%;
    //  max-width: 45rem;
    }
    @include phone-large-below{
      //max-width: none;
      flex-direction: column;
    }

    .sidebar-panel{
      @include desktop-small-below{
        //max-width: 45rem;
        @include column(5.8);
      }
      @include phone-large-below{
        @include column(12);
      }
    }
    &.sidebar{
      margin-top: -12rem;
      justify-content: flex-start;
      @include desktop-medium-below{

      }
      @include desktop-small-below{
        margin-top: 0;
        justify-content: space-between;
      }
    }
  }
}

.page-teaser-row-children{
  background-color: #fff;
  border: 2px solid $navy-200;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4rem;
  &__title{
    font-weight: 700;
    background-color: $navy-200;
    padding: 1rem 2rem;
    color: $navy-900;
    border-bottom-right-radius: 5px;
    @extend %body-copy;
    @include phone-large-below{
      width: 100%;
    }
  }
  ul{
    padding: 1rem 3rem 3rem 4rem;
    column-count: 3;
    column-gap: 4rem;
    @include tablet-below{
      column-count: 2;
    }
    @include phone-large-below{
      column-count: 1;
    }
    @extend %list-simple-small;
  }
  ul li{
    &:nth-child(3n) {
      break-after: always;
    }
  }
  a{
    color: $navy-900;
  }
}
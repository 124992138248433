$divider-padding-list:(
    (none, 0rem),
    //(default,4rem),
    (small, 2rem),
    (medium, 7rem),
    (large, 10rem)
);
@mixin dividerPaddingSizes($direction, $sizes){
	@each $size in $sizes {
		&-#{nth($size, 1)}{
			padding-#{$direction}:#{nth($size, 2)};
		}
	}
}

.divider-row{
	display:flex;
	flex-direction:column;
	justify-content: center;
	position:relative;
	align-items:flex-start;
	width:100%;

	z-index: 0;
	&:after{
		content:"";
		position: absolute;
		display:inline-block;
		width:100%;
		height:2px;
		background:$navy-400;
	}
	&--pad-top{
		@include dividerPaddingSizes("top",$divider-padding-list);
	}
	&--pad-bottom{
		@include dividerPaddingSizes("bottom",$divider-padding-list);
	}

}
.cta-block{
  @extend %block-base;
  box-shadow: $shadow-100;
  background-color: #fff;
  padding: 4rem;
  margin-bottom: 2rem;

  &__title{
    margin: 0;
    @include el-size(two-thirds, three-quarters, full){
        @extend %heading-three;
        margin-bottom: 0;
        @include phone-large-below{
            font-size: 2.9rem;
        }
    }
    @include el-size(half, third, quarter, sixth){
        @extend %heading-five;
        margin-bottom: 0;
        @include phone-large-below{
          font-size: 2.9rem;
      }
    }
  }

  &__heading{
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    @include el-size(quarter){
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__icon{
    margin-right: 2rem;
    position: relative;
    width: 4rem;
    height: 4rem;

    svg{
      width: 100%;
      height: 100%;
      *{
        fill: $orange;
      }
    }
    @include el-size(quarter){
      width: 5rem;
      height: 5rem;
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }

  &__description {
    p {
      margin-bottom:2rem;
    }
  }

  &__button {
    .button {
      margin-bottom:0;
    }
  }


  &--promoted {
    background-color:$navy-200;
    box-shadow:none;
  }

}


.home-projects {

    background-color:$navy-300;
    padding: 8rem 0 8rem 0;

    @include phone-large-below {
        padding-bottom: 3rem;
    }

    &__wrapper {
        padding-top:8rem;
        @include tablet-below {
            padding-top:0;
        }
    }

    &__slider-wrapper {
        padding:0;
    }

    &__bg-torus {
        position: absolute;
        pointer-events: none;
        z-index: 0;
        left:0;
        top:0;
        @include tablet-below {
            left:-10%;
            top:-50%;
        }
        @include phone-large-below {
            left: -37%;
            top: -69%;
            transform: scale(0.7);
        }
    }   

    &__content {
        display:flex;
        justify-content: space-between;
        margin-bottom: 5rem;
        padding: 0 3rem;
        @include tablet-below {
            flex-direction: column;
            justify-content: flex-start;
        }
        @include phone-large-below {
            margin-bottom: 0;
            padding:0;
        }
    }

    &__title {
        line-height:130%;
        @extend %heading-one;
        max-width: 80rem;
        margin-right: 4vw;
        strong {
            color:$orange;
        }
        flex:4;
        @include tablet-below {
            // text-align: center;
            line-height:120%;
            margin-right: 0;
        }
        @include phone-large-below {
            font-size:3.4rem;
        }
    }

    &__desc {
        @extend %body-copy-large;
        padding: 3rem 2rem 0 2rem;
        color:$navy;
        flex:3;
        @include tablet-below {
            // text-align: center;
            padding:0;
        }
        @include phone-large-below {
            font-size:2.3rem;
        }
    }

    .slick-slider {
        z-index: 1;
        &--overflow {
            .slick-slide {
                padding: 0 3rem;
            }
        }
        @include desktop-small-below {
            pointer-events: none;
        }
    }

    .slick-list {
        padding: 3rem 3rem 5rem; //so shadows dont get cut 
        @include desktop-small-below {
            padding: 3rem 4rem 5rem;
            pointer-events: none;
            * {
                pointer-events: auto;
            }
        }
        @include phone-large-below {
            padding: 3rem 0 5rem;
         
        }
    }

    .slick-track {
        display: flex;
        align-items: stretch;
    }

    .slick-slide {
        height:initial; //using flex stretch
        padding: 0 2.4rem;
        @include desktop-small-below {
            padding: 0 2rem;
        }
        > div {
            height:100%;
        }
    }

    &__projects-slide {
        height:100%;
    }

    &__controls {
        display:flex;
        align-items: center;
        position:absolute;
        left:6rem;
        bottom:4rem;
        border-radius: 5px;
        width: fit-content;
        @include phone-large-below {
            position:relative;
            top: -7rem;
            left:0;
            right:0;
            bottom:initial;
            margin: 0 auto;
            padding: 1rem;
            background-color:$navy-100;
        }
    }

    &__controls-left,
    &__controls-right {
        background: none;
        color:$navy;
        border:0;
        box-shadow: 0;
        font-size:2.2rem;
        i {
            position: relative;
            top: 2px;
        }
        &:hover {
            background: $navy-100;
            cursor:pointer;
        }
        &.slick-disabled {
            opacity:0.35;
        }
    }

    .slick-dots {
        display:flex;
        align-items: center;
        li {
            display:flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__dot {
        border-radius: 100%;
        width:13px;
        height:13px;
        padding:0;
        margin: 0 1rem;
        border:0;
        transition: all .2s ease-out;
        background-color:#D4D4D4;
        &:hover {
            cursor:pointer;
            transform: scale(1.2);
        }
        .slick-active & {
            background-color:#3D65A4; 
        }
        body:not(.using-mouse) .slick-active & {
            &:focus {
                outline: 2px solid $orange;
            }
        }
        @include phone-large-below {
            width:10px;
            height:10px;
        }
    }

    &__buttons {
        display:flex;
        width:100%;
        justify-content:flex-end;
        padding: 0 5rem;
        .button {
            width:fit-content;
            margin:0;
        }
        @include phone-large-below {
            justify-content: flex-start;
            padding: 0 2rem;
            margin-top: -3rem;
        }
    }
}
// ** Page header styles ** 
.view-page-header-full {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    @include phone-large-below {
        min-height: 0;
    }

    &.view-page-header-full--keyProject {
        @include tablet-small-below {
            margin-bottom: 6rem;
        }
    }

    &.view-page-header-full--technicalArticle,
    &.view-page-header-full--event {
        @include tablet-small-below {
            min-height: 0;
        }
    }

    &.view-page-header-full--event {
        min-height: 90vh;
        margin-bottom: 8rem;
        @include phone-large-below {
            margin-bottom: 10rem;
        }
        .wrapper {
            min-height: 90vh;
        }
    }

    .page-header__background-image {
        position: absolute;
        z-index: -1;
        min-height: 100%;
    }

    &--dark {
        background-color:rgba(0, 0, 0, 0.55);
        
        .page-header__title, .page-header__summary {
            color: $white;
        }
    }

    .wrapper {
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        @include desktop-small-below {
            flex-wrap: wrap;
        }
    }

    &.view-page-header-full--technicalArticle .wrapper,
    &.view-page-header-full--event .wrapper {
        @include tablet-small-below {
            min-height: 65rem;
            flex-direction: column;
        }
    }

    .page-header__background-image {
        height: 100%;
        width: 100%;
        overflow: hidden;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            min-height: 100%;
            min-width: 100%;
        }
    }

    .page-header__primary {
        @include column(9);
        margin: 20rem 0;
        padding-right: 5rem;
        @include desktop-medium-below {
            @include column(9);
        }
        @include desktop-small-below {
            @include column(9);
            margin: 22rem 0 3rem 0;
            padding-right: 0;
        }
        @include tablet-small-below {
            margin-bottom: 0;
        }
        @include phone-large-below {
            @include column(12);
        }
    }

    &.view-page-header-full--technicalArticle .page-header__primary,
    &.view-page-header-full--event .page-header__primary {
        padding-right: 0;
        margin: 15rem 0;

        @include tablet-small-below {
            margin-top: 8.5rem;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 15rem 0;
            @include column(12);
            
            .page-header__summary {
                text-align: center;
            }
        }
    }
    &.view-page-header-full--event .page-header__primary {
        @include phone-large-below {
            padding-bottom: 0;
        }
    }

    .page-header__pre-title {
        color: $navy-400;
        font-size: 2rem;
        font-weight: 700;
    }

    .page-header__title {
        margin-top: 3rem;
        p {
            @extend %heading-one;
            color: $white;
        }
    }

    &.view-page-header-full--technicalArticle .page-header__title, 
    &.view-page-header-full--event .page-header__title {
        @include tablet-small-below {
            margin-top: 0;
            text-align: center;
        }
    }

    .sidebar {
        margin-top: auto;
        @include desktop-small-below {
            flex-direction: row;
        }
        @include tablet-small-below {
            flex-direction: column;
            margin-bottom: -8rem;
        }
    }
}


// Event details block on Event view pages
.page-header__event-details {
    @include column(8.3);
    position: absolute;
    bottom: -8rem;
    height: 16rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: $shadow-300;
    border-radius: 5px;
    @include desktop-xlarge-below {
        @include column(7.6);
    }
    @include desktop-medium-below {
        @include column(6.8);
    }
    @include desktop-small-below {
        width: calc(100% - 6rem);
    }
    @include tablet-small-below {
        margin-bottom: 0;
    }
    @include phone-large-below {
        @include column(12);
        position: relative;
        flex-direction: column;
        height: auto;
    }

    color: $navy;
    background: $white;

    .event-detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 3rem;
        height: 100%;
        width: 100%;
        border-right: 2px solid #EDEDED;
        @include phone-large-below {
            border-right: none;
            border-bottom: 2px solid #EDEDED;
            padding: 3rem 0;
        }

        &.event-detail--date {
            background: $navy;
            border-right: none;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 5px;
            @include phone-large-below {
                border-bottom: none;
                border-bottom-left-radius: 0;
                border-top-right-radius: 3px;
            }

            span {
                color: $white;
            }
            .event-detail__title {
                color: $white;
            }
        }
        &.event-detail--booth {
            border-right: none;
            @include phone-large-below {
                border-bottom: none;
            }
        }
        
        &__title, &__detail {
            text-align: center;
        }
        &__title {
            @extend %heading-base;
            font-size: 1.8rem;
            color: $navy-500;
            //opacity: .8;
        }
        &__detail {
            font-size: 2.6rem;
            font-weight: 700;
            margin-top: .5rem;
            @include desktop-medium-below {
                font-size: 2rem;
            }
            @include phone-large-below {
                font-size: 2.6rem;
            }
        }
    }
}
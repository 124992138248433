body .modal-backdrop {
    background:#08306F;
    &.show {
        opacity: .7;
    }
}

body.is-searching {
    .modal-backdrop {
        background:$navy-100;
        &.show {
            opacity: 1;
        }
    }
}

.search-modal {

    .modal-dialog {
        width:90%;
        max-width:1800px;
        height:100%;
        padding-top:5rem;
        @include phone-large-below {
            width:100%;
            margin:0;
            padding: 2rem 1rem;
        }
    }

    .modal-content {
        height:100%;
        background:none;
        border:none;
        padding:0 1rem;
        box-shadow:none;
    }

    .modal-header {
        background:none;
        border:0;
    }

    .modal-header__text {
        color:$navy;
        font-size:2.4rem;
        font-weight:400;
        @include desktop-small-below {
            color:$navy-900;
        }
    }

    input {
        position:relative;
        width:100%; 
        padding:3rem 3.5rem;
        border:0;
        border-radius:5px;
        background:none;
        color:$navy-500;
        font-size: 2.6rem;
        background-color:#fff;
        //fix chrome bg bug
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1);
        &::-webkit-search-cancel-button {
            display:none;
        }
        // transition: padding .2s ease-out;
        &::placeholder {
            font-size: 2.6rem;
        }
        // &:placeholder-shown {
        //     border-bottom: 2px solid #000;
        // }
        // &:focus {
        //     border:none;
        //     padding-left:2.1rem;
        // }
        @include desktop-small-below {
            padding: 2rem 2rem 2rem 6rem;
        }
    }

    &__close {
        background: none;
        border:0;
        padding:0;
        margin:0;
        color:$navy-500;
        i {
            font-size:3rem;
        }
        &:hover {
            cursor:pointer;
        }
        @include desktop-small-below {
            display:none;
        }
    }

    &__search-wrapper {
        position:relative;
        @include desktop-small-below {
            display:flex;
            align-items: center;
            width:100%;
            // display:none;
            // .is-searching & {
            //     display:flex;
            // }
        }
    }

    &__input-wrapper {
        position:relative;
        box-shadow:$shadow-300;
        width:100%;
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 3rem;
        i {
            font-size:3.6rem;
            color:$navy-400;
        }
        @include desktop-small-below {
            left:10px;
            right:initial;
            i {
                color:$navy;
            }
        }
    }

    &__mobile-filter-toggle {
        display:none;
        border:none;
        box-shadow:$shadow-300;
        border-radius:5px;
        background-color: #fff;
        color:$navy;
        margin-left:2rem;
        padding:1.5rem;
        flex-shrink:0;
        align-items:center;
        justify-content: center;
        transition: all .2s ease-in-out;
        &:hover {
            cursor: pointer;
        }
        i {
            font-size:4rem;
            color:$navy;
        }

        &--active {
            background-color:$navy;
            i {
                color:#fff;
            }
        }
        @include desktop-small-below {
            display:flex;
        }
    }

    &__mobile-filters-warning {
        display:none;
        font-size:2rem;
        color:$navy-600;
        padding: 3rem 0;
        text-align: right;
        &--active {
            display:block;
        }
    }
}

#query-container.show-filters {
    display: flex;
    flex-direction: column;
    padding-top:4rem;
}

.search-results {
    &__list {
        padding-bottom: 10rem;
        *, *:hover {
            text-decoration:none;
        }
    }
    &__title {
        margin: 4.5rem 0 3rem;
        display: block;
        font-size: 2rem;
        color: $navy-500;
        @include phone-large-below {
            line-height:1.3;
        }
    }

    &__total {
        font-weight:700;
        color: $navy-500;
    }
}

.search-filters {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom:4.5rem;

    @include desktop-small-below {
        overflow:hidden;
        height:0;
        position:relative;
        margin-bottom:0;
        &::before {
            width:100vh;
            left:50%;
            transform:translateX(-50%);
            background-color:$navy-200;
            height:100%;
            content:"";
            position:absolute;
            top:0;
            z-index: -1;
        }
        .show-filters & {
            height:auto;
            order:-1;
            margin-bottom:0;
            overflow:initial;
            padding: 2rem 0;
        }
    }

    &__types-group {
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        justify-content: flex-start;
        align-items: center;
    }

    &__filter-li {
        .button {
            margin-right:1.2rem;
            margin-bottom: 1.2rem;
            &.active-search-filter {
                background-color:$navy;
                color:#fff;
            }
        }
    }

    &__filter {
        background:none;
    }

    &__prompt {
        font-size:2rem;
        color:$navy-900;
        margin-right: 2rem;
    }

    &__sort-dropdown {
        display:flex;
        align-items: center;
    }

    &__sort-select-wrapper {
        position:relative;
        background-color:#fff;
        box-shadow:$shadow-300;
        border-radius:5px;
        padding:5px;
        &:after {
            position: absolute;
            right: 15px;
            top: 50%;
            transform:translateY(-50%);
            font-family: icomoon;
            font-style: normal;
            font-weight: 400;
            content: '\e90d';
            color:$navy;
            font-size: 14px;
            pointer-events: none;
        }
    }  

    &__sort-select {
        position:relative;
        margin: 0;      
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline:none;
        background: none;
        cursor: pointer;
        &:focus {
            outline: 2px solid $orange;
            outline-offset: 5px;
        }
        padding: 1.4rem 4rem 1.4rem 2rem;
        color:$navy;
        font-size:1.8rem;
        font-weight:400;
        border:0;
    } 
}

@include desktop-small-below {
    #search-modal.show {
        top:70px;
    }
}

.search-result {
    //margin: 1rem 0;
    border-bottom: 1px solid $navy-300;

    @include desktop-small-below {
        box-shadow: 0px 17px 80px rgb(94 121 154 / 7%), 0px 7.10219px 33.4221px rgb(94 121 154 / 5%), 0px 3.79717px 17.869px rgb(94 121 154 / 4%), 0px 2.12866px 10.0172px rgb(94 121 154 / 4%), 0px 1.13052px 5.32008px rgb(94 121 154 / 3%), 0px 0.470434px 2.21381px rgb(94 121 154 / 2%);
        border-radius:5px;
        background-color: #fff;
        padding: 0 2rem;
        margin-bottom: 2rem;
    }

    a {
        display: block;
        padding: 2rem 0;
        //padding-top: 2rem;
        position: relative;
        &::before{
            content: '';
            display: block;
            position: absolute;
            left: -1.5rem;
            top: 0;
            height: 100%;
            width: calc(100% + 3rem);
            background-color: $navy-200;
            opacity: 0;
            z-index: -1;
            transition: opacity .2s ease-in-out;
            border-radius: 5px;
        }
        &:hover{
            &::before{
                opacity: 1;
            }
        }
    }


    &__type {
        font-weight:400;
        color:$navy-600;
        font-size:1.6rem;
        @include phone-large-below {
            display:none;
        }
        &--mobile {
            display:none;
            @include phone-large-below {
                display:block;
            }
        }
        &--always {
            display:block;
        }
    }

    &__title {
        color: $navy;
        font-size:2.4rem;
        font-weight:700;
    }

    &__crumbs {
        font-size: 1.6rem;
        font-weight:400;
        text-decoration:none;
        color: $navy-600;
        margin-top:1rem;
        span:not(:last-child):after {
            position: relative;
            top: .05rem;
            display: inline-block;
            font-family: icomoon;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            content: "\e90f";
            color: $navy-400;
            font-size: 1.4rem;
            margin: 0 .5rem 0 1rem;
        }
    }

    &__summary {
        margin:1rem 0;
        font-weight:400;
        color:$navy;
        @include desktop-small-below {
            color:$navy-900;
        }
    }

    &__summary *,
    .search-result > p,
    .search-result > p > a {
        font-size: 1.8rem;
        color:#000;
        font-weight:400;
        margin:1rem 0;
    }
    
    &__image-row {
        .search-result & {
            display:flex;
            flex-direction:row;
            align-items: center;
            height:100%;
            @include phone-large-below {
                // flex-direction: column;
                // align-items: flex-start;
            }
        }
    }

    &__image {
        display:inline-block;
        margin-right:2rem;
        height:135px;
        width:135px;
        flex-shrink: 0;
        img {
            width:100%;
            height:100%;
            object-fit: cover;
            border-radius: 5px;
        }
        @include phone-large-below {
            height:100px;
            width:70px;
        }
    }

    &--download {
        .search-result__image {
            display: inline-flex;
            justify-content: center;
            img {
                object-fit: contain;
            }
            @include phone-large-below {
                height:100px;
                width:70px;
            }
        }
    }

    &__ancillary {
        @include phone-large-below {
            display:none;
        }
    } 

    &__non-image-content {
        display:inline-block;
    }

    &--hide {
        display:none;
    }

    &--projects {
        display:flex;
    }



}


.search-panel__loading{
    color:#000;
    font-size: 2rem;
    position: fixed;
    left: calc(50% - 50px);
    top: 30%;
    display: none;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $navy-400;
    border-color: $navy-400 transparent $navy-400 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  } 
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  





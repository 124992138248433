
//====MODAL=====//
.general-contact-modal{

  &__secondary {
    margin-bottom: 3rem;
    > span{
      @extend %body-copy-large;
      color: $navy-900;
      margin-bottom: 2rem;
      display: block;
    }
  }
  &__title-container{
    display: flex;
    align-items: center;

    img{
      max-width: 9rem;
      margin-right: 2rem;
    }
  }

  &__name{
    @extend %heading-two;
    margin-bottom: .25rem;
  }

  &__title{
    @extend %body-copy;
    color: $navy-500;
    margin-bottom: 0;
  }



  &__primary{

  }
}

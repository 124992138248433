
//====BIO MODAL=====//
//#bio-modal{
//    .modal-dialog{
//        max-width: 1570px;
//        padding: 4rem;
//    }
//}
.bio-modal {

  @include tablet-below{
    padding: 6rem;
  }
  @include phone-large-below {
    padding: 2rem;
  }
  &__contact-info {
    display: flex;
    @include tablet-below {
      flex-direction: column;
    }
    span.bio-modal__description {
      //color:$navy-500;
      //font-size: 18px;
      //font-weight: 400;
      //margin-bottom: 3rem;
      p{
        @extend %body-copy-small;
      }
    }
    @include tablet-below{
      .bio-modal__contact .button {
        margin-right: .5rem;
      }
    }
  }

  &__secondary{
    .bio-modal__modal-img{//take out img
      width: 100%;
    }
    @include tablet-below {
      margin-bottom: 2rem;
    }
  }
  &__primary{
    padding-left: 4rem;
    @include tablet-below{
      padding-left: 0;
    }
    .bio-modal__title{
      margin-bottom: 1rem;
      @extend %heading-two;
    }
  }
  &__role{
    @extend %body-copy-large;
    margin-bottom: 1.5rem;
    @include phone-large-below {
      line-height:1.3;
    }
 
  }


}
%button-base{
    display: inline-block;
    cursor: pointer;
    padding:1.4rem 2rem;
    font-size:1.7rem;
    font-weight:700;
    font-family: $font-body;
    border:2px solid transparent;
    border-radius:.5rem;
    position:relative;
    text-decoration:none;
    margin-right:2rem;
    margin-bottom: 2rem;
    line-height: 1;
   // transition:all 0.15s ease-in-out;
    &:hover, &:focus{
      text-decoration:none;
    //  transition:all 0.3s ease-in-out;
    }
  i{
 //   transition:all 0.3s ease-in-out;
    font-size:2.2rem;
    position: absolute;

  }
}

.button, .button .link-text{
  transition:  background-color 0.15s cubic-bezier(.23, .65, .63, .99), color 0.15s cubic-bezier(.23, .65, .63, .99), border-color 0.15s cubic-bezier(.23, .65, .63, .99);
}

.button{
  @extend %button-base;
  display: inline-flex;
  align-content:center;
  align-items: center;
  flex-direction: row;
  flex-wrap:wrap;
  background: transparent;
  text-align: left;
  .link-text{

  }
  &--size-regular {
    @include phone-large-below {
      padding:1.6rem 2.4rem;
      font-size:2.2rem;
      i{
        font-size:3rem;
      }
      &.button--icon-left{
        padding-left:6rem;
      }
      &.button--icon-right{
        padding-right:6rem;
      }
    }
  }
  &--size-large{
    padding:1.6rem 2.4rem;
    font-size:2.2rem;
    i{
      font-size:3rem;
    }
    &.button--icon-left{
      padding-left:6rem;
    }
    &.button--icon-right{
      padding-right:6rem;
    }
  }
  &--expand{
    width:100%;
  }
  &--center{
    text-align: center;
    justify-content:center;
    i{
      position: relative;
      left:0;
      right:0;
    }
  }
  &--center#{&}--icon-left{
    padding-left: 2rem;
    i{
      left:-1rem;
    }
  }
  &--center#{&}--icon-left{
    padding-right: 2rem;
    i{
      right:-1rem;
    }
  }
  &--primary{
    background-color:$navy;
    color:#fff;
    &:not(.button--no-hover):hover, &:not(.button--no-hover):focus{
      background-color:transparent;
      color:$navy;
      border-color:$navy;
    }
  }
  &--primary-alt{
    background-color:#fff;
    border-color:#fff;
    span.link-text, i::before{
      color:$navy;
    }
    &:not(.button--no-hover):hover, &.js-hover:not(.button--no-hover), &:not(.button--no-hover):focus{
      background-color:transparent;
      color:#fff;
      border-color:#fff;
      span.link-text, i::before{
        color:#fff;
      }
    }
  }


  &--secondary{
    //background-color:#fff;
    color:$navy;
    border-color:$navy;
    &:not(.button--no-hover):hover, &.js-hover:not(.button--no-hover), &:not(.button--no-hover):focus{
        background-color:$navy;
        color:#fff;
    }
  }
  &--secondary-alt{
    background-color:transparent;
    border-color:#fff;
    span.link-text, i::before{
      color:#fff;
    }
    &:not(.button--no-hover):hover, &.js-hover:not(.button--no-hover), &:not(.button--no-hover):focus{
      background-color:#fff;
      span.link-text, i::before{
        color:$navy;
      }
    }
  }
  &--trans{
    color:#fff;
    border-color:#fff;
    &:not(.button--no-hover):hover, &.js-hover:not(.button--no-hover), &:not(.button--no-hover):focus{
      color:$navy;
      background: #fff;
    }
  }
  &--trans-alt{
    color:$navy;
    background: #fff;
    border-color:#fff;
    &:not(.button--no-hover):hover, &.js-hover:not(.button--no-hover), &:not(.button--no-hover):focus{
      color:#fff;
      background: transparent;
    }
  }

  &--tertiary, &--tertiary-alt{
    .link-text{
      position:relative;
      &:after{
        content:'';
        position:absolute;
        width:0;
        left: 0;
        //right: 0;
        bottom:-.8rem;
        height: 2px;
        transition: width .4s $bezier-fast;
      }
    }
    &:not(.button--no-hover):hover, &.js-hover:not(.button--no-hover), &:not(.button--no-hover):focus{
      .link-text::after{
        width: 100%;
        //right: auto;
       // left: 0;
      }
    }
  }

  &--tertiary{
    color:$navy;
    padding-left:0;
    &.button--icon-left{
      padding-left:4rem;
      i{
        left:0;
      }
    }

    .link-text::after{
      background-color: $navy-500;
    }
    &:not(.button--no-hover):hover, &.js-hover:not(.button--no-hover), &:not(.button--no-hover):focus{
      .link-text{
        color:$navy-900;
      }      
    }
    i{
      color:$orange;
    }
  }
  &--tertiary-alt{
    color:#fff;
    padding-left:0;
    &.button--icon-left{
      padding-left:4rem;
      i{
        left:0;
      }
    }
    .link-text::after{
      background-color: $orange;
    }
    &:not(.button--no-hover):hover, &.js-hover:not(.button--no-hover), &:not(.button--no-hover):focus{
      .link-text{
        color:#fff;
      }      
    }
    i{
      color:$orange;
      &:before{
        color:$orange;
      }
    }
  }
  &--icon-right{
    padding-right:4.8rem;
    i{
      right:1.5rem;
    }
  }
  &--icon-left{
    padding-left:4.8rem;
    i{
      left:1.5rem;
    }
  }
  &--empty{
    text-align:center;
    padding:1.5rem;
    i{
      position: relative;
      right:initial;
      left:initial;
    }
  }
  body:not(.using-mouse) & {
    //accessibility focus for keyboard use only
    &:focus {
      outline: 2px solid $orange;
    }
  }




  &--primary, &--primary-alt, &--secondary, &--secondary-alt{
    i{
      transition: transform .3s $bezier-smooth ;
    }
    &:hover, &.js-hover:not(.button--no-hover), &:not(.button--no-hover):focus{
      i{
        transform: scale(1.2);
      }
    }
  }

  &--secondary-alt {
    &:hover, &.js-hover:not(.button--no-hover), &:not(.button--no-hover):focus{
      i{
        color: $navy;
      }
    }
  }

  i{
    &.i-arrow-right, &.i-arrow-up, &.i-arrow-down, &.i-arrow-left{
      //transition: transform 2s $bezier-smooth ;
      overflow: hidden;
      &::before, &::after{
        display: block;
        transition: transform .4s $bezier-fast--bounce;
      }
      &::after{
        font-family: 'icomoon';
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: 0;
        top: 0;
        //color: red;
      }
    }
    &.i-arrow-right::after{
        content: "\e913";
    }
    &.i-arrow-up::after{
      content: "\e914";
    }
    &.i-arrow-down::after{
      content: "\e911";
    }
    &.i-arrow-left::after{
      content: "\e912";
    }

    &.i-arrow-right::after {
      transform: translateX(-190%);
    }
    &.i-arrow-up::after {
      transform: translateY(90%);
    }
    &.i-arrow-down::after {
      transform: translateY(-90%);
    }
    &.i-arrow-left::after {
      transform: translateX(90%);
    }

  }

  &:hover, &.js-hover:not(.button--no-hover), &:not(.button--no-hover):focus{
    i.i-arrow-right, i.i-arrow-up, i.i-arrow-down, i.i-arrow-left{
      transform: scale(1);
      &::after{
        transform: translateX(0%) translateY(0%);
      }
    }
    //
    .i-arrow-right::before {
      transform: translateX(300%);
    }
    .i-arrow-up::before {
      transform: translateY(-100%);
    }
    .i-arrow-down::before {
      transform: translateY(100%);
    }
    .i-arrow-left::before {
      transform: translateX(-100%);
    }

  }

&--desktop-only{
  @include desktop-small-below{
    display: none;
  }
}

}
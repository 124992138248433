.sidebar-panel{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 2rem;
  position: relative;
  
  &--light{
    background-color: #fff;
    padding: 3rem;
    box-shadow: $shadow-300;
  }
  &--dark {
    background-color: $navy;
    padding: 3.5rem;
  }
  &__title{
    @extend %heading-five;
    margin-bottom: 1.9rem;
    @include desktop-small-below{
      font-size:2.2rem;
    }
    @include phone-large-below{
      font-size:2.4rem;
    }
    &--light {
      color: $white;
    }
  }

  .page-header &{
    &:last-child{
     // margin-bottom: 0;
    }
  }
}

.sidebar-related-items{

}

.sidebar-related-item{

}
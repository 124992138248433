.learning-center {
    &-index {
        > *:first-child {
            //Account for the absolute positioning of these filters
            //Req for the navy BG to float behind them
            padding-top:15rem;
            @include desktop-small-below {
                padding-top: 30rem;
            }
            @include phone-large-below {
                padding-top: 50rem;
            }
        }
    }
}


.learning-center__section {
    padding-top: 8rem;
    padding-bottom: 8rem;

    &-title {
        display:flex;
        align-items: center;
        margin-bottom:3rem;
        a {
            margin-left: 3rem;
            padding-bottom: 1rem;
        }
        @include tablet-large-below {
            flex-direction: column;
            align-items:flex-start;
            a {
                margin-left:0;
            }
        }
    }

    .search-results__list {
        padding-bottom:4rem;
        @include phone-large-below {
            padding-bottom: 0;
        }
    }

    &--interactives {
        background-color: $navy-200;
    }

    @include tablet-large-below {
        padding-bottom:3rem;
    }

}

.learning-center__interactives {
    &-slider {
        width:100%;
        padding-bottom:4rem;
        img {
            max-width:100%;
        }
        .interactive-block {
            box-shadow:none;
        }
        @include tablet-large-below {
            padding-bottom:1rem;
        }
    }
    .slick-list {
        box-shadow:$shadow-100;
    }
}

.interactives-slider-controls {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    button {
        &:hover {
            cursor: pointer;
        }
    }

    &__slider-prev,
    &__slider-next {
        margin: .5rem 1rem;
        background:none;
        border:0;
        font-size:3.4rem;
        color:$navy;
    }

    &__dots {
        margin-bottom: 5px;
        li {
            line-height:0;
        }
        .slick-dots {
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content: center;
        }
        button {
            border:0;
            border-radius:50%;
            background:$navy-400;
            width:10px;
            height:10px;
            margin: 0 11px;
            padding:0;
        }
        .slick-active button {
            background: $navy-600;
        }
    }
    
}


// This is the training CTA on learning center Index
.learning-center__training {
    margin-top:4rem;
    margin-bottom:15rem;

    .row {
        background-color:$navy-200;
        padding:5rem;
        border-radius:5px;
        flex-wrap: nowrap;
        justify-content: space-between;
        @include desktop-small-below {
            flex-direction:column;
        }
    }
    &-content {
        width:100%;
        padding-right:8rem;
        @include desktop-small-below {
            width:100%;
            padding-right:0;
        }
    }
    &-title {
        display:flex;
        flex-direction:row;
        align-items: center;
        margin-bottom:2.4rem;
        i {
            font-size:5rem;
            color:$orange;
            margin-right:1.5rem;
        }
        h3 {
            margin-bottom:0;
        }
    }
    &-description {
        @extend %body-copy-large;
    }
    &-images {
        // width:30%;
        position:relative;
        display:flex;
        flex-direction:row;
        align-items: center;
        width:40%;
        max-width: 580px;
        @include desktop-small-below { 
            order:-1;
            margin-bottom: 3rem;
            width:100%;
        }
    }
    &-image {
        width:calc(50% - 1rem);
        max-width:280px;
        position:absolute;
        &:first-child {
            top:-8rem;
            left:0;
        }
        &:last-child {
            bottom:-8rem;
            right:0;
        }
        @include desktop-small-below { 
            position:relative;
            margin-right:2rem;
            &:last-child {
                bottom:0;
            }
        } 
    }
}
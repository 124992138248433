.rich-text {
    table {
        width: 100%;
    }
    th {
        @extend %body-copy;
        background-color: $navy;
        color: #fff;
        font-weight: 700;
        padding: 2rem;
        margin-bottom: 0;

    }
    tr:nth-child(even){
        background-color: $navy-100;
    }
    td {
        @extend %body-copy;
        padding: 1.5rem 1rem;
        border: 1px solid $navy-400;
    }
}
.organization-block {
    @extend %base-block;
    padding:2rem 4rem 2rem 2rem;
    //margin-bottom:5rem;
    position: relative;
    @include el-size(full, two-thirds, three-quarters) {
        flex-direction: row;
        @include tablet-large-above{
            // .organization-block__primary {
            //     padding-top: 2rem;
            //     padding-left: 5rem;
            // }
            // .organization-block__secondary {
            //     padding-right: 2rem;
            // }
        }
        @include tablet-large-below {
            flex-direction: column;
        }
    }
    @include el-size(half, third, quarter) {
        flex-direction: column;
    }
    &__tag {
        @extend %base-tag;
		display:inline-block;
		margin-bottom:1rem; 
        position: absolute;
        left:0;
        border-top-left-radius:0px;
        border-bottom-left-radius:0px;
    }

    &__img {
        max-width: 70%;
        max-height: 150px;
    }
    &__title {
        margin-bottom:2rem;
    }
    p {
        // @extend %body-copy;
        color:$navy-500;
        // font-size:2rem;
        font-weight:400;
        margin-bottom:1rem;
    }
    &__link {
        width:fit-content;
        // font-size:1.7rem;
        // font-weight:700;
        // color:$navy;
        margin-bottom:0;
        // display:flex;
        // align-items:center;
        // i{
        //     color:$orange;
        //     font-size:3rem;
        //     margin-left:1rem;
        // }
        // &:hover{
        //     text-decoration:none;
        // }
    }


    &__secondary{

    }
    &__primary{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 5rem;
        flex-grow: 1;
        picture{
            margin-bottom: 0;

        }
        a{

        }
    }
}
.training-page {
    display:flex;
    width:100%;
    flex-wrap:nowrap;
    padding-top:5rem;
    padding-bottom:5rem;
    @include tablet-large-below {
        flex-direction:column;
    }

    &__builder {
        width:50%;
        @include tablet-large-below {
            width:100%;
        }
    }

    &__side-bar {
        width:50%;
        padding-top:7rem;
        padding-left:10rem;
        @include tablet-large-below {
            width:100%;
            padding-left:0;
            padding-top:3rem;
        }
    }

    &__ceu {
        background-color:$navy-200;
        padding: 5rem 5rem 3rem 5rem;
        border-radius:5px;

        img {
            max-width:200px;
            width:30%;
            height:auto;
            margin: 0 2rem 2rem 0;
            @include desktop-large-below {
                margin:2rem auto 4rem auto;
                width:100%;
            }
        }
    
        h3 {
            @include desktop-large-below {
                text-align: center;
            }
        }
    
        .large-text {
            line-height:160%;
            color:$navy;
            @include desktop-large-below {
                text-align: center;
            }
        }
    }

    &__top-content {
        display:flex;
        flex-direction:row;
        align-items:flex-start;
        margin-bottom:2rem;
        @include desktop-large-below {
            flex-direction:column;
            align-items: center;
        }
    }

    &__desc {
        color:$navy;
        @include desktop-large-below {
            text-align: center;
        }
    }
}



   

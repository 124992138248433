.innovation-focus-area-block{
    @extend %base-block;
    min-height: calc(100% - 2rem);
    overflow: hidden;
    @include el-size (half, two-thirds, three-quarters, full){
        @include tablet-large-below{
            flex-direction:column;
        }
    }
    @include tablet-large-below{
        flex-direction:column;
    }
    &__primary{
        @extend %base-block__primary;
        padding-top:4rem;
        @include el-size (half, two-thirds, three-quarters, full){
            padding-left: 4rem;
            @include tablet-large-below{
                width: 100%;
                padding-left: 2rem;
            }
        }
    }
    &__tag{
        @extend %base-tag;
        z-index:4;
        @include el-size(half, two-thirds, three-quarters, full){
            @include tablet-large-below {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }
        @include el-size(third, quarter, sixth){
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }
    &__info{
        display:flex;
        flex-direction:column;
        flex-wrap:wrap;
        margin-bottom:2rem;
    }
    &__info--innovation{
        @include el-size(half, two-thirds, three-quarters, full){
            @include tablet-large-below {
                position:absolute;
                top:-2rem;
                left:0rem;
                display:flex;
                border-top-right-radius:5px;
                background-color:#fff;
                flex-direction:row;
                span{
                    display:inline-block;
                    padding:1rem 1.8rem;
                    font-size:1.5rem;
                    font-weight: 200;
                    @include el-size(sixth){
                        font-size:1.2rem;
                    }
                }
            }
            @include phone-large-below {
                top: -3rem;
                span{
                    font-size:2rem;
                }
            }
            // @include phone-large-below {
            //     top: -2rem;
            // }
        }
        @include el-size(third, quarter, sixth){
            position:absolute;
            top:-2rem;
            left:0rem;
            display:flex;
            border-top-right-radius:5px;
            background-color:#fff;
            flex-direction:row;
            span{
                display:inline-block;
                padding:1rem 1.8rem;
                font-size:1.5rem;
                font-weight: 200;
                @include el-size(sixth){
                    font-size:1.2rem;
                }
                @include phone-large-below {
                    font-size:2rem;
                }
            }
            @include phone-large-below {
                top:-3rem;
            }
        }
    }
    &__info--project &{
        @include el-size(half, two-thirds, three-quarters, full){
            @include tablet-large-below{
                &__tag{
                    border-top-left-radius:0rem;
                    border-bottom-left-radius:0rem;
                }
            }
            @include phone-large-below{
                &__tag{
                    border-top-left-radius:0rem;
                    border-bottom-left-radius:0rem;
                }
                &__date{
                    border-top-right-radius:5px;
                }
            }
        }
        @include el-size(third, quarter, sixth) {
            &__tag{
                border-top-left-radius:0rem;
                border-bottom-left-radius:0rem;
                transform: translateY(-25%);
            }
            &__date{
                border-top-right-radius:5px;
            }
        }
    }
    &__title{
        @extend %heading-five;
        margin-bottom:1.5rem;
        @include phone-large-below {
            font-size:2.9rem;
            margin-bottom:2rem;
        }
    }
    &__secondary{
        @extend %base-block__secondary;
        width: 50%;
        z-index: 2;
        padding: 0;
        @include el-size (half, two-thirds, three-quarters, full){
            padding-bottom: 0;
            @include tablet-large-below{
                width: 100%;
            }
        }
        @include tablet-large-below{
            width:100%;
        }
        picture {
            width: 100%;
            height: 100%;
            // img{
            //     width: 100%;
            //     height: 100%;
            //     object-fit: cover;
            // }
        }
        @include el-size(third, quarter){
            width:100%; 
        }
    }
    &--horizontal{
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .button--secondary{
            display: inline-flex;
        }
        .innovation-focus-area-block__summary{
            display: block;
            @extend %body-copy; 
        }
    }
    &__img{
        @extend %base-block__img;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include el-size (half, two-thirds, three-quarters, full){
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            @include tablet-large-below{
                border-top-right-radius: 5px;
                border-bottom-right-radius: 0;
            }
        }
        @include el-size(third, quarter){
            border-bottom-right-radius: 0;
        }

    }
    &__summary{
        padding-bottom:1.5rem;
    }

    &--promoted {
        background-color: $navy;
        .innovation-focus-area-block__tag {
            color:#fff;
            background-color:$navy-900;
        }
        .innovation-focus-area-block__info {
            background-color:$navy;
        }
        .innovation-focus-area-block__title {
            color:#fff;
        }
        .innovation-focus-area-block__summary {
            color:#fff;
            * {
                color:#fff; 
            }
        }
        .button {
            color:#fff;
            border-color: #fff;
        }
    }
  
}
.quote-row {
  //margin-bottom: 5rem;

  overflow-y: hidden;
  &__quote{
    &::before{
      content: '\e921';
      font-family: 'icomoon';
      display: block;
      color: $orange;
      font-size: 5rem;
      margin-bottom: 1rem;
    }
    p{
      color: #fff;
      font-size: 3rem;
      max-width: 100%;
      @include desktop-medium-below{
        font-size: 2.5rem;
      }
      @include phone-large-below{
        font-size: 2.5rem;
      }
    }
  }
  &__author-name{
    @extend %body-copy-large;
    color: #fff;
  } 
  &__author-sub{
    @extend %body-copy-large;
  }
}

.quote-row__decoration{
  pointer-events: none;
  user-drag: none;
  user-select: none;
}

.quote-row--default{
  background-color: $navy-900;
  position: relative;
  overflow: hidden;
  .wrapper{
    padding: 10rem 20rem 10rem 27rem;
    @include desktop-medium-below{
      padding: 10rem;
    }
    @include phone-large-below{
      padding: 10rem 6rem;
    }
  }
  .quote-row__decoration{
    max-width: 55rem;
    height: 70rem;
    width: 100%;
    position: absolute;
    top: -60px;
    left:0;
    opacity: 0.15;
  }
  .quote-row__author{
    display: flex;
    flex-wrap:wrap;
    align-content:center;
    align-items:center;
    z-index: 1;
    .quote-row__author-img{
      max-width:80px;
      width:100%;
      border-radius:50%;
      margin-right:2rem;
    }
  }
  .quote-row__author-details{
    display: flex;
    flex-direction: column;
  }
  .quote-row__author-name{
    font-weight: 700;
  }
  .quote-row__author-sub{
    color: $orange;
  }
}

.quote-row--alternate {
  position: relative;
  margin-top: 2rem;
  .quote-row__background {
    position: absolute;
    background-color: $navy-100;
    width: 100%;
    height: 90%;
    bottom:0;
  }
  .wrapper {
    flex-direction: row;
    align-items: center;
    padding-top: 1rem;
  }
  .quote-row__author-details{
    display: flex;
    flex-direction: column;
    @include column(2);
    @include tablet-large-below {
      display: none;
    }
  }
  .quote-row__author-name{
    @extend %heading-subline;
  }
  .quote-row__author-sub{
    @extend %body-copy-large;
    color: $navy-500;
    
  }
  .quote-row__author{
    display: flex;
    justify-content: center;
    align-self: flex-end;
    position: relative;
    z-index: 1;
    @include tablet-large-below {
      flex-shrink: 0;
      width: 25%;
    }
    @include phone-large-below {
      display: none;
    }
    .quote-row__decoration{
      position: absolute;
      max-width: 500px;
      top: 10%;
      z-index: -1;
      opacity: .1;
    }
    &-img {
      // max-width: 310px;
      // max-height: 515px;
      // height:100%;
      // width: 100%;
      width:300px;
      height:auto;
    }
  }
  .quote-row__quote{
    @include column(8);
    padding-top: 3rem;
    padding-left: 5rem;
    p{
      color: $navy;
      font-size: 3rem;
    }
    @include tablet-large-below {
      display: none;
    }
  }
}
//responsive styles tablet and below
.quote-row__responsive{
  display: none;
  @include tablet-large-below {
    display: flex;
    flex-direction: column;
    padding-top:4rem;
    padding-bottom: 2.5rem;
    width: 75%;
    @include phone-large-below {
      width: 100%;
    }
    .quote-row__quote{
      display: block;
      width: auto;
      p{
        font-size: 2.4rem;
      }
      @include phone-large-below {
        padding-left: 0;
      }
    }
    .quote-row__author-name{
      padding-left: 5rem;
      font-size: 2rem;
      @include phone-large-below {
        padding-left: 0;
      }
    }
    .quote-row__author-sub{
      padding-left: 5rem;
      font-size: 2rem;
      @include phone-large-below {
        padding-left: 0;
      }
    }
  }
}


.accordion {
  margin-bottom: 2rem;
}
.accordion-row{
  margin: 0;
  width: 100%;

  &__header{
    width: 100%;
    background: transparent;
    padding: 2rem 2em 2rem 2rem;
    border: 0;
    border-top: 2px solid $navy-400;
    position: relative;
    margin-bottom: 0;
    transition: color .2s ease-in-out;
    text-align: left;
    cursor: pointer;

    display: flex;
    align-items: center;

    &:hover {
      .accordion-row__title {
        color: $navy-900;
        text-decoration: underline;
      }
    }

    &::before{
      font-family: 'icoMoon';
      content: '\e90f';
      font-size: 3.2rem;
      margin-right: 2rem;
      color: $orange;
    }
    &[aria-expanded="true"]{
      color: $orange;
    }
    &[aria-expanded="true"]::before{
      content: '\e910';
    }

    @include el-size(third){
      @extend %heading-five;
      margin: 0;
    }
    @include el-size(quarter){
      font-size: 2rem;
    }


    &:last-child{
      border-bottom: 2px solid #DFE5F1;
    }
  }

  &__title{
    margin: 0;
    @extend %heading-three;
    margin: 0;
    @include phone-large-below{
      font-size: 2.4rem;
    }
    @include el-size(two-thirds, three-quarters, full){
      @extend %heading-three;
      margin: 0;
      @include phone-large-below{
        font-size: 2.4rem;
      }
    }
    @include el-size(half, third, quarter, sixth){
      @extend %heading-five;
      @include phone-large-below{
        font-size: 2.4rem;
      }
      margin: 0;
    }
  }


  &__body{
    > div{
      padding: 1rem 2rem 2rem 2rem;
    }
    @include el-size(third, quarter){
      .rich-text p{
        @extend %body-copy-small;
      }
    }
  }
}



@font-face{
  font-family: 'GTAmerica Standard';
  src: url('/fonts/type/gtamerica-standard/GT-America-Standard-Regular.ttf') format('truetype');
  font-weight: 500;
}

@font-face{
  font-family: 'GTAmerica Standard';
  src: url('/fonts/type/gtamerica-standard/GT-America-Standard-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face{
  font-family: 'GTAmerica Extended';
  src: url('/fonts/type/gtamerica-extended/GT-America-Extended-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face{
  font-family: 'GTAmerica Extended';
  src: url('/fonts/type/gtamerica-extended/GT-America-Extended-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face{
  font-family: 'Laforge Stencil';
  src: url('/fonts/type/laforge-stencil/LaforgeStencil-Regular.otf') format('opentype');
  font-weight: 500;
}

.page-header--careers.page-header-inline-media {

    margin-bottom:5rem;
    @include desktop-small-below {
        margin-bottom:2rem;
    }

    .page-header-inline-media__primary {
        padding: 15rem 0 5rem 0;
    }

    .careers-box {
        display:flex;
        flex-direction:column;
        max-width:75rem;
        background-color:#fff;
        box-shadow: $shadow-500;
        padding:3.5rem 4rem 1rem 4rem;
        border-radius:5px;
        margin-bottom: -8rem;
        margin-top:8rem;
        @include desktop-small-below {
            margin-top:3rem;
            margin-bottom: 3rem;
        }

        &__title {
            @extend %heading-four;
            margin-bottom:2rem;
            color:$navy;
        }

        &__description {
            @extend %body-copy;
            color:$navy;
            margin-bottom:2rem;
        }

        &__buttons {
            display:flex;
            align-items:center;
            flex-wrap:wrap;
        }
    }

}
body .popover{
	background-color: $navy;
	border-radius:.5rem;
	max-width:432px;
	box-shadow:$shadow-300; 
	z-index:990;
}
.popover-arrow{
      width: 0;
      height: 0;
      border: 15px solid transparent;
      border-bottom-color: $navy;
      position: relative;
      &:after{

      content: '';
      position: absolute;
      left: -15px;
      top: 15px;
      width: 0;
      height: 0;
      border: 15px solid transparent;
      border-top-color: $navy;
      }
	.bs-popover-bottom &{
		top:-30px;
	}
	.bs-popover-top &{
		bottom:0;
	}
	.bs-popover-end &{
		left:-15px;
	}
	.bs-popover-start &{
		right:-15px;
	}

}
.popover-block{
	background-color:$navy;
	color:#fff;
	padding:2rem;
	&__title{
		color:#fff;
		width:100%;
		padding-bottom:2rem;
		border-bottom: 1px solid #CAD2DD;
		font-size:2.5rem;
		font-family:$font-heading;
		line-height:130%;
	}
	&__date{
		padding-top:1.5rem;
		font-size:1.8rem;
		font-family:$font-body;
		display:block;
		color:#fff;
	}
	&__summary{
		font-size:1.8rem;
		font-weight:300;
		margin-bottom: 2rem;
		display: block;
		width:100%;
	}
	&__details{
		display:flex;
		flex-direction:row;
		flex-wrap:wrap;
		div{
			@include column(6);
			&.full{
				@include column(12);
			}
			padding-right:2rem;
			&:last-child {
				padding-right:0;
			}
			span{
				font-size:1.5rem;
				font-weight:300;
			}
			p{
				font-weight:bold;
			}
		}
	}
}
.capabilities-feed {
    @extend %base-block;
    padding: 3rem 4rem;
    flex-wrap: wrap;
    &__heading {
        @extend %heading-three;
        width: 100%;
        color: $navy-900;
    }
    &__capability-parent{
        display: flex;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5rem;
            height: 6rem;
            clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
            background-color: $orange-100;
            margin-bottom: 1rem;
            margin-right: 1rem;
            i {
                font-size: 2.6rem;
                color: $orange;
            }
        }
        a {
            @extend %heading-five;
            margin-bottom: 2rem;
            text-decoration: none;
        }
    }
    &__capability-child {
        display: flex;
        flex-direction: column;
        a{
            @extend %body-copy-small;
            line-height: 1.4;
            color: $navy-500;
            margin-bottom: 1.5rem;
            text-decoration: underline;
        }
    }
    //container display overrides by column size
    &__container{
        @include el-size(full, half, two-thirds, three-quarters){
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
            padding-right: 2rem;
            .capabilities-feed__capability-parent {
                flex-direction: column;
            }
            @include tablet-below {
                flex-direction: column;
            }
        }
        @include el-size(third, quarter){
            display: flex;
            flex-direction: column;
            .capabilities-feed__capability-parent {
                align-items: center;
                a{
                    margin-bottom: 1.5rem;
                }
            }
            .capabilities-feed__capability-child{
                padding-left: 6rem;
            }
        }
    }

    &__capability {
        width:100%;
        @include el-size(full, half, two-thirds, three-quarters){
            padding-right:3rem;
            @include tablet-below {
                padding-right:0;
            }
        }
    }
}




// .capabilities-feed {
//     @extend %base-block;
//     padding: 3rem 4rem;
//     flex-wrap: wrap;
//     &__heading {
//         width: 100%;
//         color: $navy-900;
//     }
//     &__capability{
//         margin-right: 1rem;
//         span {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             width: 5rem;
//             height: 6rem;
//             clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
//             background-color: $orange-100;
//             margin-bottom: 1rem;
//             i {
//                 font-size: 2.6rem;
//                 color: $orange;
//             }
//         }
        
//     }
//     &__capability-parent{
//         text-decoration: none;
//         &:hover {
//             text-decoration: none;
//         }
//     }
//     p.capabilities-feed__capability-child{
//         margin-bottom: 1rem;
//         a{
//             font-size: 1.8rem;
//             font-weight: 400;
//             color: $navy-500;
//         }
//     }
//     &__container--horizontal{
//         width: 100%;
//         display: flex;
//         justify-content: space-between;
//     }
//     &__container--vertical {
//         .capabilities-feed__capability {
//             display: flex;
//             flex-wrap: wrap;
//         }
//         .capabilities-feed__capability-parent {
//             //margin-left: 1.5rem;
//             align-self: center;
//         }
//         .capabilities-feed__capability-child {
//             margin-left: 6.5rem;
//             width: 100%;
//         }
//     }
// }

.capability-highlight-block{
    @extend %base-block;
    @include el-size(full, three-quarters, two-thirds, half, third, quarter){
        @include tablet-large-below{
            flex-direction: column-reverse;
            justify-content: flex-end;
        }
    }
    @include el-size(third, quarter){
        flex-direction: column-reverse;
        justify-content: flex-end;
    }
    &__tag{
        @extend %base-tag;
        margin-top: 2rem;
        margin-bottom: 2rem;
        line-height: 1.2;
        @include el-size(two-thirds, half) {
            width: initial;
        }
        @include el-size(full, three-quarters, two-thirds, half){
            @include tablet-large-below{
                margin-top: 0;
                margin-bottom: 0;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                font-size: 1.7rem;
                padding-left: 2.5rem;
                transform: translateY(-1.7rem);
            }
            @include phone-large-below {
                font-size:2rem;
            }
            // @include phone-large-below {
            //     top: -5%;
            // }
        }
        @include el-size(third, quarter){
            margin-top: 0;
            margin-bottom: 0;
            position: absolute;
            top: -8.5%;
            left: 0;
            z-index: 1;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            @include tablet-large-above {
                max-width: 60%;
            }
        }
    }
    &__primary{
        @extend %base-block__primary;
        //padding-left: 5rem !important;
        display: flex;
        // align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-shrink:initial;
        @include el-size(full, three-quarters, two-thirds, half){
            width: 50%;
            padding: 4rem;
            margin-right: 4rem;
            @include tablet-large-below{
                width: 100%;
                padding: 4rem 2rem 2rem 2rem;
            }
        }
        @include el-size(two-thirds, half) {
            @include desktop-medium-below {
                padding: 2.5rem;
                margin-right: 2rem;
            }
            @include tablet-large-below{
                padding-top: 4rem;
            }
        }
        @include el-size(third, quarter){
            width: 100%;
            padding: 2rem !important;
            @include tablet-large-below{
                width: 100%;
            }
        }
    }
    &__title{
        width: 100%;
        @include el-size(third, quarter){
            margin-top: 2.2rem;
            @include desktop-large-above {
                width: 80%;
            }
        }
        @include el-size(two-thirds, three-quarters, full){
            @extend %heading-three;
            @include phone-large-below{
                font-size: 2.9rem;
            }
        }
        @include el-size(half, third, quarter, sixth){
            @extend %heading-five;
            @include phone-large-below{
                font-size: 2.9rem;
            }
        }
    }
    &__summary{
        color: $navy-500;
        width: 100%;
        margin-bottom: 3rem !important;
        @include el-size(two-thirds, three-quarters, half){
            @extend %body-copy-small;
        }
    }
    .button{
        //margin-bottom: 5rem;
        text-align: left;
    }
    &__secondary{
        @extend %base-block__secondary;
        flex-direction: row;
        align-items: center;
        padding-left:0;
        padding: 0; 
        picture{
            height: 100%;
            width: 100%;
        }
        @include el-size(full, three-quarters, two-thirds, half){
            width: 50%;
            padding-bottom: 0;
            @include tablet-large-below{
                width: 100%;
                // padding-left: 2rem;
            }
        }
        @include el-size(third, quarter){
            // padding-left: 2rem;
        }

    }
    &__image-container {
        height: 100%;
        @include el-size(two-thirds, half) {
            min-height: 55rem;
            @include tablet-large-below {
                min-height: auto;
            }
        }
        @include tablet-large-below {
            max-height: 40rem;
            overflow: hidden;
        }
        @include phone-large-below {
            max-height: 33rem;
        }
    }
    &__icon{
        position:absolute;
        left: 0;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%) translateY(-50%);
        @include tablet-large-below{
            left: initial;
            right: 0;
            bottom: 0;
            top: auto;
            transform: translateX(-50%) translateY(35%);
        }
        // @include phone-large-below {
        //     bottom: -5rem;
        //     right: 3rem;
        // }
        @include phone-small-below {
            width: 20%;
            transform: translateX(-25%) translateY(50%);
        }
        @include el-size(third, quarter){
            right:0;
            bottom: 0;
            top: auto;
            left: auto;
            // transform: translate(-3rem, 50%);
            transform: translate(-1.5rem, 50%);
            // @include tablet-large-below{
            //     transform: translate(-40%, 35%);
            // }
            @include phone-large-below {
                transform: translate(-40%, 50%);
            }
        }
        &-wrapper{
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        svg {
            max-width: 15.5rem;
            max-height: 15.5rem;
            width: 100%;
            height: 100%;
            @include el-size(two-thirds, half) {
                max-width: 12.5rem;
                max-height: 12.5rem;
                @include phone-large-below{
                    max-width: 10rem;
                    max-height: 10rem;
                } 
            }
            @include tablet-large-below {
                max-width: 12.5rem;
                max-height: 12.5rem;
            }
            @include phone-large-below{
                max-width: 10rem;
                max-height: 10rem;
            } 
            @include el-size(third){
                max-width: 12.5rem;
                max-height: 12.5rem;
                @include desktop-large-below {
                    max-width: 8rem;
                    max-height: 8rem;
                }
                @include phone-large-below {
                    max-width: 10rem;
                    max-height: 10rem;
                }
            }
            @include el-size(quarter){
                max-width: 10rem;
                max-height: 10rem;
                @include desktop-large-below {
                    max-width: 8rem;
                    max-height: 8rem;
                }
                @include phone-large-below {
                    max-width: 10rem;
                    max-height: 10rem;
                }
            }
        }
        i{
            font-size:4rem; 
            color: $navy;
            position: absolute;
            @include el-size(two-thirds, half) {
                font-size:3.5rem;
                @include phone-large-below{
                    font-size:2.5rem
                }
                @include phone-small-below {
                    font-size:2rem;
                } 
            }
            @include phone-large-below{
                font-size:2.5rem
            }
            @include phone-small-below {
                font-size:2rem;
            }
            @include el-size(third){
                font-size: 3.4rem;
                @include desktop-large-below {
                    font-size: 2.8rem;
                }
                @include phone-small-below {
                    font-size:2rem;
                }
            }
            @include el-size(quarter){
                font-size: 3rem;
                @include desktop-large-below {
                    font-size: 2.8rem;
                }
                @include phone-small-below {
                    font-size:2rem;
                }
            }
        }
    }
    &__img{
        @extend %base-block__img;
        object-fit: cover;
        object-position: center;
        @include el-size(full, three-quarters, two-thirds, half){
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            @include tablet-large-below{
                border-top-left-radius: 5px;
                border-bottom-right-radius: 0;
            }
        }
        @include el-size(third, quarter){
            border-bottom-right-radius: 0;
        }
        picture &{
            height: 100%;
            width: 100%;
        }
    } 
}
